import * as React from 'react';
import { useState } from 'react';
import Container from '@mui/material/Container';
import { Box, Button, CircularProgress, TextField, Typography,useTheme,Fab } from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import logofulldark from "assets/logofull.svg"
import { useCreateAccountMutation, useCreateSendLinkMutation, useGetAppShareLinkQuery, useUpdateTierSubscriptionMutation } from 'state/api';
import { useLocation, useOutletContext,useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

export default function AddPatient() {

    const [userData] = useOutletContext()
    const [progress, setProgress] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState()
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [createAccount, response] = useCreateAccountMutation();
    const {data, isLoading} = useGetAppShareLinkQuery();
    const [updateTierSubs] = useUpdateTierSubscriptionMutation()
    const location = useLocation()
    const tierSubscription = location.state ? location.state.tierSubscription : null;
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const navigate = useNavigate();
    if (!location.state) {
        navigate("/")
    } else if (location.state  && !location.state.hasLimit) {
        navigate("/")
    }
  const [sendSms] = useCreateSendLinkMutation();
    const handleAddPatient = () =>{
       
        setProgress(true)
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
        
        if (name.trim().length === 0) {
            setError(true)
            setProgress(false)
            setMessage('Name is required')
            setTimeOut()
            return
        } else if (email.trim().length > 0 && !email.match(validRegex)) {
            setError(true)
            setProgress(false)
            setMessage('Invalid email address')
            setTimeOut()
            return
        }else if (phoneNumber.trim().length === 0) {
            setError(true)
            setProgress(false)
            setMessage('Invalid phone number')
            setTimeOut()
            return
        } else {
            
            const account = { name: name, mobile: phoneNumber, email: email, role: 'Client', referralCode:userData.userData[0].userReferralCode.code}
           
            if(tierSubscription){
                const subs = {quantity: 1, tier: tierSubscription.tier, addons: [{item:"Extra Patient"}], id: tierSubscription._id, razorSubscription: tierSubscription.razorSubscription}
                updateTierSubs(subs).unwrap()
                .then((d)=>{
                    createAccount(account).unwrap().then((data1) => {
               
                        if (data) {
                         const link = "https://casamed.page.link/app"
                        
                          sendSms({phoneClient: phoneNumber, name: name, partner: userData.userData[0].name, link:link}).unwrap().then((d)=>{
                             setProgress(false)
                             setSuccess(true)
                             setTimeOut()
                             window.location.replace('/patients')
                          }).catch((e)=>{
                             setError(true)
                             setMessage(e.data.message)
                             setProgress(false)
                             setTimeOut()
                          })
                        }
         
                     }).catch((err) => {
                         setError(true)
                         setMessage(err.data.message)
                         setProgress(false)
                         setTimeOut()
                     })
                }).catch((e)=>{
                    alert(e.message)
                })
            } else {
                
                createAccount(account).unwrap().then((data1) => {
               
                    if (data) {
                     const link = "https://casamed.page.link/app"
                    
                      sendSms({phoneClient: phoneNumber, name: name, partner: userData.userData[0].name, link:link}).unwrap().then((d)=>{
                         setProgress(false)
                         setSuccess(true)
                         setTimeOut()
                         window.location.replace('/patients')
                      }).catch((e)=>{
                         setError(true)
                         setMessage(e.data.message)
                         setProgress(false)
                         setTimeOut()
                      })
                    }
     
                 }).catch((err) => {
                     setError(true)
                     setMessage(err.data.message)
                     setProgress(false)
                     setTimeOut()
                 })
            }
            
        }
    }
    const setTimeOut = () => {
        setTimeout(()=>{
            setError(false)
            setSuccess(false)
        },2000)
    }
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            flexDirection:'column',
            position:'relative'

        }}>

<div className='flex flex-col md:flex-row'>
                    <Fab
                        color="primary"
                        aria-label="back"
                        size="small"
                        sx={{
                            backgroundColor: isDarkMode ? theme.palette.primary.light : theme.palette.secondary.light,
                            marginLeft: "8px",
                            position:'absolute',
                            top:'16px',
                            left:'16px'
                        }}
                        onClick={() => { navigate(-1); }} // Navigate back to the previous screen
                    >
                        <ArrowBack />
                    </Fab>
                </div>
            <Container sx={{
                color: 'black',
                boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                borderRadius: '16px',
                padding: '16px',
                bgcolor: 'white',
                width: '500px',
                margin: '12px'
            }}>
                {/* <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={logofulldark} alt="App Logo" style={{ height: '64px' }} />

                </Box> */}

                <Typography align='center' fontWeight={700} variant="h5" sx={{ mt: 2 }}>
                   Add New Patient
                </Typography>
                {
                success && <Box display="flex" borderRadius={2} mt={2} padding="8px" bgcolor="#d7ffe8" justifyContent="center" alignItems="center"><Typography align='center' variant='h6' color="#01b850">Added new patient successfully!</Typography></Box>
            }
                {
                     <Box display='flex' flexDirection='column' sx={{ mt: 6 }}>
                        <Typography variant="h6" fontWeight={600}>
                            Name
                        </Typography>
                        <TextField
                            onChange={(e)=>{setName(e.target.value)}}
                            variant="standard"
                            fullWidth
                            placeholder='Enter patient name'
                            focused={false}
                            required
                            inputProps={{

                                style: { color: 'black', fontSize: '16px', border: 'solid black', padding: '12px' }
                            }}
                        />

                        <Typography mt={2} variant="h6" fontWeight={600}>
                            Email
                        </Typography>
                        <TextField
                            variant="standard"
                            placeholder='Enter patient email (optional)'
                            fullWidth
                            onChange={(e)=>{setEmail(e.target.value)}}
                            focused={false}
                            type="email"
                            inputProps={{

                                style: { color: 'black', fontSize: '16px', border: 'solid black', padding: '12px' }
                            }}
                        />

                        <Typography mt={2} variant="h6" fontWeight={600}>
                            Phone Number
                        </Typography>

                        <PhoneInput

                            style={{ padding: '12px', border: 'solid', marginTop: '8px' }}
                            className={"input-phone-number"}
                            placeholder="Enter patient phone number"
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            defaultCountry="IN"
                            international
                            countryCallingCodeEditable={false}
                            inputProps={{

                                required: true,
                                autoFocus: true,


                            }}
                        />

                    </Box>

                }
                
                {
                   error &&  <Box display="flex" borderRadius={2} mt={2} padding="8px" bgcolor="#ff9999" justifyContent="center" alignItems="center"><Typography align='center' variant='h6' color="#eb0a0a">{message}</Typography></Box>
                }
                { (

                    <Box sx={{ mt: 3 }}>
                        <div style={{ margin: '8px' }} id='recaptcha-container'></div>
                        { !progress && <Button id="signup-btn"  variant="contained" onClick={handleAddPatient} sx={{ width: '100%', padding: '12px', backgroundColor: "#006fa4" }}>
                            Add Patient
                        </Button>}

                    </Box>
                )}

                {
                   progress &&  <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress sx={{ width: '100%', alignSelf: 'center' }} /></Box>
                }

                {/* <Box sx={{ mt: 3 }}>
                    <Typography align='center' variant="h6">
                        Already have an account? <a href='/signin'><Typography color="#006fa4" variant='h5' style={{ cursor: 'pointer' }} fontWeight={600}>Sign In</Typography></a>
                    </Typography>
                </Box> */}
            </Container>
        </Box>
    )
}