import { ArrowOutward, LinkOff } from "@mui/icons-material";
import { Button, LinearProgress, TableContainer } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Link, useOutlet, useOutletContext } from "react-router-dom";
import Tiers from "scenes/tiers";
import {
  useGetActiveSubscriptionInvoiceQuery,
  useGetActiveSubscriptionQuery,
  useGetCustomersQuery,
} from "state/api";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import { tokensDark } from "theme";

export default function Billing() {
  const [userData] = useOutletContext();
  const navigate = useNavigate();
  const [skip, setSkip] = useState(false);
  const { data } = useGetActiveSubscriptionQuery(userData.userData[0]._id);
  const patients = useGetCustomersQuery(userData.userData[0].userReferralCode);
  const isMobileView = window.innerWidth <= 600;

  const invoices = useGetActiveSubscriptionInvoiceQuery(
    data && data.data[0].razorSubscription && data.data[0].razorSubscription,
    { skip: !skip }
  );

  useEffect(() => {
    if (data) {
      setSkip(true);
      if (invoices.data) {
        // console.log(invoices.data)
      }
    }
  }, [data]);
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const handleRedirectBill = (shortUrl) => {
    window.location.href = shortUrl;
  };
  function nextAmount() {
    if (patients.data.length > data.data[0].tier.patients) {
      return (
        data.data[0].tier.price / 100 +
        (data.data[0].tier.extra / 100) *
          (patients.data.length - data.data[0].tier.patients)
      );
    } else {
      return data.data[0].tier.price / 100;
    }
  }

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const backgroundColor = isDarkMode ? "white" : "#917e4e";
  const buttonbackgroundColor = isDarkMode ? "#1759e8" : "#f7fae8";
  const iconColor = isDarkMode ? "black" : "#857c08";

  return (
    <div className="flex flex-col items-start py-4 px-4">
      {" "}
      {/* Updated padding */}
      <div className="mb-4">
        {/* <IconButton
          onClick={() => {
            navigate(-1); // Navigate back to the previous page
          }}
          style={{
            backgroundColor: buttonbackgroundColor,
            color:iconColor,
          }}
        >
          <ArrowBack />
        </IconButton> */}
      </div>
      <div
        className="w-full space-y-4 mb-10"
        style={{ color: backgroundColor }}
      >
        <h1 className="text-5xl font-semibold">
          <span
            className="underline underline-offset-8"
            style={{ textDecorationColor: isDarkMode ? "#02B9ED" : "#fae1a2" }}
          >
            B
          </span>
          illing
        </h1>
        <p className="font-medium text-base">
          Manage your billing and payment details.
        </p>
      </div>
      {data && (
        <div className="flex gap-2 flex-col md:flex-row w-full justify-between px-[2%]  rounded-lg ">
          <div
            className="w-full  border rounded-lg flex flex-col justify-between"
            style={{ backgroundColor: isDarkMode ? "#1759e8" : "#f7fae8" }}
          >
            <div className="p-8 space-y-4 rounded-lg ">
              <div className="w-full">
                <div
                  className="flex flex-col md:flex-row justify-between gap-8"
                  style={{ color: backgroundColor }}
                >
                  <div className=" items-center gap-2 flex rounded-lg ">
                    <h6 className="text-xl font-semibold ">
                      {data.data[0].tier.name}
                    </h6>
                    <h6 className="text-lg font-semibold bg-green-100 px-2 rounded-lg text-green-500">
                      {data.data[0].tierdurationType}
                    </h6>
                  </div>
                  <div>
                    <h4 className="text-3xl font-medium ">
                      ₹ {numberWithCommas(data.data[0].tier.price / 100)}
                      <span className="ml-2 text-base  ">per month</span>
                    </h4>
                  </div>
                </div>
                <p className="text-base pt-4 pb-8 font-medium ">
                  {data.data[0].tier.description}
                </p>
                <div className="mt-4">
                  <p className="text-base pb-2 font-medium">
                    {patients.data ? patients.data.length : 0} of{" "}
                    {data.data[0].tier.patients}
                  </p>
                  {patients.data && patients.data.length && (
                    <LinearProgress
                      variant="determinate"
                      value={Math.round(
                        (100 / data.data[0].tier.patients) *
                          patients.data.length
                      )}
                      color="success"
                    />
                  )}
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-4 pb-2 mr-2 w-full flex justify-end">
              <Link state={{ user: userData.userData[0] }} to="/tier-purchase">
                <Button
                  sx={{ color: backgroundColor }}
                  endIcon={<ArrowOutward />}
                >
                  Upgrade Plan
                </Button>
              </Link>
            </div>
          </div>
          <div
            className="w-full border rounded-lg flex flex-col justify-between"
            style={{ backgroundColor: isDarkMode ? "#1759e8" : "#f7fae8" }}
          >
            <div className="p-8 space-y-4 rounded-lg ">
              <div className="w-full">
                <div className="flex flex-col">
                  <div className=" items-center gap-2 flex rounded-lg ">
                    <h6
                      className="text-xl font-semibold"
                      style={{ color: backgroundColor }}
                    >
                      Subscription Detail
                    </h6>
                  </div>

                  <p
                    className="text-base pt-4 font-medium"
                    style={{ color: isDarkMode ? "gray[600]" : "#857c08" }}
                  >
                    Purchased on:{" "}
                    <span
                      className="text-lg"
                      style={{ color: isDarkMode ? "gray[600]" : "#423a11" }}
                    >
                      {moment(data.data[0].startsAt).format("DD MMMM YYYY")}
                    </span>
                  </p>
                  <p
                    className="text-base font-medium"
                    style={{ color: isDarkMode ? "gray[600]" : "#857c08" }}
                  >
                    Next Due:{" "}
                    <span
                      className="text-lg"
                      style={{ color: isDarkMode ? "gray[600]" : "#423a11" }}
                    >
                      {moment(data.data[0].endsAt).format("DD MMMM YYYY")}
                    </span>
                  </p>
                  {patients.data && patients.data.length && (
                    <p
                      className="text-base font-medium"
                      style={{ color: isDarkMode ? "gray[600]" : "#857c08" }}
                    >
                      Amount Payable:{" "}
                      <span
                        className="text-lg"
                        style={{ color: isDarkMode ? "gray[600]" : "#423a11" }}
                      >
                        ₹{numberWithCommas(nextAmount())}
                      </span>
                    </p>
                  )}

                  <div className="mt-4 flex justify-between">
                    <p
                      className="text-base pb-2 font-medium"
                      style={{ color: isDarkMode ? "gray[600]" : "#857c08" }}
                    >
                      Payment:{" "}
                      <span className="text-sm font-semibold bg-green-100 px-2 rounded-sm text-green-500">
                        {data.data[0].status === "active" ? "Done" : "Not Done"}
                      </span>
                    </p>
                    <p
                      className="text-base pb-2 font-medium "
                      style={{ color: isDarkMode ? "gray[600]" : "#857c08" }}
                    >
                      Status:{" "}
                      <span className="text-sm font-semibold bg-green-100 px-2 rounded-sm text-green-500">
                        {data.data[0].status}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-4 pb-2 mr-2 w-full flex justify-end">
              <Link state={{ user: userData.userData[0] }} to="/tier-purchase">
                <Button
                  style={{ color: isDarkMode ? "gray[600]" : "#857c08" }}
                  sx={{ color: "white" }}
                  endIcon={<ArrowOutward />}
                >
                  View More Detail
                </Button>
              </Link>
            </div>
          </div>
        </div>
      )}
      <p
        className="mt-8 font-medium text-base mb-4"
        style={{ color: isDarkMode ? "gray[600]" : "#d6a53a" }}
      >
        Billing and Invoices.
      </p>
      {/* Table */}
      <TableContainer className="w-full">
        <table className="border-collapse w-full">
          <thead>
            <tr>
              <th className="p-2 border" style={{ color: backgroundColor }}>
                {" "}
                {/* Added padding and border */}
                Invoice
              </th>
              <th className="p-2 border" style={{ color: backgroundColor }}>
                {" "}
                {/* Added padding and border */}
                Billing Date
              </th>
              <th className="p-2 border" style={{ color: backgroundColor }}>
                {" "}
                {/* Added padding and border */}
                Status
              </th>
              <th className="p-2 border" style={{ color: backgroundColor }}>
                {" "}
                {/* Added padding and border */}
                Amount Plan
              </th>
              <th className="p-2 border" style={{ color: backgroundColor }}>
                Download
              </th>{" "}
              {/* Added padding and border */}
            </tr>
          </thead>
          <tbody>
            {invoices.data &&
              invoices.data.data &&
              invoices.data.data.items.map((invoice) => (
                <tr key={invoice.id}>
                  <td className="p-2 border text-center">{invoice.id}</td>{" "}
                  {/* Added padding and border */}
                  <td className="p-2 border text-center">
                    {moment(invoice.date * 1000).format("DD MMMM YYYY")}
                  </td>{" "}
                  {/* Added padding and border */}
                  <td className="p-2 border text-center">
                    {invoice.status}
                  </td>{" "}
                  {/* Added padding and border */}
                  <td className="p-2 border text-center">
                    ₹{numberWithCommas(invoice.amount / 100)}
                  </td>{" "}
                  {/* Added padding and border */}
                  <td className="p-2 border text-center">
                    <IconButton
                      onClick={() => {
                        handleRedirectBill(invoice.short_url);
                      }}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
}
