import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useLocation, useOutletContext } from "react-router-dom";
import { useGetCustomersQuery, useUpdatePrescriptionMutation } from "state/api";
import { Fab } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const PrescriptionDetail = () => {
 
  const theme = useTheme();
  const [userData] = useOutletContext()
  const location = useLocation()
  const prescription = location.state.prescription;
  const { data, isLoading } = useGetCustomersQuery(userData.userData[0].userReferralCode);
  const [userId, setUserId] = useState(prescription.patient._id)
  const [updatePrescription] = useUpdatePrescriptionMutation()
  const [diagnosis, setDiagnosis] = useState(prescription.diagnosis)
  const [complain, setComplain] = useState(prescription.complaint)
  const [conclusion, setConclusion] = useState(prescription.conclusion)
  const [observations, setObservation] = useState(prescription.observation);
  const isDarkMode = theme.palette.mode === 'dark';
  const navigate = useNavigate();
 

  const [labOrders, setLabOrders] = useState(prescription.labOrders);
  const [comments, setComments] = useState(prescription.comments)
  const [prescriptions, setPrescriptions] = useState(prescription.prescriptions)

  const handleSubmit = (event) => {
    event.preventDefault();
    if (diagnosis.length === 0 || complain.length === 0) {
      alert("Please enter all fields!")
    } else {
      const prescriptionUpdated = {
        partner:userData.userData[0]._id,
        patient: userId,
        diagnosis: diagnosis,
        complaint: complain,
        conclusion: conclusion,
        _id: prescription._id,
        observation: observations,
        prescriptions: prescriptions,
        labOrders: labOrders,
        comments: comments
      }
      updatePrescription(prescriptionUpdated).unwrap().then((d)=>{
        alert("Prescription updated successfully!")
        
      }).catch((e)=>{
        alert("Something went wrong while creating prescription!")
      })
    }
  };

  return (
    <div
      style={{
        backgroundColor: theme.palette.mode === "dark" ? "#090e45" : "#8f8f85",
        minHeight: "100vh",
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start", 
        margin: "8px",
        borderRadius: "8px",
        
      }}
    >

<div className='flex flex-col md:flex-row mb-10'>
        <Fab
            color="primary"
            aria-label="back"
            size="small"
            sx={{
                backgroundColor: isDarkMode ? theme.palette.primary.light : theme.palette.secondary.light,
            }}
            onClick={() => { navigate(-1); }} // Navigate back to the previous screen
        >
            <ArrowBack />
        </Fab>
    </div>
      <h1
        style={{
          color: "white",
          fontSize: "32px",
          fontWeight: "bold",
          marginBottom: theme.spacing(6),
        }}
      >
        Prescription
      </h1>
      {isLoading === false ? (
        <form
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            color: "black",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginBottom: theme.spacing(4),
            }}
          >
             <div style={{ display: "flex", gap:"8px", marginBottom: theme.spacing(4), alignItems: "center" }}>
              <label htmlFor="complaint"  style={{ marginBottom: theme.spacing(1), color: "white" }}>
                Patient:
              </label>

              
              <label htmlFor="complaint" className=" font-bold" style={{ marginBottom: theme.spacing(1), color: "white" }}>
                {prescription.patient.name}
              </label>
              
            </div>
            <h2 style={{ color: "white", marginBottom: "8px" }}>Clinical Notes</h2>
            <div style={{ display: "flex", marginBottom: theme.spacing(4) }} className="flex gap-4 flex-col md:flex-row">
              <label htmlFor="complaint" style={{ marginBottom: theme.spacing(1), color: "white" }}>
                Complaints
              </label>

              <div style={{ flex: 1 }} >
              {
                complain.map((c, i)=>{
                 return <div key={i} className="flex gap-2">
                  <textarea
                 
                  onChange={(e)=>{
                    let tempComplain = complain
                    tempComplain[i] = e.target.value
                    setComplain(tempComplain)
                  }}
                  defaultValue={c}
                  aria-multiline={true}
                  type="text"
                  placeholder="Complain"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginBottom: theme.spacing(2),
                  }}
                />
                { i === complain.length-1 &&  <button
                 type="button"
                 onClick={()=>{
                  
                  setComplain((prevCom)=> prevCom.filter((v,index)=> index !== i))
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  -
                </button>}
                  </div>
                })
              }
                 
                <button
                 type="button"
                 onClick={()=>{
                  
                  setComplain([...complain, ""])
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "#59c229",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "36px",
                    height: "36px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  +
                </button>
              </div>
            </div>
            <hr style={{ borderTop: "1px solid #ccc", marginBottom: theme.spacing(4) }} />

            <div style={{ display: "flex", marginBottom: theme.spacing(4) }} className="flex gap-4 flex-col md:flex-row">
              <h2 style={{ color: "white" }}>Observations</h2>
              
              <div style={{ flex: 1 }}>
              {
                observations.map((c, i)=>{
                 return <div  key={i} className="flex gap-2">
                  <textarea
                 defaultValue={c}
                  onChange={(e)=>{
                    let tempObs = observations
                    tempObs[i] = e.target.value
                    setObservation(tempObs)
                  }}
                  aria-multiline={true}
                  type="text"
                  placeholder="Observation"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginBottom: theme.spacing(2),
                  }}
                />
                { i === observations.length-1 &&  <button
                 type="button"
                 onClick={()=>{
                  
                  setObservation((prevOb)=> prevOb.filter((v,index)=> index !== i))
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  -
                </button>}
                  </div>
                })
              }
                 
                <button
                 type="button"
                 onClick={()=>{
                  
                  setObservation([...observations, ""])
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "#59c229",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "36px",
                    height: "36px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  +
                </button>
              </div>
               
             
            </div>
            <hr style={{ borderTop: "1px solid #ccc", marginBottom: theme.spacing(4) }} />

            <div style={{ display: "flex", marginBottom: theme.spacing(4) }} className="flex gap-4 flex-col md:flex-row">
              <h2 style={{ color: "white" }}>Diagnosis</h2>
              <div style={{ flex: 1 }}>
                
              <div style={{ flex: 1 }}>
              {
                diagnosis.map((c, i)=>{
                 return <div  key={i} className="flex gap-2">
                  <textarea
                 defaultValue={c}
                  onChange={(e)=>{
                    let tempObs = diagnosis
                    tempObs[i] = e.target.value
                    setDiagnosis(tempObs)
                  }}
                  aria-multiline={true}
                  type="text"
                  placeholder="Diagnosis"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginBottom: theme.spacing(2),
                  }}
                />
                { i === diagnosis.length-1 &&  <button
                 type="button"
                 onClick={()=>{
                  
                  setDiagnosis((prevOb)=> prevOb.filter((v,index)=> index !== i))
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  -
                </button>}
                  </div>
                })
              }
                 
                <button
                 type="button"
                 onClick={()=>{
                  
                  setDiagnosis([...diagnosis, ""])
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "#59c229",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "36px",
                    height: "36px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  +
                </button>
              </div>
              </div>
            </div>
            <hr style={{ borderTop: "1px solid #ccc", marginBottom: theme.spacing(4) }} />

            <h2 style={{ color: "white" }}>Lab Orders</h2>
            <div style={{ display: "flex", marginBottom: "8px"}} className="gap-2">
              <div style={{ flex: 1 }}>
                <label htmlFor="test">Test</label>
                 
                {
                labOrders.map((c, i)=>{
                 return <div  key={i} className="flex gap-2">
                  <textarea
                 defaultValue={c.test}
                  onChange={(e)=>{
                    let tempObs = labOrders
                    tempObs[i].test = e.target.value
                    setLabOrders(tempObs)
                  }}
                  aria-multiline={true}
                  type="text"
                  placeholder="Test"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginBottom: theme.spacing(2),
                  }}
                />
              
                  </div>
                })
              }
                <button
                type="button"
                onClick={()=>{
                  setLabOrders([...labOrders, {test: "", instructions: ""}])
                }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "#59c229",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "36px",
                    height: "36px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  +
                </button>
         
              </div>
              <div style={{ flex: 1 }}>
                <label htmlFor="instruction">Instruction</label>
                {
                labOrders.map((c, i)=>{
                 return <div  key={i} className="flex gap-2">
                  <textarea
                 defaultValue={c.instructions}
                  onChange={(e)=>{
                    let tempObs = labOrders
                    tempObs[i].instructions = e.target.value
                    setLabOrders(tempObs)
                  }}
                  aria-multiline={true}
                  type="text"
                  placeholder="Instructions"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginBottom: theme.spacing(2),
                  }}
                />
              
                  </div>
                })
              }
                    <button
                 type="button"
                 onClick={()=>{
                  setLabOrders((prevPres)=> {
                     
                    const updatedItems = prevPres.slice();
                    
                    updatedItems.splice(prevPres.length-1, 1);
                    
                    return updatedItems;
                  })
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "36px",
                    height: "36px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  -
                </button>
              </div>
            </div>
            <hr style={{ borderTop: "1px solid #ccc", marginBottom: theme.spacing(4) }} />

            <h2 style={{ color: "white", marginBottom: "8px" }}>Prescriptions</h2>
            <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
             {
              prescriptions.map((p,i)=>{
                return  <div key={i} className="flex flex-col gap-2">
                  <div className="flex flex-col md:flex-row  gap-2">
                   <div className="flex-col gap-2">
              <div style={{ color: '#59c229', marginBottom: "8px"  }}>Drug</div>
                <input
                  type="text"
                  placeholder="Drug"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                  defaultValue={p.drug}
                  onChange={(e)=>{
                    let tempPres = prescriptions;
                    tempPres[i].drug = e.target.value
                    setPrescriptions(tempPres)
                  }}
                />
              </div>
              <div className="flex-col gap-2">
              <div style={{ color: '#59c229', marginBottom: "8px"  }}>Frequency</div>
                <input
                  type="text"
                  defaultValue={p.frequency}
                  placeholder="Frequency"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                  onChange={(e)=>{
                    let tempPres = prescriptions;
                    tempPres[i].frequency = e.target.value
                    setPrescriptions(tempPres)
                  }}
                />
              </div>
              <div className="flex-col gap-2">
              <div style={{ color: '#59c229', marginBottom: "8px"  }}>Duration</div>
                <input
                  type="text"
                  defaultValue={p.duration}
                  placeholder="Duration"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                  onChange={(e)=>{
                    let tempPres = prescriptions;
                    tempPres[i].duration = e.target.value
                    setPrescriptions(tempPres)
                  }}
                />
              </div>
              <div className="flex-col gap-2">
              <div style={{ color: '#59c229', marginBottom: "8px" }}>Instructions</div>
                <input
                  type="text"
                  defaultValue={p.instruction}
                  placeholder="Instructions"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                  onChange={(e)=>{
                    let tempPres = prescriptions;
                    tempPres[i].instruction = e.target.value
                    setPrescriptions(tempPres)
                  }}
                />
              </div>
                 </div>
               <hr className="border md:border-0"/>
                </div>
              })
             }
            </div>
            
            </div>
            <div className="flex justify-between gap-2">
            <button
            type="button"
              onClick={()=>{
                setPrescriptions((prevPres)=> [...prevPres, {drug:"", frequency: "", duration: "", instructions: ""}])
              }}
              style={{
                marginLeft: theme.spacing(2),
                borderRadius: "50%",
                backgroundColor: "#59c229",
                color: "#fff",
                padding: "6px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "36px",
                height: "36px",
                border: "none",
                cursor: "pointer",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              +
            </button>
            <button
                 type="button"
                 onClick={()=>{
                  setPrescriptions((prevPres)=> {
                     
                    const updatedItems = prevPres.slice();
                    
                    updatedItems.splice(prevPres.length-1, 1);
                    
                    return updatedItems;
                  })
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "36px",
                    height: "36px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  -
             </button>
             </div>
            <hr style={{ borderTop: "1px solid #ccc", marginBottom: theme.spacing(4) }} />

            <h2 style={{ color: "white" }}>Comments</h2>
            <textarea
              type="text"
              placeholder="Enter comments"
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginBottom: theme.spacing(2),
              }}
              defaultValue={comments[0]}
              onChange={(e)=>{
                let tempCom = comments
                comments[0] = e.target.value
                setComments(tempCom)
              }}
            />
          </div>

          <button
            type="submit"
            style={{
              backgroundColor: "#59c229",
              color: "#fff",
              padding: "12px 24px",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "bold",
              transition: "background-color 0.2s ease",
            }}
          >
            Submit
          </button>
        </form>
      ) : (
        <div className="loader" />
      )}
    </div>
  );
};

export default PrescriptionDetail;
