import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { ResponsiveLine } from '@nivo/line';
import { useTheme } from '@mui/material';

const BizarreCurveGraph = ({ data, title }) => {
    const theme = useTheme()
   const margin =  { top: 20, bottom: 50, left: 40 }
  return (
          <ResponsiveLine
              data={data}
              theme={{
                  axis: {
                      domain: {
                          line: {
                              stroke: theme.palette.secondary[200],
                          },
                      },
                      legend: {
                          text: {
                              fill: theme.palette.secondary[200],
                          },
                      },
                      ticks: {
                          line: {
                              stroke: theme.palette.secondary[200],
                              strokeWidth: 1,
                          },
                          text: {
                              fill: theme.palette.secondary[200],
                          },
                      },
                      class: 'text-sm lg:text-base whitespace-nowrap md:whitespace-normal md:flex-wrap truncated',

                  },
                  legends: {
                      text: {
                          fill: theme.palette.secondary[200],
                      },
                  },
                  tooltip: {
                      container: {
                          color: theme.palette.primary.main,
                      },
                  },
              }}

              margin={margin}
              xScale={{ type: "point" }}
              yScale={{
                  type: "band",
                  min: "auto",
                  max: "auto",
                  stacked: false,
                  reverse: false,
              }}
               
              curve="catmullRom"
              enableArea={true}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                  format: (v) => {
                      // abbreviate month names
                      const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                      return monthAbbreviations[v - 1];
                  },
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0, // rotate the labels by 45 degrees
                  legend: "Date",
                  legendOffset: 40,
                  legendPosition: "middle",
                  tickMargin: 5,


                  // display only 5 tick values
              }}
              axisLeft={{
                  orient: "left",
                  tickValues: 5,
                  tickSize: 3,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "",
                  legendOffset: -40,
                  legendPosition: "middle",
              }}
              enableGridX={false}
              enableGridY={false}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}

          />
  )
};

export default BizarreCurveGraph;
