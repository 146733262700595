import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetVideoCallTokenQuery } from "state/api";
import { useTheme } from "@mui/material/styles";
import { tokensDark, tokensLight } from "theme";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
  Clear,
  UpdateRounded,
} from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import { ArrowUpward } from "@mui/icons-material";
import { ArrowDownward } from "@mui/icons-material";

const SubscriptionTable = ({ appointments }) => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const navigate = useNavigate();

  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterCriteria, setFilterCriteria] = useState({
    patientName: "",
    planName: "",
    duration: "",
    startDate: "",
    endDate: "",
    phoneNumber: "",
    status: "",
  });
  const [filteredAppointments, setFilteredAppointments] =
    useState(appointments); // New state for filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };
  const sortTableData = () => {
    const sortedData = [...appointments].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    console.log(sortedData);
    setFilteredAppointments(sortedData);
  };

  useEffect(() => {
    sortTableData();
  }, [sortOrder]);
  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = appointments.filter((appointment) => {
      const name = appointment.user.name;
      const planName = appointment.plan.name;
      const days = appointment.days;
      const startsAt = new Date(appointment.startsAt);
      const endsAt = new Date(appointment.endsAt);
      const mobile = appointment.user.mobile;
      const status = appointment.status;

      const {
        patientName: patientNameFilter,
        planName: planNameFilter,
        duration: durationFilter,
        startDate: startDateFilter,
        endDate: endDateFilter,
        phoneNumber: phoneNumberFilter,
        status: statusFilter,
      } = filterCriteria;

      const isPatientNameMatch =
        !patientNameFilter ||
        name.toLowerCase().includes(patientNameFilter.toLowerCase());
      const isPlanNameMatch =
        !planNameFilter ||
        planName.toLowerCase().includes(planNameFilter.toLowerCase());
      const isDurationMatch =
        !durationFilter || days.toString().includes(durationFilter);
      const isStartDateMatch =
        !startDateFilter ||
        moment(startsAt).format("DD/MM/YYYY HH:mm").includes(startDateFilter);
      const isEndDateMatch =
        !endDateFilter ||
        moment(endsAt).format("DD/MM/YYYY HH:mm").includes(endDateFilter);
      const isPhoneNumberMatch =
        !phoneNumberFilter || mobile.includes(phoneNumberFilter);
      const isStatusMatch =
        !statusFilter ||
        getStatusText(status)
          .toLowerCase()
          .includes(statusFilter.toLowerCase());

      return (
        isPatientNameMatch &&
        isPlanNameMatch &&
        isDurationMatch &&
        isStartDateMatch &&
        isEndDateMatch &&
        isPhoneNumberMatch &&
        isStatusMatch
      );
    });

    setFilteredAppointments(filteredData);
    setCurrentPage(1);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    console.log(query);

    const searchedData = appointments.filter((appointment) => {
      const name = appointment.user.name;
      const planName = appointment.plan.name;
      const days = appointment.days;
      const startsAt = new Date(appointment.startsAt);
      const endsAt = new Date(appointment.endsAt);
      const mobile = appointment.user.mobile;
      const status = appointment.status;

      const isNameMatch = name.toLowerCase().includes(query.toLowerCase());
      const isPlanNameMatch = planName
        .toLowerCase()
        .includes(query.toLowerCase());
      const isDurationMatch = days.toString().includes(query);
      const isStartDateMatch = new Date(startsAt)
        .toLocaleDateString()
        .includes(query);
      const isEndDateMatch = new Date(endsAt)
        .toLocaleDateString()
        .includes(query);
      const isPhoneNumberMatch = mobile.includes(query);
      const isStatusMatch = status.toLowerCase().includes(query.toLowerCase());

      return (
        isNameMatch ||
        isPlanNameMatch ||
        isDurationMatch ||
        isStartDateMatch ||
        isEndDateMatch ||
        isPhoneNumberMatch ||
        isStatusMatch
      );
    });

    console.log(searchedData);
    // Perform further actions with the filtered data

    setFilteredAppointments(searchedData);
    setCurrentPage(1);
  };

  const handleDownload = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredAppointments);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "subscriptions.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data) => {
    const header = [
      "Patient Name",
      "Plan Name",
      "Duration",
      "Start Date",
      "End Date",
      "Phone Number",
      "Status",
    ];
    const rows = data.map((appointment) => [
      appointment.user.name,
      appointment.plan.name,
      appointment.days,
      appointment.startsAt,
      appointment.endsAt,
      `"${appointment.user.mobile}"`, // Treat phone number as a string
      appointment.status,
    ]);

    const csvData = [header, ...rows]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");
    return csvData;
  };

  const handlePrint = () => {
    const content = tableRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      name: "",
      schedule: "",
      painPoint: "",
      user: "",
      status: "",
    });
    setFilteredAppointments(appointments); // Reset filtered results to original data
    setCurrentPage(1);
  };

  const getStatusText = (status) => {
    switch (status) {
      case "active":
        return "Active";
      case "paid":
        return "Paid";
      case "created":
        return "Created";
      case "expired":
        return "Expired";
      case "cancelled":
        return "Cancelled";
      case "completed":
        return "Completed";
      default:
        return "";
    }
  };

  const handleRowClick = (appointment) => {
    if (selectedSubscription === appointment) {
      setSelectedSubscription(null);
    } else {
      setSelectedSubscription(appointment);
    }
  };

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAppointments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredAppointments.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderRow = (appointment, idx) => {
    const backgroundColor = theme.palette.primary.light;

    const { startsAt, endsAt, status, plan, days } = appointment;
    const { name, mobile } = appointment.user;
    return (
      <TableRow
        sx={{
          backgroundColor: backgroundColor,
          color: isDarkMode
            ? tokensDark.secondary[800]
            : tokensDark.secondary[700],
          ":hover": {
            backgroundColor: isDarkMode
              ? tokensDark.primary[300]
              : tokensDark.grey[200],
          },
        }}
        key={appointment.id}
        onClick={() => handleRowClick(appointment)}
      >
        <TableCell>{name}</TableCell>
        <TableCell>{plan.name}</TableCell>
        <TableCell>{days}</TableCell>
        <TableCell>{moment(startsAt).format("DD/MM/YYYY HH:mm")}</TableCell>
        <TableCell>{moment(endsAt).format("DD/MM/YYYY HH:mm")}</TableCell>
        <TableCell>{mobile}</TableCell>
        <TableCell>{getStatusText(status)}</TableCell>
      </TableRow>
    );
  };
  const HandleStartCall = (appointment) => {
    navigate(`/video-call?appointment=${appointment._id}`);
  };
  const renderExpandedRow = (appointment, idx) => {
    const backgroundColor = theme.palette.background.alt;
    return (
      <TableRow
        sx={{
          cursor: "pointer",
          backgroundColor: backgroundColor,
          color: isDarkMode
            ? tokensDark.secondary[800]
            : tokensDark.primary[700],
        }}
        key={`${appointment.id}-expanded`}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, cursor: "pointer" }}
          colSpan={5}
        >
          <div className="expanded-row">
            <Button
              style={{
                margin: "8px",
                backgroundColor: "#00bc40",
                color: "white",
              }}
              onClick={() =>
                appointment.status === "active"
                  ? HandleStartCall(appointment)
                  : alert("Appointment not active yet!")
              }
            >
              Start call
            </Button>
            <Button
              style={{
                margin: "8px",
                backgroundColor: "#f81644",
                color: "white",
              }}
              onClick={() => console.log("Cancel appointment clicked")}
            >
              Cancel appointment
            </Button>
            <Button
              style={{
                margin: "8px",
                backgroundColor: "#0075a6",
                color: "white",
              }}
              onClick={() => console.log("View medical record clicked")}
            >
              View medical record
            </Button>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <div className="flex justify-between mb-4">
        <ToastContainer
          containerId="toast"
          autoClose={2000}
          position="top-center"
          hideProgressBar={true}
        />

        <ButtonGroup>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearch}
          />
          <IconButton onClick={handleDownload}>
            <GetApp />
          </IconButton>
          <IconButton onClick={handlePrint}>
            <Print />
          </IconButton>
          <IconButton>
            <ViewColumn />
          </IconButton>
          <IconButton onClick={handleFilterDialogOpen}>
            <FilterList />
          </IconButton>
        </ButtonGroup>
      </div>
      <TableContainer
        ref={tableRef}
        style={{ marginTop: "16px", height: "500px", scrollbarWidth: 0 }}
        component={Paper}
      >
       <Table stickyHeader={true}>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
                fontWeight: "bold",
              }}
            >
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}
              >Patient name</TableCell>
               <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}
              >Plan Name</TableCell>
               <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}
              >Duration</TableCell>
              <TableCell
              onClick={handleSortOrderChange}
                style={{ backgroundColor: theme.palette.background.alt }}
              >Start Date {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}{" "}</TableCell>
               <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}
              >End Date</TableCell>
               <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}
              >Phone number</TableCell>
              <TableCell style={{ backgroundColor: theme.palette.background.alt }} >
                Status{" "}
              
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((appointment, idx) => (
              <>
                {renderRow(appointment, idx)}
                {/* {selectedSubscription === appointment && renderExpandedRow(appointment, idx)} */}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <div className="justify-center mt-4   overflow-x-auto">
        <ButtonGroup>
          <Button
            style={{ color: isDarkMode ? "white" : "#755a0e",border:"solid"  }}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => (
            <Button
              style={{ color: isDarkMode ? "white" : "#755a0e" ,border:"solid" }}
              key={i + 1}
              variant={currentPage === i + 1 ? "contained" : "outlined"}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Button>
          ))}
          <Button
            style={{ color: isDarkMode ? "white" : "#755a0e" ,border:"solid" }}
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>

      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Subscriptions</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Patient name"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.name}
            onChange={(e) => handleFilterCriteriaChange(e, "name")}
          />

          <TextField
            margin="dense"
            label="Plan Name"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.planName}
            onChange={(e) => handleFilterCriteriaChange(e, "planName")}
          />

          <TextField
            margin="dense"
            label="Duration"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.duration}
            onChange={(e) => handleFilterCriteriaChange(e, "duration")}
          />

          <TextField
            margin="dense"
            label="Start Date"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.startDate}
            onChange={(e) => handleFilterCriteriaChange(e, "startDate")}
          />

          <TextField
            margin="dense"
            label="End Date"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.endDate}
            onChange={(e) => handleFilterCriteriaChange(e, "endDate")}
          />

          <TextField
            margin="dense"
            label="Phone number"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.phoneNumber}
            onChange={(e) => handleFilterCriteriaChange(e, "phoneNumber")}
          />

          <TextField
            margin="dense"
            label="Status"
            type="text"
            fullWidth
            variant="outlined"
            value={filterCriteria.status}
            onChange={(e) => handleFilterCriteriaChange(e, "status")}
          />
          <Button variant="contained" onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>

          <Button
            variant="contained"
            style={{ marginLeft: "8px" }}
            onClick={handleResetFilterCriteria}
          >
            Reset Filters
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubscriptionTable;
