import { ClearRounded } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCreateTierSubscriptionMutation, useVerifyTierTransactionMutation } from "state/api";


export const PricingThree = ({userData, data, tier}) => {
  const [createSubscription] = useCreateTierSubscriptionMutation()
  const [verifyTransaction] = useVerifyTierTransactionMutation()
  const [showOfferInput, setShowOfferInput] = useState(false)
  const [offerId, setOfferId] = useState()
  const [loading, setLoading] = useState(false)
  const [selectedTier, setSelectedTier] = useState()
   
  function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}
async function displayRazorpay(d) {
 
  setShowOfferInput(false)
 setLoading(true)
  createSubscription({user: userData, tier: d, offerId: offerId}).unwrap().then(async(s)=>{
    if(d.name === "Freemium"){
      setLoading(false)
      toast.success("Plan Purchased Successfully")
      window.location.replace('/')
    } else {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      
      // creating a new order
      // const result = await axios.post("http://localhost:300/payment/orders");
      
      // if (!result) {
      //     alert("Server error. Are you online?");
      //     return;
      // }
      
      // Getting the order details back
      
      setLoading(false)
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
       
        amount:d.price,
        name: "CasaMed",
         
        subscription_id: s.data.razorSubscription,
        handler: async function (response) {
            const data = {
                orderCreationId: s.data.razorSubscription,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
            };
      
            // console.log(data)
            // const result = await axios.post("http://localhost:5000/payment/success", data);
    
            //verify transaction.
            setLoading(true)
            verifyTransaction({razorpaySignature: data.razorpaySignature, razorpayOrderId: data.orderCreationId, orderId:s.data._id, razorpayPaymentId: data.razorpayPaymentId, transactionId: s.data.transactionId})
            .unwrap()
            .then((v)=>{
              setLoading(false)
              toast.success("Plan Purchased Successfully")
              window.location.replace('/')
            }).catch(e=>{
              setLoading(false)
              toast.error("Something went wrong")
            })
          
  
        },
        prefill: {
            name: userData.name,
            email: userData.email,
            contact: userData.mobile,
        },
        notes: {
            // address: "Soumya Dey Corporate Office",
        },
        theme: {
            color: "#61dafb",
        },
      };
      
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  }).catch((e)=>{
    setLoading(false)
    toast.error("Something went wrong")
  })


}
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
//offer_M1L0uxEvjVAy0K
  return (
    <>
      <div className=" py-10 flex flex-col text-center ">
      <ToastContainer autoClose={3000} position="top-center" hideProgressBar={true} />
      {
         showOfferInput &&  <div className="fixed inset-0 flex items-center justify-center z-50">
        
         <div className="bg-white rounded-lg p-5 shadow-lg flex flex-col ">
          <div className="flex items-end justify-end text-black">
            <IconButton onClick={()=>{setShowOfferInput(false)}}  sx={{color:"black"}}>
              <ClearRounded/>
            </IconButton>
          </div>
         <span className="text-lg text-gray-700 font-bold w-full ">Have Any Offer?</span>
          <input className="w-full mt-4 p-2 font-semibold border text-gray-700" onChange={(e)=>{
            setOfferId(e.target.value)}} placeholder="Enter offer id"/>
          <div className="flex justify-between gap-4">
            <Button sx={{marginTop:"18px"}} onClick={()=>{displayRazorpay(selectedTier)}} variant="contained">Proceed Without Offer</Button>
            <Button sx={{marginTop:"18px"}} onClick={()=>{displayRazorpay(selectedTier)}} variant="contained">Proceed</Button>
          </div>
         </div>
       </div>
      }
        {
          loading && <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-5 shadow-lg">
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
            </div>
            <p className="text-center text-gray-700 mt-4">Loading...</p>
          </div>
        </div>
        }
        <div className="w-full space-y-4 mb-10">
          <h1 className=" text-gray-200 text-5xl font-semibold">
            <span className="underline underline-offset-8 decoration-[#02B9ED]">C</span>hoose your B2B plan
          </h1>
          <p className="text-gray-300 font-medium text-base">
            We believe Freedom from Pain for your Patients should not be expensive.
          </p>
        </div>
        <div className="flex gap-2 flex-col md:grid md:grid-cols-2 md:gap-8 justify-around px-[2%]  rounded-lg ">
         {
           data.data.map((d, i)=>{
            return  <div key={d._id} className={`w-full ${d.bg} border rounded-lg flex flex-col justify-between`}>
            <div className="p-8 space-y-4 rounded-lg ">
              <div>
              <h6 className="text-xl pb-4 font-semibold text-white">
                {d.name}
              </h6>
              <h4 className="text-3xl font-medium text-white">
              ₹ {numberWithCommas(d.price/100)}<span className="ml-2 text-base">Monthly</span>
              </h4>
              <p className="text-base pt-4 pb-8 font-medium text-gray-300">
                {d.description}
              </p>
              <ul class="pb-4">
    <li class="flex justify-start items-center py-1 ">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left ">{d.userLevel} User Level Access</span></li>
  {d.chatSupport &&  <li class="flex justify-start items-center py-1 ">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left ">Chat Support</span></li>}
    <li class="flex justify-start items-center py-1 "><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left font-bold text-xl">{d.patients} Patients</span></li>
    {d.extra > 0 && <li class="flex justify-start items-center py-1 "><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left font-bold text-xl">₹ {d.extra/100} per Extra Patient</span></li>}
   {d.canAddExercise && d.canViewExercise && <li class="flex justify-start items-center py-1 "><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left ">Exercise Library - Add/View Access</span></li>}
  {d.canViewPlans && d.canAssignPlans &&  <li class="flex justify-start items-center py-1 "><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left ">Plan Library - View/Assign Access</span></li>}
    {d.canCreatePlan && d.canUpdatePlan && <li class="flex justify-start items-center py-1 "><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left undefined">Plan Create/Update Rights</span></li>}
   {d.patientExerciseAndWearables && <li class="flex justify-start items-center py-1 "><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left undefined">Patient Exercise and Wearables Monitoring</span></li>}
   {d.telemedicinePlatform && <li class="flex justify-start items-center py-1 "><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left undefined">Telemedicine Platform</span></li>}
   {d.unlimitedConsultation && <li class="flex justify-start items-center py-1 "><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left ">Unlimited Consultation</span></li>}
   {d.remoteAiEnabled && <li class="flex justify-start items-center py-1 "><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left ">Remote AI enabled ROM Monitoring</span></li>}
  {d.dedicatedServer &&  <li class="flex justify-start items-center py-1 "><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left ">Dedicated Server</span></li>}
  {d.userMedicalRecordStorage &&  <li class="flex justify-start items-center py-1 "><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left ">User Medical Records Storage</span></li>}
   {d.advancePatientDashboard && <li class="flex justify-start items-center py-1 "><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="mr-[10px] text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg><span class="text-gray-200 text-left ">Advanced Patient Vitals Monitoring Dashboard</span></li>}
   </ul>
              </div>
            </div>
              <div className="pb-8 px-8">
             
             { tier.length === 0 &&  <button onClick={()=>{
              setShowOfferInput(true)
              setSelectedTier(d)
              }} className="text-sm font-medium text-white bg-indigo-600 w-full py-2 px-5 rounded-md">
              Purchase Plan
              </button>}
              { tier.length > 0 && d._id !== tier[0].tier._id && d.name !== "Freemium" &&   <button onClick={()=>{
                setShowOfferInput(true)
                setSelectedTier(d)}} className="text-sm font-medium text-white bg-indigo-600 w-full py-2 px-5 rounded-md">
              Purchase Plan
              </button>}
              {tier.length > 0 && d._id === tier[0].tier._id &&  <button className="text-sm font-medium text-white bg-indigo-600 w-full py-2 px-5 rounded-md">
              Active Plan
              </button>}
               
              </div>
          </div>
           })
         }
         
        </div>
      </div>
    </>
  );
};

PricingThree.displayName = "PricingThree";