import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { useAddMedicalRecordMutationMutation } from "state/api";

export default function AddRecord(){
    const [document, setDocument] = useState()
    const [name, setName] = useState('')
    const query = useLocation().search;
    const user = new URLSearchParams(query).get("patient");
    const [error, setError] = useState(false)
    const [message, setMessage]= useState("")
    const [addMedicalRecord] = useAddMedicalRecordMutationMutation()
    const navigate = useNavigate()
    const handleSubmit = (event) =>{
        event.preventDefault();
        if (!document) {
            setError(true)
            setMessage("Please add document")
        } else if (name.trim().length <= 0) {
            setError(true)
            setMessage("Please enter document name")
        } else {
            const formData = new FormData();
            formData.append("file", document);
            formData.append("name", name);
            formData.append("user", user)
            addMedicalRecord(formData).unwrap().then((d)=>{
                navigate("/patients")
            }).catch((err)=>{
                setError(true)
                setMessage(err.data.message)
                setTimeout(()=>{
                    setError(false)
                },2000)
            })
        }
    }
     return (
        <div>
             <form onSubmit={handleSubmit} className="max-w-md w-full mx-auto p-6 mt-4 bg-white rounded-lg shadow-md text-black">
               
           <div className="mb-4">
                    <label htmlFor="photo" className="block font-medium text-gray-700 mb-2">Medical Document/Photo</label>
                    <input type="file"  id="photo" name="photo" className="border border-gray-300 p-2 rounded-lg w-full" onChange={(event) => setDocument(event.target.files[0])} />
                </div>

                <div className="mb-4">
                    <label htmlFor="name" className="block font-medium text-gray-700 mb-2">Document Name</label>
                    <input onChange={(e)=>{setName(e.target.value)}} type="text"  id="name" name="name" className="border border-gray-300 p-2 rounded-lg w-full"  />
                </div>
                <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-300 ease-in-out">Add Medical Record</button>
                {
                    error && <Box display="flex" borderRadius={2} mt={2} padding="8px" bgcolor="#ff9999" justifyContent="center" alignItems="center"><Typography align='center' variant='h6' color="#eb0a0a">{message}</Typography></Box>
             }
                </form>
        </div>
    )
}