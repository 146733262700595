import React from 'react'

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreatePlanMutation, useCreateSubscriptionMutation, useGetExercisesQuery, useGetPlanDetailsQuery, useUpdateCustomPlanMutation } from 'state/api';
 
import ExerciseView from './ExerciseView';

const ViewExercise = () => {
   const [name, setName] = useState('');
    const [weeks, setWeeks] = useState(1);
    const [department, setDepartment] = useState('');
    const [painPoints, setPainPoints] = useState([]);
    const [sports, setSports] = useState([]);
    const [multiWeekPlan, setMultiWeekPlan] = useState([])
    const {data, loading} = useGetExercisesQuery();
    const [selectedWeek, setSelectedWeek] = useState(0)
    const [numberOfSessions, setNumberOfSessions] = useState(1)
    const [createCustomPlan, response] = useCreatePlanMutation()
    const [updateCustomPlan, response2] = useUpdateCustomPlanMutation()
    const [error, setError] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState()
    // const query = useLocation().search;
    // const user = new URLSearchParams(query).get("user");
    // const partner = new URLSearchParams(query).get("partner")
    // const planId = new URLSearchParams(query).get("plan")
    
    
    return (
        <div className='flex flex-col md:flex-row'>
            
            <div className="w-full py-4 text-white m-4">

            {data && <ExerciseView showModal={showModal} setShowModal={setShowModal} exercises={data}/>}
            </div>
        </div>
   
   );
};

export default ViewExercise