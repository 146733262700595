import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  useGetMedicalRecordsQuery,
  useGetVideoCallTokenQuery,
  useSendJoinRequestMutation,
  useUpdateAppointmentMutation,
} from "state/api";
import { useTheme } from "@mui/material/styles";
import { tokensDark, tokensLight } from "theme";
import { toast } from "react-toastify";
import {
  Search,
  GetApp,
  Print,
  ViewColumn,
  FilterList,
  Clear,
  UpdateRounded,
} from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ArrowUpward } from "@mui/icons-material";
import { ArrowDownward } from "@mui/icons-material";

function encodeIdToNum(id) {
  // Convert the string representation of MongoDB ID to hexadecimal
  const hex = id.replace(/-/g, "");
  // Convert the hexadecimal to a decimal number
  const decimal = parseInt(hex, 16);
  // Take the modulo of the decimal number with a large prime number to reduce the output size
  const modulo = decimal % 15485867;
  // Convert the modulo back to a string and pad with leading zeros to make it a 5 digit number
  const num = modulo.toString().padStart(5, "0");
  return num.toString();
}

const AppointmentTable = ({ appointments }) => {
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [sendRequest, response] = useSendJoinRequestMutation();
  const [userData] = useOutletContext();
  const navigate = useNavigate();
  const [updateAppointment] = useUpdateAppointmentMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [sortSchedule, setSortSchedule] = useState("asc");
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const tableRef = useRef(null);

  const [filterCriteria, setFilterCriteria] = useState({
    name: "",
    date: "",
    painPoint: "",
    phoneNumber: "",
    status: "",
  });
  const [filteredAppointments, setFilteredAppointments] =
    useState(appointments); // New state for filtered results
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };
  const handleSortScheduleChange = () => {
    const newSortOrder = sortSchedule === "asc" ? "desc" : "asc";
    setSortSchedule(newSortOrder);
  };
  const sortTableData = () => {
    const sortedData = [...appointments].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });

    setFilteredAppointments(sortedData);
  };
  const sortTableOnSchedule = () => {
    const sortedData = [...appointments].sort((a, b) => {
      const dateA = new Date(a.schedule);
      const dateB = new Date(b.schedule);
      return sortSchedule === "asc" ? dateA - dateB : dateB - dateA;
    });

    setFilteredAppointments(sortedData);
  };

  useEffect(() => {
    sortTableData();
  }, [sortOrder]);
  useEffect(() => {
    sortTableOnSchedule();
  }, [sortSchedule]);

  const handleFilterCriteriaChange = (event, field) => {
    const { value } = event.target;
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      [field]: value,
    }));

    const filteredData = appointments.filter((appointment) => {
      const { name, mobile, email } = appointment.user;
      const { schedule, painPoint, status } = appointment;

      const {
        name: nameFilter,
        date: dateFilter,
        painPoint: painPointFilter,
        phoneNumber: phoneNumberFilter,
        status: statusFilter,
      } = filterCriteria;

      const isNameMatch =
        !nameFilter || name.toLowerCase().includes(nameFilter.toLowerCase());
      const isDateMatch =
        !dateFilter ||
        moment(schedule).format("DD/MM/YYYY HH:mm").includes(dateFilter);
      // const isPainPointMatch = !painPointFilter || painPoint.toLowerCase().includes(painPointFilter.toLowerCase());
      const isPhoneNumberMatch =
        !phoneNumberFilter || mobile.includes(phoneNumberFilter);
      const isStatusMatch =
        !statusFilter ||
        getStatusText(status)
          .toLowerCase()
          .includes(statusFilter.toLowerCase());

      return isNameMatch && isDateMatch && isPhoneNumberMatch && isStatusMatch;
    });

    setFilteredAppointments(filteredData);
    setCurrentPage(1);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    console.log(query);

    const searchedData = appointments.filter((appointment) => {
      const { name, mobile, email } = appointment.user;
      const { schedule, painPoint, status } = appointment;
      console.log(appointment);
      const isNameMatch = name.toLowerCase().includes(query.toLowerCase());
      const isDateMatch = moment(schedule)
        .format("DD/MM/YYYY HH:mm")
        .includes(query);
      //const isPainPointMatch = painPoint.toLowerCase().includes(query.toLowerCase());
      const isPhoneNumberMatch = mobile.includes(query);
      const isStatusMatch = getStatusText(status)
        .toLowerCase()
        .includes(query.toLowerCase());

      return isNameMatch || isDateMatch || isPhoneNumberMatch || isStatusMatch;
    });

    setFilteredAppointments(searchedData);
    setCurrentPage(1);
  };

  const handleDownload = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredAppointments);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "appointments.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data) => {
    const header = [
      "Patient Name",
      "Date",
      "Pain Point",
      "Phone Number",
      "Status",
    ];
    const rows = data.map((appointment) => [
      appointment.user.name,
      appointment.schedule,
      appointment.painPoint,
      `"${appointment.user.mobile}"`, // Treat phone number as a string
      appointment.status,
    ]);

    const csvData = [header, ...rows]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");
    return csvData;
  };

  const handlePrint = () => {
    const tableContent = tableRef.current.cloneNode(true);
    const buttons = tableContent.querySelectorAll("button");
    buttons.forEach((button) => button.remove());
    const content = tableContent.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setFilteredAppointments(appointments); // Reset filtered results to original data
  };

  const getStatusText = (status) => {
    switch (status) {
      case "active":
        return "Active";
      case "paid":
        return "Scheduled";
      case "created":
        return "Scheduled";
      case "expired":
        return "Expired";
      case "cancelled":
        return "Cancelled";
      case "completed":
        return "Completed";
      default:
        return "";
    }
  };

  const handleResetFilterCriteria = () => {
    setFilterCriteria({
      name: "",
      schedule: "",
      painPoint: "",
      user: "",
      status: "",
    });
    setFilteredAppointments(appointments); // Reset filtered results to original data
    setCurrentPage(1);
  };

  const handleRowClick = (appointment) => {
    if (selectedAppointment === appointment) {
      setSelectedAppointment(null);
    } else {
      setSelectedAppointment(appointment);
    }
  };

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAppointments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredAppointments.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleMedicalClick = (appointment) => {};
  const handleCancelAppointment = (appointment) => {
    if (appointment.status === "cancelled") {
      toast.error("Already cancelled!");
    } else {
      updateAppointment({
        id: appointment._id,
        status: "cancelled",
        cancelledBy: userData.userData[0]._id,
        cancelledByType: userData.userData[0].role,
        cancelled: true,
      })
        .unwrap()
        .then((d) => {
          setSelectedAppointment(d);
          toast.success("Appointment Cancelled Successfully!");
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }
  };
  const DateTimePickerModal = ({ showSchedule, setShowSchedule, patient }) => {
    const [selectedDateTime, setSelectedDateTime] = useState("");
    const [appointmentType, setAppointmentType] = useState("Physio Evaluation");
    const [department, setDepartment] = useState("orthopedic");

    const handleDateTimeChange = (e) => {
      setSelectedDateTime(e.target.value);
    };

    const handleSave = (patient) => {
      if (selectedDateTime.trim().length === 0) {
        alert("Please enter the date and time for the appointment");
      } else {
        const appointment = {
          id: patient._id,
          schedule: moment(selectedDateTime)
            .utc(true)
            .format("YYYY-MM-DD HH:mm"),
        };
        updateAppointment(appointment)
          .unwrap()
          .then((d) => {
            alert("Appointment rescheduled successfully!");
            setShowSchedule(false);
            window.location.reload();
          })
          .catch((e) => {
            console.log(e);
            alert(e.data.message);
          });
      }
    };

    return (
      <div className='fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50'>
        <div className='bg-white rounded-lg p-6'>
          <h2 className='text-lg text-gray-700 font-semibold mb-4'>
            Select a date and time
          </h2>
          <input
            type='datetime-local'
            value={selectedDateTime}
            onChange={handleDateTimeChange}
            className='border rounded-lg px-3 py-2 mb-4 text-gray-700'
          />
          {/* <h2 className="text-md text-gray-700 font-semibold mt-4">Choose Appointment For</h2>
          <select onChange={(e)=>{setAppointmentType(e.target.value)}} className='w-full text-gray-700'>
            <option value={"Physio Evaluation"}>
            Physio Evaluation
            </option>
            <option value={"Physio Session"}>Physio Session</option>
            <option value={"Doctor Consultation"}>Doctor Consultation</option>
            <option>Diet</option>
            <option>Dietician</option>
            <option value={"Home Visit"}>Home Visit</option>
          </select>
          <h2 className="text-md text-gray-700 font-semibold mt-4">Choose Department</h2>
          <select onChange={(e)=>{setDepartment(e.target.value)}} className='w-full text-gray-700'>
            <option value={"orthopedic"}>
            Orthopedic
            </option>
            <option value={"sports_injury"}>Sports</option>
            <option value={"gynaecology"}>Gynae</option>
            <option>Diet</option>
            <option>Dietician</option>
            <option value={"pcod"}>PCOD</option>
          </select> */}

          <div className='flex gap-2 justify-end mt-4'>
            <button
              onClick={() => {
                setShowSchedule(false);
              }}
              className='bg-red-500 hover:bg-red-600 text-white rounded-lg px-4 py-2'>
              Cancel
            </button>
            <button
              onClick={() => {
                handleSave(patient);
              }}
              className='bg-blue-500 hover:bg-blue-600 text-white rounded-lg px-4 py-2'>
              Schedule
            </button>
          </div>
        </div>
      </div>
    );
  };
  const tableRowStyle = {
    cursor: "pointer",
    margin: "8px",
    backgroundColor: isDarkMode
      ? tokensDark.primary[600]
      : tokensDark.secondary[300],
    color: isDarkMode ? tokensDark.secondary[800] : tokensDark.secondary[700],
  };

  const renderRow = (appointment, idx) => {
    const { schedule, department, status } = appointment;
    const { name, mobile } = appointment.user;
    const backgroundColor = theme.palette.primary.light;

    return (
      <TableRow
        sx={{
          backgroundColor: backgroundColor,
          color: isDarkMode
            ? tokensDark.secondary[800]
            : tokensDark.secondary[700],
          ":hover": {
            backgroundColor: isDarkMode
              ? tokensDark.primary[300]
              : tokensDark.grey[200],
          },
        }}
        key={appointment.id}
        onClick={() => handleRowClick(appointment)}>
        <TableCell>{name}</TableCell>
        <TableCell>
          {moment(schedule).utc(false).format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell>{department}</TableCell>
        <TableCell>{mobile}</TableCell>
        <TableCell>{getStatusText(status)}</TableCell>
      </TableRow>
    );
  };
  const HandleStartCall = (appointment) => {
    const callId = appointment._id;
    const message = {
      notification: {
        title: "Incoming Call",
        body: `Incoming Call From ${userData.userData[0].name}. Click here to join the call!`,
      },
      data: {
        title: "Incoming Call",
        content: `Incoming Call From ${userData.userData[0].name}. Click here to join the call!`,
        start_video_call: "true",
        channel_id: callId,
      },
      topic: `${appointment._id}-${appointment.user._id}`,
    };
    // console.log(message)
    sendRequest(message)
      .unwrap()
      .then((d) => {
        if (d) {
          navigate(`/video-call?appointment=${callId}`);
        }
      })
      .catch((err) => {
        alert(err.data.message);
      });
  };
  const [showSchedule, setShowSchedule] = useState(false);
  const renderExpandedRow = (appointment, idx) => {
    const backgroundColor = theme.palette.background.alt;
    const handleMedicalClick = (appointment) => {
      navigate(`/appointment/medicalrecord?patient=${appointment.user._id}`, {
        state: { appointment },
      });
    };
    const HandleChangeStatus = (appointment, status) => {
      updateAppointment({
        id: appointment._id,
        status: status,
      })
        .unwrap()
        .then((d) => {
          alert("Status Changed Successfully!");
          const updatedAppointments = [...filteredAppointments];
          const appointmentIndex = updatedAppointments.findIndex(
            (a) => a === appointment
          );
          if (appointmentIndex !== -1) {
            const updatedAppointment = {
              ...updatedAppointments[appointmentIndex],
              status: status,
            };
            updatedAppointments[appointmentIndex] = updatedAppointment;
            setFilteredAppointments(updatedAppointments);
          }
        })
        .catch((e) => {
          alert(e.data.message);
        });
    };
    return (
      <TableRow
        sx={{
          cursor: "pointer",
          backgroundColor: backgroundColor,
          color: isDarkMode
            ? tokensDark.secondary[800]
            : tokensDark.primary[700],
        }}
        key={`${appointment.id}-expanded`}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, cursor: "pointer" }}
          colSpan={5}>
          {showSchedule && (
            <DateTimePickerModal
              setShowSchedule={setShowSchedule}
              showSchedule={showSchedule}
              patient={appointment}
            />
          )}
          <div className='expanded-row'>
            {!userData.team && userData.userData[0].role !== "Client" && (
              <Button
                variant='outlined'
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() =>
                  appointment.status === "active"
                    ? HandleStartCall(appointment)
                    : alert("Appointment not active yet!")
                }>
                Start call
              </Button>
            )}
            {userData.team && userData.team.role !== "viewer" && (
              <Button
                variant='outlined'
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() =>
                  appointment.status === "active"
                    ? HandleStartCall(appointment)
                    : alert("Appointment not active yet!")
                }>
                Start call
              </Button>
            )}

            {!userData.team && userData.userData[0].role !== "Client" && (
              <Button
                variant='outlined'
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() => {
                  handleCancelAppointment(appointment);
                }}>
                Cancel appointment
              </Button>
            )}
            {userData.team && userData.team.role !== "viewer" && (
              <Button
                variant='outlined'
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() => {
                  handleCancelAppointment(appointment);
                }}>
                Cancel appointment
              </Button>
            )}
            {!userData.team && userData.userData[0].role !== "Client" && (
              <Button
                variant='outlined'
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() => {
                  appointment.status === "expired"
                    ? alert("Appointment Expired")
                    : setShowSchedule(true);
                }}>
                Reschedule
              </Button>
            )}
            {userData.team && userData.team.role !== "viewer" && (
              <Button
                variant='outlined'
                style={{
                  margin: "8px",
                  color: isDarkMode ? "white" : "black",
                  borderColor: isDarkMode ? "white" : "black",
                }}
                onClick={() => {
                  appointment.status === "expired"
                    ? alert("Appointment Expired")
                    : setShowSchedule(true);
                }}>
                Reschedule
              </Button>
            )}
            {!userData.team && userData.userData[0].role !== "Client" && (
              <Select
                value={"change"}
                variant='standard'
                className='m-2'
                onChange={(e) => {
                  HandleChangeStatus(appointment, e.target.value);
                }}>
                <MenuItem disabled value={"change"}>
                  Change Status
                </MenuItem>
                <MenuItem value={"active"}>Active</MenuItem>
                <MenuItem value={"completed"}>Completed</MenuItem>
                <MenuItem value={"expired"}>Expired</MenuItem>
                <MenuItem value={"paid"}>Scheduled</MenuItem>
              </Select>
            )}
            {userData.team && userData.team.role !== "viewer" && (
              <Select
                value={"change"}
                variant='standard'
                className='m-2'
                onChange={(e) => {
                  HandleChangeStatus(appointment, e.target.value);
                }}>
                <MenuItem disabled value={"change"}>
                  Change Status
                </MenuItem>
                <MenuItem value={"active"}>Active</MenuItem>
                <MenuItem value={"completed"}>Completed</MenuItem>
                <MenuItem value={"expired"}>Expired</MenuItem>
                <MenuItem value={"paid"}>Scheduled</MenuItem>
              </Select>
            )}
            <Button
              variant='outlined'
              style={{
                margin: "8px",
                color: isDarkMode ? "white" : "black",
                borderColor: isDarkMode ? "white" : "black",
              }}
              onClick={() => handleMedicalClick(appointment)}>
              View medical record
            </Button>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <div className='flex justify-between mb-4'>
        <ToastContainer
          containerId='toast'
          autoClose={2000}
          position='top-center'
          hideProgressBar={true}
        />

        <ButtonGroup sx={{ marginTop: "1rem" }}>
          <TextField
            label='Search'
            variant='outlined'
            size='small'
            value={searchQuery}
            onChange={handleSearch}
          />
          <IconButton onClick={handleDownload}>
            <GetApp />
          </IconButton>
          <IconButton onClick={handlePrint}>
            <Print />
          </IconButton>
          <IconButton>
            <ViewColumn />
          </IconButton>
          <IconButton onClick={handleFilterDialogOpen}>
            <FilterList />
          </IconButton>
        </ButtonGroup>
      </div>

      <TableContainer
        ref={tableRef}
        component={Paper}
        style={{ marginTop: "16px", height: "500px", scrollbarWidth: 0 }}>
        <Table stickyHeader={true}>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: isDarkMode
                  ? tokensDark.secondary[800]
                  : tokensDark.secondary[700],
                fontWeight: "bold",
              }}>
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}>
                Patient name
              </TableCell>
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}
                onClick={handleSortScheduleChange}>
                Date{" "}
                {sortSchedule === "asc" ? <ArrowDownward /> : <ArrowUpward />}
              </TableCell>
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}>
                Department
              </TableCell>
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}>
                Phone number
              </TableCell>
              <TableCell
                style={{ backgroundColor: theme.palette.background.alt }}
                onClick={handleSortOrderChange}>
                Status{" "}
                {sortOrder === "asc" ? <ArrowDownward /> : <ArrowUpward />}{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((appointment, idx) => (
              <>
                {renderRow(appointment, idx)}
                {selectedAppointment === appointment &&
                  renderExpandedRow(appointment, idx)}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <div className='justify-center mt-4   overflow-x-auto'>
        <ButtonGroup style={{ color: "#755a0e" }}>
          <Button
            style={{ color: isDarkMode ? "white" : "#755a0e", border: "solid" }}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}>
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => (
            <Button
              key={i + 1}
              style={{
                color: isDarkMode ? "white" : "#755a0e",
                border: "solid",
              }}
              variant={currentPage === i + 1 ? "contained" : "outlined"}
              onClick={() => handlePageChange(i + 1)}>
              {i + 1}
            </Button>
          ))}
          <Button
            style={{ color: isDarkMode ? "white" : "#755a0e", border: "solid" }}
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}>
            Next
          </Button>
        </ButtonGroup>
      </div>

      <Dialog open={openFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Table Data</DialogTitle>
        <DialogContent>
          <TextField
            margin='dense'
            label='Patient name'
            type='text'
            fullWidth
            variant='outlined'
            value={filterCriteria.name}
            onChange={(e) => handleFilterCriteriaChange(e, "name")}
          />
          <TextField
            margin='dense'
            label='Date'
            type='text'
            fullWidth
            variant='outlined'
            value={filterCriteria.date}
            onChange={(e) => handleFilterCriteriaChange(e, "date")}
          />
          <TextField
            margin='dense'
            label='Pain point'
            type='text'
            fullWidth
            variant='outlined'
            value={filterCriteria.painPoint}
            onChange={(e) => handleFilterCriteriaChange(e, "painPoint")}
          />
          <TextField
            margin='dense'
            label='Phone number'
            type='text'
            fullWidth
            variant='outlined'
            value={filterCriteria.phoneNumber}
            onChange={(e) => handleFilterCriteriaChange(e, "phoneNumber")}
          />
          <TextField
            margin='dense'
            label='Status'
            type='text'
            fullWidth
            variant='outlined'
            value={filterCriteria.status}
            onChange={(e) => handleFilterCriteriaChange(e, "status")}
          />

          <Button variant='contained' onClick={handleFilterDialogClose}>
            Apply Filter
          </Button>

          <Button
            variant='contained'
            style={{ marginLeft: "8px" }}
            onClick={handleResetFilterCriteria}>
            Reset Filters
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AppointmentTable;
