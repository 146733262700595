import React, {useState} from 'react'
import {
    Box,
    useTheme
} from "@mui/material"
import { DataGrid } from '@mui/x-data-grid'
import { useGetTransactionsQuery } from 'state/api'
import Header from 'components/Header'
import rows from "utils/subscriptions"
import DataGridCustomToolbar from 'components/DataGridCustomToolbar'
import moment from 'moment'
import { useOutlet, useOutletContext } from 'react-router-dom'

const Transactions = () => {
    const theme = useTheme()

    // values to be sent to the backend
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");
    const [userData] = useOutletContext()

    const [searchInput, setSearchInput] = useState("")

    const { data, isLoading } =  useGetTransactionsQuery({
        page,
        pageSize,
        sort: JSON.stringify(sort),
        search,
        referralCode: userData.userData[0].userReferralCode
    }); 
    console.log("dataTrans", data)
    // d.paymentId.amount/100 === NaN ? 0 : d.paymentId.amount/100
    const rows = [];
    if (data) {
      data.map((d, index)=>{
        const row = {
          "id": index+1,
          "systemsub_id":index+1,
          "createdAt": moment(d.createdAt).format('DD/MM/YYYY HH:mm'),
          "endsAt": null,
          "planMonths": 0,
          "razorpayOrderObject.amount": d.paymentId ? d.paymentId.amount/100 === NaN ? 0 : d.paymentId.amount/100 : 0,
          "plan_id": d.products[0].name,
          "startsAt": null,
          "status": d.status,
          "updatedAt": moment(d.updatedAt).format('DD/MM/YYYY HH:mm'),
          "user": d.user.name
        }
        rows.push(row)
      })
    }

    const columns = [
        {
          field: "systemsub_id",
          headerName: "Index",
          flex: 0.5,
        },
        {
          field: "user",
          headerName: "User Name",
          flex: 1,
        },
        {
          field: "createdAt",
          headerName: "CreatedAt",
          flex: 1,
        },
        {
          field: "status",
          headerName: "Subscription Status",
          flex: 1,
        },
        {
          field: "razorpayOrderObject.amount",
          headerName: "Amount",
          flex: 1,
        },
        {
          field: "plan_id",
          headerName: "Plan Name",
          flex: 1,
        },
        
      ];

  return (
    <Box m="1.5rem 2.5rem">
        <Header title="Transactions" subtitle="Get all of your patient transactions here!" />
        <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        {
          data &&
          
        <DataGrid
          
          
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
             
          }}
          
        />
}
      </Box>
    </Box>
  )
}

export default Transactions