import React, { useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import Header from "components/Header";
import { ResponsiveLine } from "@nivo/line";
// import { useGetSalesQuery } from "state/api";

import { dataOverallStat } from "utils/data";
import { useGetDashboardStatusQuery } from "state/api";
import { useOutletContext } from "react-router-dom";

const Monthly = () => {

  const theme = useTheme();
  const [userData] = useOutletContext()
  const dashboardStats = useGetDashboardStatusQuery(userData.userData[0].userReferralCode);
 
  const [totalSalesLine, totalSubscriptions] = useMemo(() => {
    if (!dashboardStats.data) return [];

    const monthlyData = dashboardStats.data.monthCustomers;
    const subscriptionData = dashboardStats.data.monthSubscriptions

    const totalSalesLine = {
      id: "Total Patients",
      color: theme.palette.secondary.main,
      data: [],
    };
    const totalSubscriptions = {
      id: "Subscriptions",
      color: theme.palette.secondary.main,
      data: [],
    };

    Object.values(subscriptionData).reduce(
      (acc, { month, count }) => {

        totalSubscriptions.data = [
          ...totalSubscriptions.data,
          { x: month, y: count },
        ];


        return { sales: count };
      },
      { sales: 0 }
    )
    Object.values(monthlyData).reduce(
      (acc, { month, count }) => {

        totalSalesLine.data = [
          ...totalSalesLine.data,
          { x: month, y: count },
        ];


        return { sales: count };
      },
      { sales: 0 }
    );

    return [[totalSalesLine], [totalSubscriptions]];
  }, [dashboardStats.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="MONTHLY SALES" subtitle="Chart of monthlysales" />
      <Box height="75vh">
        {dashboardStats.data ? (
          <ResponsiveLine
            data={totalSalesLine}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: theme.palette.secondary[200],
                  },
                },
                legend: {
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
                ticks: {
                  line: {
                    stroke: theme.palette.secondary[200],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
              },
              legends: {
                text: {
                  fill: theme.palette.secondary[200],
                },
              },
              tooltip: {
                container: {
                  color: theme.palette.primary.main,
                },
              },
            }}
        
            colors={{ datum: "color" }}
            margin={{ top: 50, right: 50, bottom: 70, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
              
            }}
            yFormat=" >-.2f"
            curve="catmullRom"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 90,
              legend: "Month",
              legendOffset: 60,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Total",
              legendOffset: -50,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                justify: false,
                translateX: 0,
                translateY: -50,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <>Loading...</>
        )}
      </Box>
    </Box>
  );
};

export default Monthly;