import { PricingThree } from "components/PricingThree";
import { useLocation, useOutletContext } from "react-router-dom";
import { useGetActiveSubscriptionQuery, useGetTiersQuery } from "state/api";
import { useNavigate } from "react-router-dom";
import { useTheme ,Fab} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

export default function Tiers(){
    const location = useLocation()
    const user = location.state.user
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const tierSubscription = useGetActiveSubscriptionQuery(user._id);
    const navigate = useNavigate();
    const { data } = useGetTiersQuery()
     
    return (
        <div className="flex justify-center items-start xxs:pt-12 md:pt-32 bg-bluenav">
        <div className='flex flex-col md:flex-row' style={{ alignSelf: "flex-start", marginLeft: "16px" }}>
          <Fab
            color="primary"
            aria-label="back"
            size="small"
            sx={{
              backgroundColor: isDarkMode ? theme.palette.primary.light : theme.palette.secondary.light,
            }}
            onClick={() => { navigate(-1); }} 
          >
            <ArrowBack />
          </Fab>
        </div>
        {data && tierSubscription.data && <PricingThree tier={tierSubscription.data ? tierSubscription.data.data : []} data={data} userData={user}/> }
      </div>
      
    )
}