
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { tokensDark } from 'theme';
import { Check, Clear, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useUpdateProfile } from "react-firebase-hooks/auth";
import { useUpdateAccountMutation, useUpdateAccountNameMutation, useUpdateTeamMemberMutation } from "state/api";
import { storage } from "firebaseConfig";

const Sidebar = ({editEmail, setEditEmail, editImage, setEditImage, editName, setEditName, userData}) => {
    
     const [profileImage, setProfileImage] = useState()
     const [updatePartner] = useUpdateAccountMutation()
     const [updateAccount] = useUpdateAccountNameMutation()
     const [updateTeamMember] = useUpdateTeamMemberMutation()
     const [profileName, setProfileName] = useState()
     const [profileEmail, setProfileEmail] = useState()
     const isMobileView = window.innerWidth <= 600; // Set the breakpoint for mobile view here (600px in this case)
    const partner = {
        name: !userData.team ? userData.userData[0].name : userData.team.name, 
        email: !userData.team  ? userData.userData[0].email : userData.team.email,
        referralCode: userData.userData[0].userReferralCode.code,
       
        mobile: !userData.team  ? userData.userData[0].mobile : userData.team.mobile,
        status: !userData.team ? "Active" : userData.team.status,
        role:!userData.team ? userData.userData[0].role : userData.team.role,
        photo: !userData.team ? userData.doctorData && userData.doctorData.photoUrl : userData.team.photo
    };
const theme = useTheme();
const isDarkMode = theme.palette.mode === 'dark';
const backgroundColor = isDarkMode ? '#3668a2' : '#917e4e';
const updateProfileImage = () => {
    if (profileImage) {
        const storageRef = ref(storage, `/Doctors/${partner.name}`)
        const uploadTask = uploadBytesResumable(storageRef, profileImage);
       
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    if(userData.team){
                        const team = { id:  userData.team._id,  photo: url }
                        updateTeamMember(team).unwrap().then((data) => {
                            alert("Profile Updated")
                            window.location.replace('/')
    
                        }).catch((err) => {
                            alert("Something went wrong while updating account!")
                        })
                    }else {
                        const partner = { id: !userData.team ? userData.doctorData._id : userData.team._id,  photoUrl: url }
                    updatePartner(partner).unwrap().then((data) => {
                        alert("Profile Updated")
                        window.location.replace('/')

                    }).catch((err) => {
                        alert("Something went wrong while updating account!")
                    })
                    }
                });
            }
        );
    } else {
        alert("Please add image!")
    }
}
const updateProfileName = () => {
    if (profileName) {
        if(userData.team){
            const team = { id: userData.team._id,  name: profileName }
        updateAccount({id: userData.team.account, name:profileName}).unwrap().then((data) => {
          updateTeamMember(team).then((p)=>{
            alert("Profile Updated")
            window.location.replace('/')
          }).catch((err) => {
            alert("Something went wrong while updating account!")
        })

        }).catch((err) => {
            alert("Something went wrong while updating account!")
        })
        } else {
            const partner = { id: userData.userData[0]._id,  name: profileName }
        updateAccount(partner).unwrap().then((data) => {
          updatePartner({id: userData.doctorData._id, name: profileName}).then((p)=>{
            alert("Profile Updated")
            window.location.replace('/')
          }).catch((err) => {
            alert("Something went wrong while updating account!")
        })

        }).catch((err) => {
            alert("Something went wrong while updating account!")
        })
        }
    } else {
        alert("Please enter name!")
    }
}
const updateEmail = () => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

    if (profileEmail && profileEmail.match(validRegex)) {
        if(userData.team){
            const team = { id: userData.team._id,  email: profileEmail }
        updateAccount({id: userData.team.account, email:profileEmail}).unwrap().then((data) => {
          updateTeamMember(team).then((p)=>{
            alert("Profile Updated")
            window.location.replace('/')
          }).catch((err) => {
            alert("Something went wrong while updating account!")
        })

        }).catch((err) => {
            alert("Something went wrong while updating account!")
        })
        } else {
            const partner = { id: userData.userData[0]._id,  email: profileEmail }
        updateAccount(partner).unwrap().then((data) => {
          updatePartner({id: userData.doctorData._id, email: profileEmail}).then((p)=>{
            alert("Profile Updated")
            window.location.replace('/')
          }).catch((err) => {
            alert("Something went wrong while updating account!")
        })

        }).catch((err) => {
            alert("Something went wrong while updating account!")
        })
        }
    } else {
        alert("Please enter valid email!")
    }
}

    return (
        <div className={`flex ${isMobileView ? "w-full flex-col" : "w-2/4"} flex-col py-8 px-6`} style={{backgroundColor: backgroundColor}}>
            <span className="text-xl font-bold">Your Details</span>
            <div className='mt-6 flex items-start'>
                <img className='w-80 h-80  rounded-md' src={partner.photo ? partner.photo : ''}></img>
                <IconButton onClick={()=>{setEditImage(!editImage)}}>{!editImage ? <Edit/> : <Clear/>}</IconButton>
                </div>
                {
                    editImage && <div className="flex rounded-md p-2 mb-2 justify-between">
                    <input  placeholder="Add Image" type="file" onChange={(event) => setProfileImage(event.target.files[0])}  accept=".jpg,.png"  className=" text-white outline-none"/>
                    <IconButton onClick={updateProfileImage}><Check/></IconButton>
                   </div>
                }
            <div className='block mt-4'>
                <h1 className="text-xl font-bold mb-4 items-center flex">{partner.name} <IconButton onClick={()=>{setEditName(!editName)}}>{!editName ? <Edit/> : <Clear/>}</IconButton> </h1>
               { editName && <div className="flex bg-blue-600 rounded-md p-2 mb-2 justify-between">
                <input onChange={(e)=>{setProfileName(e.target.value)}} placeholder="Enter Name" className="bg-blue-600 text-white outline-none"/>
                <IconButton onClick={updateProfileName}><Check/></IconButton>
               </div> }
                <h4 className="text-sm font-medium mb-4">{partner.mobile}</h4>
                <p className="mb-2 text-[18px] font-medium pt-6 opacity-50">Details</p>
                        <hr />

                        <div className="grid grid-cols-2 gap-4 center mt-4">
                        <h5 className="mb-2 text-md font-bold text-left">{partner.email}  <IconButton onClick={()=>{setEditEmail(!editEmail)}}>{!editEmail ? <Edit/> : <Clear/>}</IconButton> </h5>
                            <h5 className="mb-2 text-md text-right">Email</h5>
                            
                            <h5 className="mb-2 text-md font-bold text-left">{partner.status}</h5>
                            <h5 className="mb-2 text-md text-right">Status</h5>
                            <h5 className="mb-2 text-md font-bold text-left">{partner.referralCode}</h5>
                            <h5 className="mb-2 text-md text-right">Referral Code</h5>
                            <h5 className="mb-2 text-md font-bold text-left">{partner.role}</h5>
                            <h5 className="mb-2 text-md text-right">Role</h5>
                           
                        </div>
                        { editEmail && <div className="flex bg-blue-600 rounded-md p-2 mb-2 justify-between">
                <input onChange={(e)=>{setProfileEmail(e.target.value)}} placeholder="Enter Email" className="bg-blue-600 text-white outline-none"/>
                <IconButton onClick={updateEmail}><Check/></IconButton>
               </div> }
                        {/* <button onClick={() => { }} className="bg-blue-500 mt-4 w-full text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-300 ease-in-out">Edit Profile</button> */}

              
            </div>
             
          
        </div>
    );
};

// const MainContainer = () => {
//     return (
//         <div className="flex flex-col items-center py-8 px-4">

//             <div className="w-full h-64 bg-cover bg-center bg-[#21295c] rounded-md mb-4"></div>
//             <img
//                 className="w-32 h-32 rounded-full object-cover border-4 border-white"
//                 src="user-photo.jpg"
//                 alt="User"
//             />
//         </div>
//     );
// };


export default function MyAccount() {
    const [userData] = useOutletContext()
    const [editName, setEditName] = useState(false)
    const [editEmail, setEditEmail] = useState(false)
    const [editImage, setEditImage] = useState(false)
    return (
        <div className="flex w-full justify-center items-center">
            <div className="items-center justify-center flex w-full">
                {userData && <Sidebar editEmail={editEmail} setEditEmail={setEditEmail} editImage={editImage} setEditImage={setEditImage} editName={editName} setEditName={setEditName} userData={userData}/>}
            </div>
            {/* <div className="w-3/4">
                <MainContainer />
            </div> */}
        </div>
    )
}