import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
// import Test from "components/Test"
// import { Calculate } from '@mui/icons-material';
import {
  useGetAccountQuery,
  useGetTeamMemberByPhoneQuery,
  useGetUserQuery,
} from "state/api";
import { auth } from "firebaseConfig";

const Layout = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const userId = useSelector((state) => state.global.userId);
  const [user, setUser] = useState();
  const userName = localStorage.getItem("user_name");
  const [login, setLogin] = useState(false);
  const { data, error } = useGetUserQuery(user, { skip: !login });
  const [partnerData, setPartnerData] = useState();
  const [team, setTeam] = useState();
  const userData = useGetAccountQuery(
    auth.currentUser?.phoneNumber.replace("+", "%2b")
  );
  const [doctorData, setDoctorData] = useState();
  const teamMeberByPhone = useGetTeamMemberByPhoneQuery(
    auth.currentUser?.phoneNumber.replace("+", "%2b")
  );
  const [loading, setLoading] = useState(false);
  const router = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (userData.data) {
      if (
        userData.data[0].role === "Doctor" ||
        userData.data[0].role === "Physio"
      ) {
        setUser(userData.data[0]._id);
        localStorage.setItem("user", userData.data[0]._id);
        setPartnerData(userData.data);

        setLogin(true);
        if (data) {
          setDoctorData(doctorData);

          router(
            {
              pathname: location.pathname,
              search: location.search,
              hash: location.hash,
            },
            { state: location.state }
          );
        } else if (error) {
          router(`/profile?partner=${userName}`, {
            state: { account: userData.data[0] },
          });
        }
      } else if (
        (userData.data && userData.data[0].role === "Client") ||
        (userData.data && userData.data[0].role === "Team_Member")
      ) {
        if (teamMeberByPhone.data) {
          // console.log("partnerData", teamMeberByPhone.data)
          localStorage.setItem("user", teamMeberByPhone.data.partner._id);
          setPartnerData([teamMeberByPhone.data.partner]);
          setTeam(teamMeberByPhone.data);
          router(
            {
              pathname: location.pathname,
              search: location.search,
              hash: location.hash,
            },
            { state: location.state }
          );
        } else if (teamMeberByPhone.error) {
          alert("Permission Denied");
          auth
            .signOut()
            .then(() => {
              router("/");
            })
            .catch((e) => {
              alert(e.message);
            });
        }
      }
    }
  }, [data, error, userData]);

  return (
    <div className=''>
      {/* <Notification /> */}
      <div>
        {userData.data && (
          <Sidebar
            user={data || userData.data[0]}
            isNonMobile={isNonMobile}
            drawerWidth='250px'
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            team={team}
            userData={partnerData}
          />
        )}

        <div className='flex flex-col flex-grow'>
          {userData.data && (
            <Navbar
              user={data || userData.data[0]}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
              isNonMobile={isNonMobile}
              team={team}
            />
          )}
          <Box
            // sx={{justifyContent: "space-between", marginLeft: {sm:'40%', md: '30%', lg:'25%', xl:'15%', xxl: '13%' }}}
            sx={
              isSidebarOpen === true
                ? {
                    justifyContent: "center",
                    marginLeft: {
                      sm: "40%",
                      md: "30%",
                      lg: "20%",
                      xl: "18%",
                      xxl: "15%",
                    },
                    paddingX: { xs: "6px", md: "10px", lg: "0px" },
                  }
                : {
                    justifyContent: "center",
                    paddingX: { xs: "6px", md: "32px", lg: "0px" },
                    paddingY: { xs: "6px", md: "10px", lg: "16px" },
                  }
            }>
            {partnerData && (
              <Outlet
                context={[
                  { userData: partnerData, team: team, doctorData: data },
                ]}
              />
            )}
          </Box>
          {/* <Test /> */}
        </div>
      </div>
    </div>
  );
};

export default Layout;
