import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useOutletContext } from "react-router-dom";
import { useCreatePrescriptionMutation, useGetCustomersQuery } from "state/api";

const PrescriptionFormComponent = () => {
  const theme = useTheme();
  const [userData] = useOutletContext();
  const { data, isLoading } = useGetCustomersQuery(userData.userData[0].userReferralCode);
  const [userId, setUserId] = useState();
  const [createPrescription] = useCreatePrescriptionMutation();
  const [diagnosis, setDiagnosis] = useState([]);
  const [complain, setComplain] = useState([""]);
  const [observations, setObservation] = useState([""]);
  const [conclusion, setConclusion] = useState([" "]);

  const [labOrders, setLabOrders] = useState([{test:"", instructions: ""}]);
  const [comments, setComments] = useState([""])
  const [prescriptions, setPrescriptions] = useState([{drug: "", frequency: "", duration: "", instruction: ""}])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (diagnosis.length === 0 || complain.length === 0) {
      alert("Please enter all fields!");
    } else {
      const prescription = {
        partner: userData.userData[0]._id,
        patient: userId,
        diagnosis: diagnosis,
        complaint: complain,
        conclusion: conclusion,
        labOrders: labOrders,
        prescriptions: prescriptions,
        comments: comments,
        observation: observations
      };
      createPrescription(prescription).unwrap()
        .then(() => {
          alert("Prescription created successfully!");
          window.location.reload();
        })
        .catch(() => {
          alert("Something went wrong while creating prescription!");
        });
    }
  };

  useEffect(() => {
    if (data) {
      setUserId(data[0]._id)
    }
  }, [data]);
 
  return (
    <div
      style={{
        backgroundColor: theme.palette.mode === "dark" ? "#090e45" : "#8f8f85",
        minHeight: "100vh",
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "8px",
        borderRadius: "8px"
      }}
    >
      <h1
        style={{
          color: "white",
          fontSize: "32px",
          fontWeight: "bold",
          marginBottom: theme.spacing(6),
        }}
      >
        Prescription Form
      </h1>
      {isLoading === false ? (
        <form
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            color: "black",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginBottom: theme.spacing(4),
            }}
          >
             <div style={{ display: "flex", marginBottom: theme.spacing(4), alignItems: "center" }}>
              <label htmlFor="complaint" style={{ marginBottom: theme.spacing(1), color: "white" }}>
                Patient
              </label>

              <div style={{ flex: 1, marginLeft: theme.spacing(4) }}>
               <select onChange={(e)=>{setUserId(e.target.value)}} className="p-4 rounded-md">
                {
                  data.map((u, i)=>{
                    return <option key={i} value={u._id}>{u.name}</option>
                  })
                }
               </select>
              </div>
            </div>
            <h2 style={{ color: "white", marginBottom: "8px" }}>Clinical Notes</h2>
            <div style={{ display: "flex", marginBottom: theme.spacing(4) }} className="flex gap-4 flex-col md:flex-row">
              <label htmlFor="complaint" style={{ marginBottom: theme.spacing(1), color: "white" }}>
                Complaints
              </label>

              <div style={{ flex: 1 }} >
              {
                complain.map((c, i)=>{
                 return <div key={i} className="flex gap-2">
                  <textarea
                 
                  onChange={(e)=>{
                    let tempComplain = complain
                    tempComplain[i] = e.target.value
                    setComplain(tempComplain)
                  }}
                  aria-multiline={true}
                  type="text"
                  placeholder="Complain"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginBottom: theme.spacing(2),
                  }}
                />
                { i === complain.length-1 &&  <button
                 type="button"
                 onClick={()=>{
                  
                  setComplain((prevCom)=> prevCom.filter((v,index)=> index !== i))
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  -
                </button>}
                  </div>
                })
              }
                 
                <button
                 type="button"
                 onClick={()=>{
                  
                  setComplain([...complain, ""])
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "#59c229",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "36px",
                    height: "36px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  +
                </button>
              </div>
            </div>
            <hr style={{ borderTop: "1px solid #ccc", marginBottom: theme.spacing(4) }} />

            <div style={{ display: "flex", marginBottom: theme.spacing(4) }} className="flex gap-4 flex-col md:flex-row">
              <h2 style={{ color: "white" }}>Observations</h2>
              
              <div style={{ flex: 1 }}>
              {
                observations.map((c, i)=>{
                 return <div  key={i} className="flex gap-2">
                  <textarea
                 
                  onChange={(e)=>{
                    let tempObs = observations
                    tempObs[i] = e.target.value
                    setObservation(tempObs)
                  }}
                  aria-multiline={true}
                  type="text"
                  placeholder="Observation"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginBottom: theme.spacing(2),
                  }}
                />
                { i === observations.length-1 &&  <button
                 type="button"
                 onClick={()=>{
                  
                  setObservation((prevOb)=> prevOb.filter((v,index)=> index !== i))
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  -
                </button>}
                  </div>
                })
              }
                 
                <button
                 type="button"
                 onClick={()=>{
                  
                  setObservation([...observations, ""])
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "#59c229",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "36px",
                    height: "36px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  +
                </button>
              </div>
               
             
            </div>
            <hr style={{ borderTop: "1px solid #ccc", marginBottom: theme.spacing(4) }} />

            <div style={{ display: "flex", marginBottom: theme.spacing(4) }} className="flex gap-4 flex-col md:flex-row">
              <h2 style={{ color: "white" }}>Diagnosis</h2>
              <div style={{ flex: 1 }}>
                
              <div style={{ flex: 1 }}>
              {
                diagnosis.map((c, i)=>{
                 return <div  key={i} className="flex gap-2">
                  <textarea
                 
                  onChange={(e)=>{
                    let tempObs = diagnosis
                    tempObs[i] = e.target.value
                    setDiagnosis(tempObs)
                  }}
                  aria-multiline={true}
                  type="text"
                  placeholder="Diagnosis"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginBottom: theme.spacing(2),
                  }}
                />
                { i === diagnosis.length-1 &&  <button
                 type="button"
                 onClick={()=>{
                  
                  setDiagnosis((prevOb)=> prevOb.filter((v,index)=> index !== i))
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  -
                </button>}
                  </div>
                })
              }
                 
                <button
                 type="button"
                 onClick={()=>{
                  
                  setDiagnosis([...diagnosis, ""])
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "#59c229",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "36px",
                    height: "36px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  +
                </button>
              </div>
              </div>
            </div>
            <hr style={{ borderTop: "1px solid #ccc", marginBottom: theme.spacing(4) }} />

            <h2 style={{ color: "white" }}>Lab Orders</h2>
            <div style={{ display: "flex", marginBottom: "8px"}} className="gap-2">
              <div style={{ flex: 1 }}>
                <label htmlFor="test">Test</label>
                 
                {
                labOrders.map((c, i)=>{
                 return <div  key={i} className="flex gap-2">
                  <textarea
                 
                  onChange={(e)=>{
                    let tempObs = labOrders
                    tempObs[i].test = e.target.value
                    setLabOrders(tempObs)
                  }}
                  aria-multiline={true}
                  type="text"
                  placeholder="Test"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginBottom: theme.spacing(2),
                  }}
                />
              
                  </div>
                })
              }
                <button
                type="button"
                onClick={()=>{
                  setLabOrders([...labOrders, {test: "", instructions: ""}])
                }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "#59c229",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "36px",
                    height: "36px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  +
                </button>
         
              </div>
              <div style={{ flex: 1 }}>
                <label htmlFor="instruction">Instruction</label>
                {
                labOrders.map((c, i)=>{
                 return <div  key={i} className="flex gap-2">
                  <textarea
                 
                  onChange={(e)=>{
                    let tempObs = labOrders
                    tempObs[i].instructions = e.target.value
                    setLabOrders(tempObs)
                  }}
                  aria-multiline={true}
                  type="text"
                  placeholder="Instructions"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginBottom: theme.spacing(2),
                  }}
                />
              
                  </div>
                })
              }
                    <button
                 type="button"
                 onClick={()=>{
                  setLabOrders((prevPres)=> {
                     
                    const updatedItems = prevPres.slice();
                    
                    updatedItems.splice(prevPres.length-1, 1);
                    
                    return updatedItems;
                  })
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "36px",
                    height: "36px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  -
                </button>
              </div>
            </div>
            <hr style={{ borderTop: "1px solid #ccc", marginBottom: theme.spacing(4) }} />

            <h2 style={{ color: "white", marginBottom: "8px" }}>Prescriptions</h2>
            <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
             {
              prescriptions.map((p,i)=>{
                return  <div key={i} className="flex flex-col gap-2">
                  <div className="flex flex-col md:flex-row  gap-2">
                   <div className="flex-col gap-2">
              <div style={{ color: '#59c229', marginBottom: "8px"  }}>Drug</div>
                <input
                  type="text"
                  placeholder="Drug"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                  onChange={(e)=>{
                    let tempPres = prescriptions;
                    tempPres[i].drug = e.target.value
                    setPrescriptions(tempPres)
                  }}
                />
              </div>
              <div className="flex-col gap-2">
              <div style={{ color: '#59c229', marginBottom: "8px"  }}>Frequency</div>
                <input
                  type="text"
                  placeholder="Frequency"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                  onChange={(e)=>{
                    let tempPres = prescriptions;
                    tempPres[i].frequency = e.target.value
                    setPrescriptions(tempPres)
                  }}
                />
              </div>
              <div className="flex-col gap-2">
              <div style={{ color: '#59c229', marginBottom: "8px"  }}>Duration</div>
                <input
                  type="text"
                  placeholder="Duration"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                  onChange={(e)=>{
                    let tempPres = prescriptions;
                    tempPres[i].duration = e.target.value
                    setPrescriptions(tempPres)
                  }}
                />
              </div>
              <div className="flex-col gap-2">
              <div style={{ color: '#59c229', marginBottom: "8px" }}>Instructions</div>
                <input
                  type="text"
                  placeholder="Instructions"
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                  onChange={(e)=>{
                    let tempPres = prescriptions;
                    tempPres[i].instruction = e.target.value
                    setPrescriptions(tempPres)
                  }}
                />
              </div>
                 </div>
               <hr className="border md:border-0"/>
                </div>
              })
             }
            </div>
            
            </div>
            <div className="flex justify-between gap-2">
            <button
            type="button"
              onClick={()=>{
                setPrescriptions((prevPres)=> [...prevPres, {drug:"", frequency: "", duration: "", instructions: ""}])
              }}
              style={{
                marginLeft: theme.spacing(2),
                borderRadius: "50%",
                backgroundColor: "#59c229",
                color: "#fff",
                padding: "6px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "36px",
                height: "36px",
                border: "none",
                cursor: "pointer",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              +
            </button>
            <button
                 type="button"
                 onClick={()=>{
                  setPrescriptions((prevPres)=> {
                     
                    const updatedItems = prevPres.slice();
                    
                    updatedItems.splice(prevPres.length-1, 1);
                    
                    return updatedItems;
                  })
                 }}
                  style={{
                    marginLeft: theme.spacing(2),
                    borderRadius: "50%",
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "6px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "36px",
                    height: "36px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  -
             </button>
             </div>
            <hr style={{ borderTop: "1px solid #ccc", marginBottom: theme.spacing(4) }} />

            <h2 style={{ color: "white" }}>Comments</h2>
            <textarea
              type="text"
              placeholder="Enter comments"
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginBottom: theme.spacing(2),
              }}
              onChange={(e)=>{
                let tempCom = comments
                comments[0] = e.target.value
                setComments(tempCom)
              }}
            />
          </div>

          <button
            type="submit"
            style={{
              backgroundColor: "#59c229",
              color: "#fff",
              padding: "12px 24px",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "bold",
              transition: "background-color 0.2s ease",
            }}
          >
            Submit
          </button>
        </form>
      ) : (
        <div className="loader" />
      )}
    </div>
  );
};

export default PrescriptionFormComponent;
