import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { auth, storage } from "firebaseConfig";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateProfileMutation, useGetUserQuery } from "state/api";

const EstablishmentCategoryOptions = [
    "Multi-Speciality Hospital",
    "Single Speciality Hospital",
    "Clinic with OT",
    " OPD Clinic"
];

const Profile = () => {
    const query = useLocation().search;
    const name = new URLSearchParams(query).get("partner");
    const location = useLocation()
    const accountData = location.state.account;
    const [licenseDetails, setLicenseDetails] = useState();
    const [photo, setPhoto] = useState();
    const [experienceDetails, setExperienceDetails] = useState();
    const [degree, setDegree] = useState(accountData.role === "Doctor" ? "MBBS" : accountData.role === "Physio" ? "Bachelor of Physiotherapy (BPTh)" : "");
    const [specialization, setSpecialization] = useState(accountData.role === "Doctor" ? "Hand & Wrist" : accountData.role === "Physio" ? "Orthopedics" : "");
    const [establishmentCategory, setEstablishmentCategory] = useState();
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [createPartner, response] = useCreateProfileMutation();
    const user = localStorage.getItem("user")
    const { data } = useGetUserQuery(user);
    const degreesDoc = ["D. Ortho",
        "DNB",
       " Fellowship in Hand Surgery",
        "IOA Fellow",
       " ISCOS Spine Fellow (Australia/ Japan)",
        "MBBS",
        "MD",
        "MD Ortho",
        "MS",
       "MS (Ortho)",
        "PG Diploma",
        "PhD (Spine injury, Ortho)",
        "Spine Fellow (ISIC, New Delhi)",
        "WOC Spine Fellow (Mumbai)"]
        const specialisationDoc = [
            {
              "Specialization": "Hand & Wrist",
              "Specialization_Website": "Hand & Wrist",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Hand & Wrist"
            },
            {
              "Specialization": "Knee Replacement",
              "Specialization_Website": "Knee Replacement",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Knee Replacement"
            },
            {
              "Specialization": "Musculoskeletal",
              "Specialization_Website": "Musculoskeletal",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Musculoskeletal"
            },
            {
              "Specialization": "Trauma",
              "Specialization_Website": "Trauma",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Trauma"
            },
            {
              "Specialization": "Spine Surgery",
              "Specialization_Website": "Spine Surgery",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Spine Surgery"
            },
            {
              "Specialization": "Joint Replacement & Reconstruction Orthopedics",
              "Specialization_Website": "Joint Replacement & Reconstruction Orthopedics",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Joint Replacement & Reconstruction Orthopedics"
            },
            {
              "Specialization": "Neurology",
              "Specialization_Website": "Neurology",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Neurology"
            },
            {
              "Specialization": "Orthopedics",
              "Specialization_Website": "Orthopedics",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Orthopedics"
            },
            {
              "Specialization": "Arthritis",
              "Specialization_Website": "Arthritis",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Arthritis"
            },
            {
              "Specialization": "Hip Replacement",
              "Specialization_Website": "Hip Replacement",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Hip Replacement"
            },
            {
              "Specialization": "Sports Medicine",
              "Specialization_Website": "Sports Medicine",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Sports Medicine"
            },
            {
              "Specialization": "Arthroplasty",
              "Specialization_Website": "Arthroplasty",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Arthroplasty"
            },
            {
              "Specialization": "Orthopedic Sports Medicine",
              "Specialization_Website": "Orthopedic Sports Medicine",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Orthopedic Sports Medicine"
            },
            {
              "Specialization": "General Surgery",
              "Specialization_Website": "General Surgery",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "General Surgery"
            },
            {
              "Specialization": "Orthopaedic",
              "Specialization_Website": "Orthopedics",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Joint Replacement"
            },
            {
              "Specialization": "Joint Replacement",
              "Specialization_Website": "Joint Replacement",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Orthopedic Hip Surgery"
            },
            {
              "Specialization": "Orthopedic Hip Surgery",
              "Specialization_Website": "Orthopedic Hip Surgery",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Neurosurgery"
            },
            {
              "Specialization": "Neurosurgery",
              "Specialization_Website": "Neurosurgery",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Adult Reconstructive Orthopedic Surgery"
            },
            {
              "Specialization": "Adult Reconstructive Orthopedic Surgery",
              "Specialization_Website": "Adult Reconstructive Orthopedic Surgery",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": "Pain Management & Rehabilitation (PM&R)"
            },
            {
              "Specialization": "Pain Management & Rehabilitation (PM&R)",
              "Specialization_Website": "Pain Management & Rehabilitation (PM&R)",
              "FIELD3": "",
              "FIELD4": "",
              "Specialization_Website_Unique": ""
            }
           ]

    const degreePhysio = [
       " Bachelor of Physiotherapy (BPTh)",
"Bachelor of Occupational Therapy",
"DPT",
"MBA (HR)",
"MCMT",
"Masters of Physiotherapy (MPTh)",
"MPTh (Musculoskeletal)",
"MPTh (Ortho)",
"Master in Structural Integration",
"Phd in Physiotherapy",
"IASTM"
    ]
    const specialisationPhysio =  [
        {
          "Specialization": "Orthopedics",
          "Specialization_Website": "Orthopedics",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": "Orthopedics"
        },
        {
          "Specialization": "Pediatrics",
          "Specialization_Website": "Pediatrics",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": "Pediatrics"
        },
        {
          "Specialization": "Orthopaedics",
          "Specialization_Website": "Orthopedics",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": "Neurology"
        },
        {
          "Specialization": "Neurology",
          "Specialization_Website": "Neurology",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": "Sports Medicine"
        },
        {
          "Specialization": "Sports",
          "Specialization_Website": "Sports Medicine",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": "Women's Health"
        },
        {
          "Specialization": "Women's Health",
          "Specialization_Website": "Women's Health",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": "Clinical ElectroPhysiology"
        },
        {
          "Specialization": "Clinical electrophysiology",
          "Specialization_Website": "Clinical ElectroPhysiology",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": "Geriatrics"
        },
        {
          "Specialization": "Geriatrics",
          "Specialization_Website": "Geriatrics",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": "Gynaecology"
        },
        {
          "Specialization": "Gynaecology",
          "Specialization_Website": "Gynaecology",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": "Cardiology"
        },
        {
          "Specialization": " Pediatrics",
          "Specialization_Website": "Pediatrics",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": "Cardiology & Pulmonary"
        },
        {
          "Specialization": " Neurology",
          "Specialization_Website": "Neurology",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": "Pulmonology"
        },
        {
          "Specialization": " Geriatrics",
          "Specialization_Website": "Geriatrics",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": "Oncology"
        },
        {
          "Specialization": " Clinical electrophysiology",
          "Specialization_Website": "Clinical ElectroPhysiology",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": ""
        },
        {
          "Specialization": " Women's Health",
          "Specialization_Website": "Women's Health",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": ""
        },
        {
          "Specialization": " Sports",
          "Specialization_Website": "Sports Medicine",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": ""
        },
        {
          "Specialization": "Sports Medicine",
          "Specialization_Website": "Sports Medicine",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": ""
        },
        {
          "Specialization": "Cardiology",
          "Specialization_Website": "Cardiology",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": ""
        },
        {
          "Specialization": " Cardiovascular & Pulmonary",
          "Specialization_Website": "Cardiology & Pulmonary",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": ""
        },
        {
          "Specialization": "Pulmonology",
          "Specialization_Website": "Pulmonology",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": ""
        },
        {
          "Specialization": " Oncology",
          "Specialization_Website": "Oncology",
          "FIELD3": "",
          "FIELD4": "",
          "Specialization_Website_Unique": ""
        }
       ]
    const navigate = useNavigate()
    
    // useEffect(()=>{
    //     if (data) {
            
    //         setLoading(false)
    //     }
    // },[data])
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!licenseDetails || !photo || !experienceDetails || !degree || !specialization || !establishmentCategory) {
            setError(true)
            setMessage('Please enter all the fields!')
        } else {
            setError(false)
            setLoading(true)
            setMessage()
            const storageRef = ref(storage, `/Doctors/${name}`)
            const uploadTask = uploadBytesResumable(storageRef, photo);
           
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        const partner = { name: name, mobile: auth.currentUser?.phoneNumber,  photoUrl: url, licenseNumber:licenseDetails, yearsOfExperience: experienceDetails, specialization: [specialization], degree:[degree], description:establishmentCategory, status:"inactive", account: user  }
                        createPartner(partner).unwrap().then((data) => {
                            setLoading(false)
                            
                            window.location.replace('/')
    
                        }).catch((err) => {
                            console.log("err", err)
                            setError(true)
                            setLoading(false)
                            setMessage(err.message)
                        })
                    });
                }
            );

        }

    };

    return (
       
        !data &&  <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            flexDirection: 'column',


        }}>
            <h4 className="text-2xl text-white mb-5">Create Profile</h4>
            <form onSubmit={handleSubmit} className="mb-4 w-2/4 mx-auto text-black bg-white rounded-md p-5">
                <div className="mb-4 ">
                    <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-input w-full border-gray-700 border rounded-md shadow-sm p-3"
                        disabled
                        value={name}
                    />
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="licenseDetails"
                        className="block text-gray-700 font-bold mb-2"
                    >
                        License Details
                    </label>
                    <textarea
                        id="licenseDetails"
                        name="licenseDetails"
                        rows="1"
                        className="form-input w-full border-gray-700 border p-2 rounded-md shadow-sm"

                        onChange={(e) => setLicenseDetails(e.target.value)}
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="photo" className="block text-gray-700 font-bold mb-2">
                        Photo
                    </label>
                    <input
                        type="file"
                        id="photo"
                        name="photo"
                        className="form-input w-full rounded-md shadow-sm"
                        accept="image/*"
                        onChange={(e) => setPhoto(e.target.files[0])}
                    />
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="experienceDetails"
                        className="block text-gray-700 font-bold mb-2"
                    >
                        Experience Details
                    </label>
                    <input
                        id="experienceDetails"
                        name="experienceDetails"
                        placeholder="Enter experience in years"
                        min={0}
                        className="form-input w-full border-gray-700 border p-2  rounded-md shadow-sm"
                        type="number"
                        onChange={(e) => setExperienceDetails(e.target.value)}
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="degree" className="block text-gray-700 font-bold mb-2">
                        Degree/Qualifications
                    </label>
                    
                    { accountData.role === "Physio" &&  <select className="w-full" onChange={(e)=>{setDegree(e.target.value)}} defaultValue={degree}>
                    {
                        degreePhysio.map((s,i)=>{
                            return <option key={i} value={s}>{s}</option>
                        })
                    }
                    </select>
                    }
                    { accountData.role === "Doctor" &&  <select className="w-full" onChange={(e)=>{setDegree(e.target.value)}} defaultValue={degree}>
                    {
                        degreesDoc.map((s,i)=>{
                            return <option key={i} value={s}>{s}</option>
                        })
                    }
                    </select>
                    }
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="specialization"
                        className="block text-gray-700 font-bold mb-2"
                    >
                        Specialization
                    </label>
                    { accountData.role === "Physio" &&  <select className="w-full" onChange={(e)=>{setSpecialization(e.target.value)}} defaultValue={specialization}>
                        {
                            specialisationPhysio.map((s,i)=>{
                                return <option key={i} value={s.Specialization}>{s.Specialization}</option>
                            })
                        }
                        </select>
                        }
                         { accountData.role === "Doctor" &&  <select className="w-full" onChange={(e)=>{setSpecialization(e.target.value)}} defaultValue={specialization}>
                        {
                            specialisationDoc.map((s,i)=>{
                                return <option key={i} value={s.Specialization}>{s.Specialization}</option>
                            })
                        }
                        </select>
                        }
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="establishmentCategory"
                        className="block text-gray-700 font-bold mb-2"
                    >
                        Establishment Category
                    </label>
                    <select
                        id="establishmentCategory"
                        name="establishmentCategory"
                        className="form-select w-full border-gray-700 border  rounded-md shadow-sm"

                        onChange={(e) => setEstablishmentCategory(e.target.value)}
                    >
                        <option value="">-- Select an option --</option>
                        {EstablishmentCategoryOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
                {
                    error && <div className="mb-4 bg-red-200 p-2 text-center rounded-md mt-4">
                        <label
                            htmlFor="specialization"
                            className="block text-red-700 font-bold "
                        >
                            {message}
                        </label>

                    </div>
                }
                <div className="mt-6 w-full align-middle center text-center flex justify-center">
                    {!loading && <button
                        type="submit"
                        className="w-2/4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Submit
                    </button>}
                    {
                        loading && <CircularProgress />
                    }
                </div>
            </form>
        </Box>
       
    );
};

export default Profile