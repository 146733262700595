import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: "https://casadoc.in/api/" }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Products",
    "Customers",
    "Transactions",
    "Prescription",
    "UserCasesUpdate",
    "UserCases",
    "TierVerification",
    "TierSubscription",
    "Appointment",
    "TeamMember",
    "Accounts",
    "Partners",
    "Subscription",
    "CreatePlan",
    "CreateSms",
    "AccountsUpdate",
    "AccountsUpdateName",
    "TeamMemberUpdateName",
    "AddPhysioSession",
    "PlanUpdate",
    "UserProfileUpdate",
    "PhysioSessionUpdate",
    "UpdateTierSubscription",
    "Trimester",
    "ShareLink",
    "PhysioSession",
    "TeamMembers",
    "TeamMemberByPhone",
    "Tiers",
    "UserCases",
    "UserCasesRetained",
    "GetPartners",
    "Prescriptions",
  ],
  endpoints: (build) => ({
    createPrescription: build.mutation({
      query: (prescription) => ({
        url: "client/services/prescriptions",
        method: "POST",
        body: prescription,
      }),
      invalidatesTags: ["Prescription"],
    }),
    updatePrescription: build.mutation({
      query: (prescription) => ({
        url: `client/services/prescriptions/${prescription._id}`,
        method: "PUT",
        body: prescription,
      }),
      invalidatesTags: ["Prescription"],
    }),
    createUserCase: build.mutation({
      query: (userCase) => ({
        url: "client/services/user-cases",
        method: "POST",
        body: userCase,
      }),
      invalidatesTags: ["UserCases"],
    }),
    updateUserCase: build.mutation({
      query: (userCase) => ({
        url: `client/services/user-cases/${userCase._id}`,
        method: "PUT",
        body: userCase,
      }),
      invalidatesTags: ["UserCases"],
    }),
    createUserCaseInvite: build.mutation({
      query: ({ account, userCase, name, link }) => ({
        url: "client/services/user-cases/invite",
        method: "POST",
        body: { account, userCase, name, link },
      }),
      invalidatesTags: ["UserCases"],
    }),
    verifyTierTransaction: build.mutation({
      query: (tier) => ({
        url: "client/services/tier-transactions/verify",
        method: "POST",
        body: tier,
      }),
      invalidatesTags: ["TierVerification"],
    }),
    createTierSubscription: build.mutation({
      query: (tier) => ({
        url: "client/services/tier-subscriptions",
        method: "POST",
        body: tier,
      }),
      invalidatesTags: ["TierSubscription"],
    }),
    createAppointment: build.mutation({
      query: (appointment) => ({
        url: "client/services/book-appointment",
        method: "POST",
        body: appointment,
      }),
      invalidatesTags: ["Appointment"],
    }),
    createTeamMember: build.mutation({
      query: (teamMember) => ({
        url: "management/team-members",
        method: "POST",
        body: teamMember,
      }),
      invalidatesTags: ["TeamMember"],
    }),
    createAccount: build.mutation({
      query: (accountData) => ({
        url: "client/services/accounts",
        method: "POST",
        body: accountData,
      }),
      invalidatesTags: ["Accounts"],
    }),
    createProfile: build.mutation({
      query: (partnerData) => ({
        url: "client/services/partners",
        method: "POST",
        body: partnerData,
      }),
      invalidatesTags: ["Partners"],
    }),
    createSubscription: build.mutation({
      query: (subscription) => ({
        url: "client/services/subscriptions",
        method: "POST",
        body: subscription,
      }),
      invalidatesTags: ["Subscription"],
    }),
    createPlan: build.mutation({
      query: (planData) => ({
        url: "client/services/plans",
        method: "POST",
        body: planData,
      }),
      invalidatesTags: ["CreatePlan"],
    }),
    createSendLink: build.mutation({
      query: (body) => ({
        url: "client/services/send-sms",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["CreateSms"],
    }),
    updateAccount: build.mutation({
      query: (accountData) => ({
        url: `client/services/partners/${accountData.id}`,
        method: "PUT",
        body: accountData,
      }),
      invalidatesTags: ["Accounts"],
    }),
    updateAccountName: build.mutation({
      query: (accountData) => ({
        url: `client/services/accounts/${accountData.id}`,
        method: "PUT",
        body: accountData,
      }),
      invalidatesTags: ["Accounts"],
    }),
    updateTeamMember: build.mutation({
      query: (accountData) => ({
        url: `management/team-members/${accountData.id}`,
        method: "PUT",
        body: accountData,
      }),
      invalidatesTags: ["TeamMember"],
    }),
    updateFeedback: build.mutation({
      query: (feedbackData) => ({
        url: `client/services/physio-session/${feedbackData.id}`,
        method: "PUT",
        body: feedbackData,
      }),
      invalidatesTags: ["Partners"],
    }),
    addPhyioSessionRecord: build.mutation({
      query: (feedbackData) => ({
        url: `client/services/physio-session`,
        method: "POST",
        body: feedbackData,
      }),
      invalidatesTags: ["AddPhysioSession"],
    }),
    updateCustomPlan: build.mutation({
      query: (planData) => ({
        url: `client/services/plans/${planData.id}`,
        method: "PUT",
        body: planData,
      }),
      invalidatesTags: ["PlanUpdate"],
    }),
    updateUserProfile: build.mutation({
      query: (userData) => ({
        url: `client/services/users/${userData.id}`,
        method: "PUT",
        body: userData,
      }),
      invalidatesTags: ["UserProfile"],
    }),
    updatePhysioSession: build.mutation({
      query: (physioSession) => ({
        url: `client/services/physio-session/${physioSession.id}`,
        method: "PUT",
        body: physioSession,
      }),
      invalidatesTags: ["PhysioSession"],
    }),
    updateTierSubscription: build.mutation({
      query: (subscription) => ({
        url: `client/services/tier-subscriptions/${subscription.id}`,
        method: "PUT",
        body: subscription,
      }),
      invalidatesTags: ["TierSubscription"],
    }),
    updateAppointment: build.mutation({
      query: (appointment) => ({
        url: `client/services/appointments/${appointment.id}`,
        method: "PUT",
        body: appointment,
      }),
      invalidatesTags: ["Appointment"],
    }),
    createUserProfile: build.mutation({
      query: (userData) => ({
        url: `client/services/users`,
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["UserProfile"],
    }),
    createTrimester: build.mutation({
      query: (trimesterData) => ({
        url: `management/trimester`,
        method: "POST",
        body: trimesterData,
      }),
      invalidatesTags: ["Trimester"],
    }),
    updateTrimester: build.mutation({
      query: (trimesterData) => ({
        url: `management/trimester/${trimesterData._id}`,
        method: "PUT",
        body: trimesterData,
      }),
      invalidatesTags: ["Trimester"],
    }),
    sendJoinRequest: build.mutation({
      query: (message) => ({
        url: `client/services/request-patient-join`,
        method: "POST",
        body: message,
      }),
      invalidatesTags: ["UserCases"],
    }),
    addMedicalRecordMutation: build.mutation({
      query: (record) => ({
        url: `client/services/medical-records`,
        method: "POST",
        body: record,
      }),
      invalidatesTags: ["MedicalRecord"],
    }),
    getUser: build.query({
      query: (id) => `client/services/partners?account=${id}`,
      providesTags: ["User"],
    }),
    getProducts: build.query({
      query: (days) => `client/services/products?days=${days}`,
      providesTags: ["Products"],
    }),
    getCustomers: build.query({
      query: (referralCode) =>
        `client/services/accounts?referralCode=${referralCode._id}&role=Client`,
      providesTags: ["Customers"],
    }),
    getTransactions: build.query({
      query: ({ page, pageSize, sort, search, referralCode }) => ({
        url: `client/services/orders`,
        method: "GET",
        params: {
          page,
          pageSize,
          sort,
          search,
          referralCode: referralCode._id,
        },
      }),
      providesTags: ["Transactions"],
    }),
    getAppointments: build.query({
      query: (referralCode) =>
        `client/services/appointments?referralCode=${referralCode._id}`,
      providesTags: ["Appointments"],
    }),
    getVideoCallToken: build.query({
      query: ({ appointmentId, isPublisher }) => ({
        url: "client/services/appointments-generate-token",
        method: "POST",
        body: { appointmentId, isPublisher },
      }),
      providesTags: ["VideoCallToken"],
    }),
    getUserFeedbacks: build.query({
      query: (user) => `client/services/feedbacks?user=${user}`,
      providesTags: ["Feedbacks"],
    }),
    getAccount: build.query({
      query: (user) => `client/services/accounts?mobile=${user}`,
      providesTags: ["Accounts"],
    }),
    getUserProfile: build.query({
      query: (accountId) => `client/services/users?accountId=${accountId}`,
      providesTags: ["UserProfile"],
    }),
    getUserSubscriptions: build.query({
      query: (query) => `client/services/subscriptions?${query}`,
      providesTags: ["Subscription"],
    }),
    getPlans: build.query({
      query: (custom) => `client/services/plans?custom=${custom}`,
      providesTags: ["Plans"],
    }),
    getExercises: build.query({
      query: () => `client/services/exercises`,
      providesTags: ["Exercises"],
    }),
    getPlanDetails: build.query({
      query: (id) => `client/services/plans/${id}`,
      providesTags: ["PlanDetails"],
    }),
    getDashboardStatus: build.query({
      query: (referralCode) =>
        `client/services/dashboard-stats?referralCode=${referralCode._id}`,
      providesTags: ["DashboardStats"],
    }),
    getPartnerUserSubscriptions: build.query({
      query: (referralCode) =>
        `client/services/referral-user-subscriptions?referralCode=${referralCode._id}&role=Client`,
      providesTags: ["PartnerUserSubscriptions"],
    }),
    getMedicalRecords: build.query({
      query: (user) => `client/services/medical-records?user=${user}`,
      providesTags: ["MedicalRecords"],
    }),
    getMedicalRecord: build.query({
      query: (id) => `client/services/medical-records/${id}`,
      providesTags: ["MedicalRecord"],
    }),
    getAppShareLink: build.query({
      query: () => `client/services/share-app-link`,
      providesTags: ["ShareLink"],
    }),
    getUserPhysioSessions: build.query({
      query: (user) => `client/services/physio-session?user=${user}`,
      providesTags: ["PhysioSession"],
    }),
    getTeamMembers: build.query({
      query: (partner) => `management/team-members?partner=${partner}`,
      providesTags: ["TeamMembers"],
    }),
    getTeamMemberByPhone: build.query({
      query: (mobile) => `management/find-by-account?mobile=${mobile}`,
      providesTags: ["TeamMemberByPhone"],
    }),
    getTiers: build.query({
      query: () => `client/services/tiers`,
      providesTags: ["Tiers"],
    }),
    getActiveSubscription: build.query({
      query: (user) =>
        `client/services/tier-subscriptions?user=${user}&status=active`,
      providesTags: ["Tiers"],
    }),
    getActiveSubscriptionInvoice: build.query({
      query: (id) => `client/services/tier-subscriptions-invoice/${id}`,
      providesTags: ["TierInvoice"],
    }),
    getUserTransferedCases: build.query({
      query: (id) => `client/services/user-cases?primaryPartner=${id}`,
      providesTags: ["UserCases"],
    }),
    getUserRetainedCases: build.query({
      query: (id) => `client/services/user-cases?currentPartner=${id}`,
      providesTags: ["UserCasesRetained"],
    }),
    getPartners: build.query({
      query: () => `/general/partners`,
      providesTags: ["GetPartners"],
    }),
    getPrescriptions: build.query({
      query: (partner) => `client/services/prescriptions?partner=${partner}`,
      providesTags: ["Prescriptions"],
    }),
    getTrimesters: build.query({
      query: ({ date, user }) =>
        `management/trimesters?user=${user}${
          date !== undefined ? `&createdAt=${date}` : ""
        }`,
      providesTags: ["Trimester"],
    }),
  }),
});

export const {
  useUpdateTrimesterMutation,
  useCreateTrimesterMutation,
  useGetTrimestersQuery,
  useCreatePrescriptionMutation,
  useUpdatePrescriptionMutation,
  useGetPrescriptionsQuery,
  useUpdateUserCaseMutation,
  useCreateUserCaseInviteMutation,
  useCreateUserCaseMutation,
  useGetPartnersQuery,
  useGetUserRetainedCasesQuery,
  useGetUserTransferedCasesQuery,
  useUpdateTeamMemberMutation,
  useUpdateAccountNameMutation,
  useUpdateAccountMutation,
  useGetUserQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetAppointmentsQuery,
  useGetVideoCallTokenQuery,
  useGetUserFeedbacksQuery,
  useCreateAccountMutation,
  useGetAccountQuery,
  useGetUserProfileQuery,
  useCreateProfileMutation,
  useUpdateFeedbackMutation,
  useCreateSubscriptionMutation,
  useGetUserSubscriptionsQuery,
  useGetPlansQuery,
  useGetExercisesQuery,
  useCreatePlanMutation,
  useGetPlanDetailsQuery,
  useUpdateCustomPlanMutation,
  useGetDashboardStatusQuery,
  useGetPartnerUserSubscriptionsQuery,
  useCreateUserProfileMutation,
  useUpdateUserProfileMutation,
  useGetMedicalRecordsQuery,
  useGetMedicalRecordQuery,
  useAddMedicalRecordMutationMutation,
  useSendJoinRequestMutation,
  useGetAppShareLinkQuery,
  useCreateSendLinkMutation,
  useGetUserPhysioSessionsQuery,
  useAddPhyioSessionRecordMutation,
  useUpdatePhysioSessionMutation,
  useUpdateAppointmentMutation,
  useCreateTeamMemberMutation,
  useGetTeamMembersQuery,
  useGetTeamMemberByPhoneQuery,
  useCreateAppointmentMutation,
  useGetTiersQuery,
  useCreateTierSubscriptionMutation,
  useVerifyTierTransactionMutation,
  useGetActiveSubscriptionQuery,
  useGetActiveSubscriptionInvoiceQuery,
  useUpdateTierSubscriptionMutation,
} = api;
