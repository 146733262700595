import { Box, useTheme } from "@mui/material";
import Header from "components/Header";
import SubscriptionTable from "components/SubscriptionTable";
import { useOutletContext } from "react-router-dom";
import { useGetPartnerUserSubscriptionsQuery } from "state/api";

export default function Subscriptions(){
    const theme = useTheme()
    const [userData] = useOutletContext()
    const {data , isLoading}  = useGetPartnerUserSubscriptionsQuery(userData.userData[0].userReferralCode);
   
    return (
        <Box m="1.5rem 2.5rem">
        <Header title="Subscriptions" subtitle="Find all your patients subscriptions here!" />
        <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        {
          data && <SubscriptionTable appointments={data}/>
        }
      </Box>


    </Box>
    )
}