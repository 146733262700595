import React, { useEffect, useMemo, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  Group,
  CalendarMonth,
  Subscriptions,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Tabs,
  Tab,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import BreakdownChart from "components/BreakdownChart";
import OverviewChart from "components/OverviewChart";
import { ResponsiveLine } from "@nivo/line";
// import { useGetDashboardQuery } from "state/api";
import StatBox from "components/StatBox";
import { dataOverallStat } from "utils/data";

import { useGetActiveSubscriptionQuery, useGetCustomersQuery, useGetDashboardStatusQuery, useGetPartnerUserSubscriptionsQuery, useGetTransactionsQuery } from "state/api";
import moment from "moment";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import PatientTable from "components/PatientTable";

const Dashboard = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const dataX = dataOverallStat[0];
  const dataM = dataX.monthlyData[dataX.monthlyData.length - 1].totalSales


  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [userData] = useOutletContext()
  const [shouldSkip, setShouldSkip] = useState(false)
  const navigate = useNavigate()

  const isDarkMode = theme.palette.mode === 'dark';
  const dashboardStats = useGetDashboardStatusQuery(userData.userData[0].userReferralCode, {skip: !shouldSkip});
 
    const patients = useGetCustomersQuery(userData.userData[0].userReferralCode, {skip: !shouldSkip});
    const {data , isLoading}  = useGetPartnerUserSubscriptionsQuery(userData.userData[0].userReferralCode);
    const tierSubscription = useGetActiveSubscriptionQuery(userData.userData[0]._id, {skip: !shouldSkip});
     
    useEffect(()=>{
      if(userData.userData){
        setShouldSkip(true)
      }
    },[userData.userData])

    useEffect(()=>{
      if (tierSubscription.data && tierSubscription.data.data.length === 0) {
        // console.log("account-details",userData.userData[0])
          navigate(`/tier-purchase`, {state: {user: userData.userData[0]}})
      } 
    },[tierSubscription.data])
    
  // const { data, isLoading } = useGetTransactionsQuery({
  //   page,
  //   pageSize,
  //   sort: JSON.stringify(sort),
  //   search,
  // });
  const [totalSalesLine, totalSubscriptions] = useMemo(() => {
    if (!dashboardStats.data) return [];

    const monthlyData = dashboardStats.data.monthCustomers;
    const subscriptionData = dashboardStats.data.monthSubscriptions

    const totalSalesLine = {
      id: "Total Patients",
      color: theme.palette.secondary.main,
      data: [],
    };
    const totalSubscriptions = {
      id: "Subscriptions",
      color: theme.palette.secondary.main,
      data: [],
    };

    Object.values(subscriptionData).reduce(
      (acc, { month, count }) => {

        totalSubscriptions.data = [
          ...totalSubscriptions.data,
          { x: month, y: count },
        ];


        return { sales: count };
      },
      { sales: 0 }
    )
    Object.values(monthlyData).reduce(
      (acc, { month, count }) => {

        totalSalesLine.data = [
          ...totalSalesLine.data,
          { x: month, y: count },
        ];


        return { sales: count };
      },
      { sales: 0 }
    );

    return [[totalSalesLine], [totalSubscriptions]];
  }, [dashboardStats.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      field: "systemsub_id",
      headerName: "Index",
      flex: 0.5,
    },
    {
      field: "user",
      headerName: "User Name",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "CreatedAt",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Subscription Status",
      flex: 1,
    },
    {
      field: "razorpayOrderObject.amount",
      headerName: "Amount",
      flex: 1,
    },
    {
      field: "plan_id",
      headerName: "Plan Name",
      flex: 1,
    },

  ];
  const rows = [];
  // if (data) {
  //   data.map((d, index) => {
  //     const row = {
  //       "id": index + 1,
  //       "systemsub_id": index + 1,
  //       "createdAt": moment(d.createdAt).format('DD/MM/YYYY HH:mm'),
  //       "endsAt": null,
  //       "planMonths": 0,
  //       "razorpayOrderObject.amount": d.paymentId.amount / 100 === NaN ? 0 : d.paymentId.amount / 100,
  //       "plan_id": d.products[0].name,
  //       "startsAt": null,
  //       "status": d.status,
  //       "updatedAt": moment(d.updatedAt).format('DD/MM/YYYY HH:mm'),
  //       "user": d.user.name
  //     }
  //     rows.push(row)
  //   })
  // }

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

       
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        {
          dashboardStats.data && <>
            <StatBox
              title="Total Patients"
              value={dashboardStats.data.totalUsers}
              increase={dashboardStats.data.usersGrow > 0 ? "+" + dashboardStats.data.usersGrow + "%" : dashboardStats.data.usersGrow + "%"}
              description="Since last month"
              icon={
                <Group
                  sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                />
              }
            />
            <StatBox
              title="Active Patients"
              value={dashboardStats.data.totalActiveUsers}
              increase={dashboardStats.data.activeUsersGrow > 0 ? "+" + dashboardStats.data.activeUsersGrow + "%" :  dashboardStats.data.activeUsersGrow + "%"}

              description="Since last month"
              icon={
                <PersonAdd
                  sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                />
              }
            />
          </>
        }
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          {dashboardStats.data &&
            <ResponsiveLine
              data={totalSalesLine}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: theme.palette.secondary[200],
                    },
                  },
                  legend: {
                    text: {
                      fill: theme.palette.secondary[200],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: theme.palette.secondary[200],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: theme.palette.secondary[200],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
                tooltip: {
                  container: {
                    color: theme.palette.primary.main,
                  },
                },
              }}
              margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              yFormat=" >-.2f"
              curve="catmullRom"
              enableArea={true}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                format: (v) => {
                  return v.slice(0, 3);

                },
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Month",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickValues: 5,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: `Total No. of Patients`,
                legendOffset: -60,
                legendPosition: "middle",
              }}
              enableGridX={false}
              enableGridY={false}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={

                [
                  {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 30,
                    translateY: -40,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]

              }
            />}
        </Box>
        {
          dashboardStats.data && <>
            <StatBox
              title="Subscriptions"
              value={dashboardStats.data.totalSubs}
              increase={dashboardStats.data.subsGrow > 0 ? "+" + dashboardStats.data.subsGrow + "%" : dashboardStats.data.subsGrow + "%"}

              description="Since last month"
              icon={
                <Subscriptions
                  sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                />
              }
            />
            <StatBox
              title="Appointments"
              value={dashboardStats.data.totalAppointments}
              increase={dashboardStats.data.appointmentGrow > 0 ? "+" + dashboardStats.data.appointmentGrow + "%" : dashboardStats.data.appointmentGrow + "%"}

              description="Since last month"
              icon={
                <CalendarMonth
                  sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                />
              }
            />
          </>
        }
      {
        dashboardStats.data &&   <Box
        gridColumn="span 12"
        gridRow="span 3"
        backgroundColor={theme.palette.background.alt}
        p="1rem"
        borderRadius="0.55rem"
      >
        {dashboardStats.data &&
          <ResponsiveLine
            data={totalSubscriptions}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: theme.palette.secondary[200],
                  },
                },
                legend: {
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
                ticks: {
                  line: {
                    stroke: theme.palette.secondary[200],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
              },
              legends: {
                text: {
                  fill: theme.palette.secondary[200],
                },
              },
              tooltip: {
                container: {
                  color: theme.palette.primary.main,
                },
              },
            }}
            margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            yFormat=" >-.2f"
            curve="catmullRom"
            enableArea={true}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: (v) => {
                return v.slice(0, 3);

              },
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Month",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickValues: 5,
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: `Total No. of Subscriptions`,
              legendOffset: -60,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={

              [
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 30,
                  translateY: -40,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]

            }
          />}
      </Box>
      }
        {/* ROW 2 */}
        {/* <Box
          gridColumn="span 8"
          gridRow="span 3"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          {
          patients.data && <PatientTable patients={patients.data}/>
        }
        </Box> */}
        <Box
      gridColumn="span 4"
      gridRow="span 3"
      mt="1rem"
      backgroundColor={theme.palette.background.alt}
      p="1.5rem"
      borderRadius="0.55rem"
      display="flex"
      flexDirection="column"
      alignItems="center" // Center content horizontally
    >
      <Typography variant="h6" style={{marginTop:'25px'}} sx={{ color: theme.palette.secondary[100] }}>
        Cases By Pain Points
      </Typography>
      {data && <BreakdownChart data={data} isDashboard={true} />}
      <Typography
        p="0 0.6rem"
        fontSize="0.8rem"
        sx={{ color: theme.palette.secondary[200] }}
      >
        Breakdown of total patient cases based on pain point.
      </Typography>
      {patients.data && <Outlet context={[{ patientsTotal: patients.data }]} />}
    </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;