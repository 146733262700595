import React, { useEffect, useRef } from 'react';
import * as posenet from '@tensorflow-models/posenet';

const BodyVisualisation = () => {
  const canvasRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const runPoseDetection = async () => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Load the PoseNet model
      const net = await posenet.load();

      // Get the video dimensions
      const { videoWidth, videoHeight } = video;

      // Set the canvas size to match the video dimensions
      canvas.width = videoWidth;
      canvas.height = videoHeight;

      // Run pose detection on each video frame
      const detectPose = async () => {
        // Capture the current video frame
        context.drawImage(video, 0, 0, videoWidth, videoHeight);

        // Run pose detection on the captured frame
        const poses = await net.estimatePoses(video, {
          decodingMethod:"single-person"
        });
        console.log(poses)
        // Draw the pose keypoints on the canvas
        poses.forEach(({ keypoints }) => {
          keypoints.forEach(({ position }) => {
            const { x, y } = position;

            // Draw a circle at each keypoint
            context.beginPath();
            context.arc(x, y, 5, 0, 2 * Math.PI);
            context.fillStyle = 'red';
            context.fill();
          });
        });

        // Request the next frame
        requestAnimationFrame(detectPose);
      };

      // Start pose detection
      detectPose();
    };

    // Call the pose detection function
    runPoseDetection();
  }, []);

  return (
    <div>
      <canvas ref={canvasRef} height="100" width="200"/>
      <video ref={videoRef}  height="100" width="200" src="/exercise_vid.mp4" controls />
    </div>
  );
};

export default BodyVisualisation;
