import React from 'react';
import './MedicalPage.css'; // Import the CSS file for styling
import { useGetMedicalRecordsQuery } from 'state/api';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useTheme, Fab } from '@mui/material';

export const MedicalPage = () => {
  // Dummy data for medical records
  const location = useLocation()
  const params = new URLSearchParams(location.search);
   const [userData] = useOutletContext()
    const patient = params.get("patient")
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

  const {data, loading} = useGetMedicalRecordsQuery(patient)
  // const medicalRecords = [
  //   {
  //     id: 1,
  //     photoUrl: 'https://dummyimage.com/200x200',
  //     patientName: 'John Doe',
  //     details: 'Lorem ipsum dolor sit amet',
  //   },
  //   {
  //     id: 2,
  //     photoUrl: 'https://dummyimage.com/200x200',
  //     patientName: 'Jane Smith',
  //     details: 'Consectetur adipiscing elit',
  //   },
  //   {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },
  //     {
  //       id: 1,
  //       photoUrl: 'https://dummyimage.com/200x200',
  //       patientName: 'John Doe',
  //       details: 'Lorem ipsum dolor sit amet',
  //     },

  //   // Add more medical records here...
  // ];
 const navigate = useNavigate()
  return (
    <div className="medical-page">
    <div className='flex flex-col md:flex-row'>
                    <Fab
                        color="primary"
                        aria-label="back"
                        size="small"
                        sx={{
                            backgroundColor: isDarkMode ? theme.palette.primary.light : theme.palette.secondary.light,
                            marginLeft: "8px"
                        }}
                        onClick={() => { navigate(-1); }} // Navigate back to the previous screen
                    >
                        <ArrowBack />
                    </Fab>
                </div>
      <h1>Medical Records</h1>
      <div className="record-grid">
        {data && data.map((record) => (
          <div className="record" key={record._id} onClick={()=>{navigate(`/patient/medical-record?record=${record._id}`)}}>
            <img className="record-photo" src={record.document} alt="Medical Record" />
            <div className="record-details">
              <p className="record-patient-name">{record.name}</p>
              {/* <p className="record-details-text">{record.name}</p> */}
            </div>
          </div>
        ))}
      </div>
      {
              !userData.team && userData.userData[0].role === "Doctor" &&    <button onClick={() => { navigate(`/patient/add-record?patient=${patient}`) }} className="bg-blue-500 text-white py-2 px-1 mt-5  rounded-lg hover:bg-blue-600 transition-colors duration-300 ease-in-out">Add Medical Record</button>
            }
            {
              userData.team && userData.team.role !== "viewer" &&    <button onClick={() => { navigate(`/patient/add-record?patient=${patient}`) }} className="bg-blue-500 text-white py-2 px-1 mt-5  rounded-lg hover:bg-blue-600 transition-colors duration-300 ease-in-out">Add Medical Record</button>
            }
   
    </div>
  );
};