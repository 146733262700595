import React from "react";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar, Button } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useGetPrescriptionsQuery } from "state/api";
import { tokensDark, tokensLight } from "theme"; // Import your theme tokens
import { ArrowForward } from "@mui/icons-material"; // Import the ArrowForward icon

const Prescription = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [userData] = useOutletContext();
  const { data, error } = useGetPrescriptionsQuery(userData.userData[0]._id);

  const isDarkMode = theme.palette.mode === "dark";
  return (
    <div
      className={`bg-${
        theme.palette.mode === "dark" ? "blueGray" : "yellowOchre"
      } min-h-screen p-${theme.spacing(2)}`}
    >
      <Typography
        variant="h1"
        className={`text-${
          theme.palette.primary.main
        } font-bold text-3xl mb-${theme.spacing(6)} mt-${theme.spacing(
          8
        )} text-center`}
      >
        Prescriptions
      </Typography>
      <div
        className={`mt-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-${theme.spacing(
          6
        )} w-full sm:px-${theme.spacing(4)} md:px-${theme.spacing(
          6
        )} lg:px-${theme.spacing(8)}`}
      >
        {data && data.map((prescription, index) => (
          <Card
            key={index}
            className={`flex flex-col ml-10 rounded-lg shadow-md bg-${
              theme.palette.mode === "dark" ? "blueGray" : "white"
            } transition duration-300 transform hover:-translate-y-2 hover:shadow-xl hover:scale-105`}
            sx={{
              padding: theme.spacing(3), // Add padding to the card
            }}
          >
            <div
              className="flex justify-center p-4"
              style={{
                backgroundColor: theme.palette.primary.light,
              }}
            >
              <Avatar
                alt={prescription.partnerName}
                src={prescription.partnerLogoUrl}
              />
            </div>
            <CardContent
              className="flex flex-col flex-grow"
              style={{
                backgroundColor: isDarkMode
                  ? tokensDark.primary[300]
                  : tokensDark.grey[200],
              }}
              sx={{
                padding: theme.spacing(2), // Add padding to the card content
              }}
            >
              <Typography
                variant="h5"
                className="mb-2 text-xl font-bold text-center"
              >
                {prescription.patient.name}
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: "6px" }}
                className=" text-center"
              >
                {prescription.patient.mobile}
              </Typography>
              <Typography variant="body1" className="mb-2 mt-4">
                <strong>Complaints:</strong> {prescription.complaint.join(", ")}
              </Typography>
              <Typography variant="body1" className="mb-2">
                <strong>Diagnosis:</strong> {prescription.diagnosis.join(", ")}
              </Typography>
              <Typography variant="body1" className="mb-4">
                <strong>Observations:</strong>{" "}
                {prescription.observation.join(", ")}
              </Typography>
              <div className="flex-grow" />
              <div className="flex justify-center">
                <Button
                  className={` text-white py-2 px-4 rounded-lg hover:bg-${
                    theme.palette.primary.dark
                  } mx-auto mt-${theme.spacing(4)}`}
                  sx={{
                    backgroundColor: isDarkMode
                      ? tokensDark.primary[700]
                      : "#735502",
                    color: isDarkMode ? "white" : "black",
                    marginTop: "8px",
                  }}
                  onClick={() => {
                    navigate("/prescription/detail", {
                      state: { prescription: prescription },
                    });
                  }}
                >
                  View Details
                  <ArrowForward
                    sx={{
                      marginLeft: theme.spacing(1), // Add margin to the arrow icon
                    }}
                  />
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
      {/* Replace the existing IconButton */}
      {error && (
        <p
          style={{
            color: "white",
            marginLeft: "40px",
          }}
        >
          No Prescriptions
        </p>
      )}
      <Button
        style={{ marginTop: "10px", marginLeft: "40px" }}
        sx={{
          backgroundColor: isDarkMode ? tokensDark.primary[300] : "#a6976d",
        }}
        className={`fixed bottom-${theme.spacing(6)} right-${theme.spacing(
          6
        )} bg-${theme.palette.primary.main} text-white py-2 px-4 rounded-lg 
        }`}
        onClick={() => {
          navigate(`/prescription/form`);
        }}
      >
        <p
          style={{
            color: isDarkMode ? tokensDark.primary[900] : tokensDark.grey[700],
          }}
        >
          ADD PRESCRIPTION
        </p>
      </Button>
    </div>
  );
};

export default Prescription;
