import { ArrowBack } from "@mui/icons-material";
import { CircularProgress, IconButton, MenuItem, Select, Tab, Tabs, Typography, useTheme } from "@mui/material";

import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateTrimesterMutation } from "state/api";

export default function AddTrimester() {
    const theme = useTheme()
    const [bloodPressure, setBloodPressure] = useState('');
    const [pulseRate, setPulseRate] = useState('');
    const [temperature, setTemperature] = useState('');
    const [spo2, setSpo2] = useState('');
    const [weight, setWeight] = useState('');
    const [bloodSugar, setBloodSugar] = useState('');
    const [haemoglobin, setHaemoglobin] = useState('');
    const [foetalHeartRate, setFoetalHeartRate] = useState('');
    const [urineProteinAlbumin, setUrineProteinAlbumin] = useState('');
    const [bloodSugarUrine, setBloodSugarUrine] = useState('');
    const [t3, setT3] = useState('');
    const [t4, setT4] = useState('');
    const [tsh, setTsh] = useState('');
    const [torchPanelIgm, setTorchPanelIgm] = useState('');
    const [torchPanelIgg, setTorchPanelIgg] = useState('');
    const [cholesterol, setCholesterol] = useState('');
    const [triglycerides, setTriglycerides] = useState('');
    const [hdlCholesterol, setHdlCholesterol] = useState('');
    const [ldlCholesterol, setLdlCholesterol] = useState('');
    const [hbsAg, setHbsAg] = useState('');
    const [ogtt, setOgtt] = useState('');
    const [prolactinTest, setProlactinTest] = useState('');
    const [estrogenLevelTest, setEstrogenLevelTest] = useState('');
    const [antiMullerianHormoneTest, setAntiMullerianHormoneTest] = useState('');
    const [selectedTab, setSelectedTab] = useState(0)
    const [trimester1, setTrimester1] = useState([])
    const [trimester2, setTrimester2] = useState([])
    const [trimester3, setTrimester3] = useState([])
    const [loading, setLoading] = useState(false)
    const [date, setDate] = useState()
    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    const patient = params.get("patient")
    const mobile = params.get("mobile")
    const [createTrimester, response] = useCreateTrimesterMutation() 
    const handleChange = (setter) => (e) => {
        setter(e.target.value);
    };

    // Submit form handler
    const handleSubmit = (e) => {
        e.preventDefault();
        // Process form data...
       if (!date) {
        alert("Please enter the date")
       } else {
        setLoading(true)
        const trimesterData = {  
            user: patient,  
           
            trimesters: [
                {
                  date: date,
                  trimester: selectedTrimester,
                  bloodPressure: bloodPressure,
                  pulseRate: pulseRate,
                  temperature: temperature,
                  spo2: spo2,
                  weight: weight,
                  bloodSugar: bloodSugar,
                  haemoglobin: haemoglobin,
                  foetalHeartRate: foetalHeartRate,
                  urineProteinAlbumin: urineProteinAlbumin,
                  bloodSugarUrine: bloodSugarUrine,
                  t3: t3,
                  t4: t4,
                  tsh: tsh,
                  torchPanelIgm: [torchPanelIgm],
                  torchPanelIgg: [torchPanelIgg],
                  lipidProfile: {
                    cholesterol: cholesterol,
                    triglycerides: triglycerides,
                    hdlCholesterol: hdlCholesterol,
                    ldlCholesterol: ldlCholesterol
                  },
                  hbsAg: hbsAg,
                  ogtt: ogtt,
                  prolactinTest: prolactinTest,
                  estrogenLevelTest: estrogenLevelTest,
                  antiMullerianHormoneTest: antiMullerianHormoneTest
                }
              ]
            }
            createTrimester(trimesterData).unwrap().then((d)=>{
                if (d) {
                    setLoading(false)
                    alert("Data added successfully")
                    navigate(`/patient?patient=${patient}&mobile=${mobile}`, {replace: true})
                    window.location.reload()
                } else {
                    setLoading(false)
                    alert("Something went wrong")
                }
            }).catch((e)=>{
                setLoading(false)
                alert("Something went wrong")
            })
       }
    };
    const isDarkMode = theme.palette.mode === 'dark';
   const [selectedTrimester, setSelectedTrimester] = useState("Trimester 1")
    return (
        <div className="p-4">
             <div className="flex gap-2 items-center">
              <IconButton onClick={()=>{navigate(-1)}}>
                    <ArrowBack/>
                </IconButton>
              <Typography variant="h3" fontWeight="700">Create New Trimester</Typography>
              </div>
            <div className="w-full mb-8 mt-6 flex items-center justify-between">
              
            <Select className=""
                        value={selectedTrimester}
                        label=""
                        variant="standard"
                        onChange={(e) => setSelectedTrimester(e.target.value)}
                    >
                        <MenuItem value={"Trimester 1"}>Trimester 1</MenuItem>
                        {/* <MenuItem value={"Trimester 2"}>Trimester 2</MenuItem>
                        <MenuItem value={"Trimester 3"}>Trimester 3</MenuItem> */}
            </Select>
            
            <input onChange={(e)=>{ setDate(e.target.value) }}  className=" focus:outline-none p-2 rounded-md text-black" type="date"/>
            {/* <DatePicker label="Basic date picker" /> */}
            </div>
            <form onSubmit={handleSubmit} className=" grid md:grid-cols-2 grid-cols-1 gap-4">
                {/* Blood Pressure */}

                <div>
                    <label htmlFor="bloodPressure" className="block mb-1">Blood Pressure (mmHg)</label>
                    <input
                        type="text"
                        id="bloodPressure"
                        value={bloodPressure}
                        onChange={handleChange(setBloodPressure)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="120/80"
                    />
                </div>

                {/* Pulse Rate */}
                <div>
                    <label htmlFor="pulseRate" className="block mb-1">Pulse Rate (BPM)</label>
                    <input
                        type="text"
                        id="pulseRate"
                        value={pulseRate}
                        onChange={handleChange(setPulseRate)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter pulse rate"
                    />
                </div>

                {/* Temperature */}
                <div>
                    <label htmlFor="temperature" className="block mb-1">Temperature (°C)</label>
                    <input
                        type="text"
                        id="temperature"
                        value={temperature}
                        onChange={handleChange(setTemperature)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter temperature"
                    />
                </div>

                {/* SpO2 */}
                <div>
                    <label htmlFor="spo2" className="block mb-1">SpO2</label>
                    <input
                        type="text"
                        id="spo2"
                        value={spo2}
                        onChange={handleChange(setSpo2)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter SpO2"
                    />
                </div>

                {/* Weight */}
                <div>
                    <label htmlFor="weight" className="block mb-1">Weight (kg)</label>
                    <input
                        type="text"
                        id="weight"
                        value={weight}
                        onChange={handleChange(setWeight)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter weight"
                    />
                </div>

                {/* Blood Sugar */}
                <div>
                    <label htmlFor="bloodSugar" className="block mb-1">Blood Sugar (mg/dL)</label>
                    <input
                        type="text"
                        id="bloodSugar"
                        value={bloodSugar}
                        onChange={handleChange(setBloodSugar)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter blood sugar"
                    />
                </div>

                {/* Haemoglobin */}
                <div>
                    <label htmlFor="haemoglobin" className="block mb-1">Haemoglobin (g/dL)</label>
                    <input
                        type="text"
                        id="haemoglobin"
                        value={haemoglobin}
                        onChange={handleChange(setHaemoglobin)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter haemoglobin"
                    />
                </div>

                {/* Foetal Heart Rate */}
                <div>
                    <label htmlFor="foetalHeartRate" className="block mb-1">Foetal Heart Rate (BPM)</label>
                    <input
                        type="text"
                        id="foetalHeartRate"
                        value={foetalHeartRate}
                        onChange={handleChange(setFoetalHeartRate)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter foetal heart rate"
                    />
                </div>

                {/* Urine Protein Albumin */}
                <div>
                    <label htmlFor="urineProteinAlbumin" className="block mb-1">Urine Protein Albumin (mg/dL)</label>
                    <input
                        type="text"
                        id="urineProteinAlbumin"
                        value={urineProteinAlbumin}
                        onChange={handleChange(setUrineProteinAlbumin)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter urine protein albumin"
                    />
                </div>

                {/* Blood Sugar Urine */}
                <div>
                    <label htmlFor="bloodSugarUrine" className="block mb-1">Blood Sugar Urine (mg/dL)</label>
                    <input
                        type="text"
                        id="bloodSugarUrine"
                        value={bloodSugarUrine}
                        onChange={handleChange(setBloodSugarUrine)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter blood sugar urine"
                    />
                </div>

                {/* T3 */}
                <div>
                    <label htmlFor="t3" className="block mb-1">T3 (ng/dL)</label>
                    <input
                        type="text"
                        id="t3"
                        value={t3}
                        onChange={handleChange(setT3)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter T3"
                    />
                </div>

                {/* T4 */}
                <div>
                    <label htmlFor="t4" className="block mb-1">T4 (µg/dL)</label>
                    <input
                        type="text"
                        id="t4"
                        value={t4}
                        onChange={handleChange(setT4)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter T4"
                    />
                </div>

                {/* TSH */}
                <div>
                    <label htmlFor="tsh" className="block mb-1">TSH (µIU/mL)</label>
                    <input
                        type="text"
                        id="tsh"
                        value={tsh}
                        onChange={handleChange(setTsh)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter TSH"
                    />
                </div>

                {/* TORCH Panel IgM */}
                <div>
                    <label htmlFor="torchPanelIgm" className="block mb-1">TORCH Panel IgM (IU/mL)</label>
                    <input
                        type="text"
                        id="torchPanelIgm"
                        value={torchPanelIgm}
                        onChange={handleChange(setTorchPanelIgm)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter TORCH Panel IgM"
                    />
                </div>

                {/* TORCH Panel IgG */}
                <div>
                    <label htmlFor="torchPanelIgg" className="block mb-1">TORCH Panel IgG (IU/mL)</label>
                    <input
                        type="text"
                        id="torchPanelIgg"
                        value={torchPanelIgg}
                        onChange={handleChange(setTorchPanelIgg)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter TORCH Panel IgG"
                    />
                </div>

                {/* Cholesterol (Lipid Profile) */}
                <div>
                    <label htmlFor="cholesterol" className="block mb-1">Cholesterol (mg/dL)</label>
                    <input
                        type="text"
                        id="cholesterol"
                        value={cholesterol}
                        onChange={handleChange(setCholesterol)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter cholesterol"
                    />
                </div>

                {/* Triglycerides (Lipid Profile) */}
                <div>
                    <label htmlFor="triglycerides" className="block mb-1">Triglycerides (mg/dL)</label>
                    <input
                        type="text"
                        id="triglycerides"
                        value={triglycerides}
                        onChange={handleChange(setTriglycerides)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter triglycerides"
                    />
                </div>

                {/* HDL Cholesterol (Lipid Profile) */}
                <div>
                    <label htmlFor="hdlCholesterol" className="block mb-1">HDL Cholesterol (mg/dL)</label>
                    <input
                        type="text"
                        id="hdlCholesterol"
                        value={hdlCholesterol}
                        onChange={handleChange(setHdlCholesterol)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter HDL cholesterol"
                    />
                </div>

                {/* LDL Cholesterol (Lipid Profile) */}
                <div>
                    <label htmlFor="ldlCholesterol" className="block mb-1">LDL Cholesterol (mg/dL)</label>
                    <input
                        type="text"
                        id="ldlCholesterol"
                        value={ldlCholesterol}
                        onChange={handleChange(setLdlCholesterol)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter LDL cholesterol"
                    />
                </div>

                {/* HbsAg */}
                <div>
                    <label htmlFor="hbsAg" className="block mb-1">HbsAg (IU/mL)</label>
                    <input
                        type="text"
                        id="hbsAg"
                        value={hbsAg}
                        onChange={handleChange(setHbsAg)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter HbsAg"
                    />
                </div>

                {/* OGTT */}
                <div>
                    <label htmlFor="ogtt" className="block mb-1">OGTT (mg/dL)</label>
                    <input
                        type="text"
                        id="ogtt"
                        value={ogtt}
                        onChange={handleChange(setOgtt)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter OGTT"
                    />
                </div>

                {/* Prolactin Test */}
                <div>
                    <label htmlFor="prolactinTest" className="block mb-1">Prolactin Test (ng/mL)</label>
                    <input
                        type="text"
                        id="prolactinTest"
                        value={prolactinTest}
                        onChange={handleChange(setProlactinTest)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter prolactin test"
                    />
                </div>

                {/* Estrogen Level Test */}
                <div>
                    <label htmlFor="estrogenLevelTest" className="block mb-1">Estrogen Level Test (pg/mL)</label>
                    <input
                        type="text"
                        id="estrogenLevelTest"
                        value={estrogenLevelTest}
                        onChange={handleChange(setEstrogenLevelTest)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter estrogen level test"
                    />
                </div>

                {/* Anti-Mullerian Hormone Test */}
                <div>
                    <label htmlFor="antiMullerianHormoneTest" className="block mb-1">Anti-Mullerian Hormone Test (ng/mL)</label>
                    <input
                        type="text"
                        id="antiMullerianHormoneTest"
                        value={antiMullerianHormoneTest}
                        onChange={handleChange(setAntiMullerianHormoneTest)}
                        className="border rounded px-3 py-2 w-full focus:outline-none text-black"
                        placeholder="Enter anti-Mullerian hormone test"
                    />
                </div>

                {/* Submit Button */}
               { !loading &&  <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Create</button>}
               { loading  && <CircularProgress/> }
            </form>
        </div>
    )
}