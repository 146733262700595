import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { Box, Typography, useTheme } from "@mui/material";
import { dataOverallStat } from "utils/data";
import { isMobile } from "scenes/patient/utilities";

const BreakdownChart = ({ data, isDashboard = false }) => {
  const theme = useTheme();

  if (!data) return "Loading...";

  const colors = [
    theme.palette.secondary[500],
    theme.palette.secondary[300],
    theme.palette.secondary[300],
    theme.palette.secondary[500],
    theme.palette.secondary[500],
    theme.palette.secondary[300],
    theme.palette.secondary[300],
    theme.palette.secondary[500],
  ];

  const painPointCounts = {};

  data.forEach((item) => {
    item.plan.painPoint.forEach((painPoint) => {
      if (painPointCounts[painPoint]) {
        painPointCounts[painPoint]++;
      } else {
        painPointCounts[painPoint] = 1;
      }
    });
  });

  const formattedData = Object.keys(painPointCounts).map((painPoint, i) => ({
    id: painPoint.toUpperCase(),
    label: painPoint.toUpperCase(),
    value: painPointCounts[painPoint],
    color: theme.palette.secondary[(i + 1) * 100],
  }));
  // const formattedData = Object.entries(data).map(
  //   (s, i) => ({
  //     id: s.plan.name,
  //     label: s.plan.painPoint,
  //     value: s.plan.painPoint,
  //     color: colors[i],
  //   })
  // );

  const isMobileView = window.innerWidth <= 600; // Set the breakpoint for mobile view here (600px in this case)

  return (
    <Box
      height={isDashboard ? "400px" : "100%"}
      width={undefined}
      minHeight={isDashboard ? "325px" : undefined}
      minWidth={isDashboard ? "325px" : undefined}
      position="relative"
    >
      <ResponsivePie
        data={formattedData}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: theme.palette.secondary[200],
              },
            },
            legend: {
              text: {
                fill: theme.palette.secondary[200],
              },
            },
            ticks: {
              line: {
                stroke: theme.palette.secondary[200],
                strokeWidth: 1,
              },
              text: {
                fill: theme.palette.secondary[200],
              },
            },
          },
          legends: {
            text: {
              fill: theme.palette.secondary[200],
            },
          },
          tooltip: {
            container: {
              color: theme.palette.primary.main,
            },
          },
        }}
        colors={{ datum: "data.color" }}
        margin={
          isMobileView
            ? { top: 40, right: 40, bottom: 80, left: 40 } // Adjust margins for mobile view
            : isDashboard
            ? { top: 40, right: 80, bottom: 100, left: 50 }
            : { top: 40, right: 80, bottom: 80, left: 80 }
        }
        sortByValue={true}
        innerRadius={0.45}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        enableArcLinkLabels={!isDashboard}
        arcLinkLabelsTextColor={theme.palette.secondary[200]}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: isDashboard ? 50 : 56,
            itemsSpacing: 5,
            itemWidth: 75,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "top-to-bottom",
            itemOpacity: 1,
            symbolSize: 15,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: theme.palette.primary[500],
                },
              },
            ],
          },
        ]}
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        textAlign="center"
        pointerEvents="none"
        sx={{
          transform: "translate(-50%, -50%)", // Center horizontally and vertically
          color: theme.palette.secondary[400],
        }}
      >
        <Typography
          variant="h6"
          sx={{
            whiteSpace: "nowrap",
            transform: "translate(0%,-50%)",
            fontSize: isMobileView ? "10px" : "15px",
          }}
        >
          {!isDashboard && "Total Cases:"}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            whiteSpace: "nowrap",
            fontSize: isMobileView ? "10px" : "15px",
            transform: isDashboard ?( isMobileView? "translate(-0%, -100%)": "translate(-90%, -110%)") : "translate(0%, -50%)", // Use different transform values based on condition
          }}
        >
          {data.length}
        </Typography>
      </Box>
    </Box>
  );
};

export default BreakdownChart;
