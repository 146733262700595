import React,{ useEffect, useState } from 'react'
import { 
    Box, 
    Card, 
    CardActions, 
    CardContent, 
    Collapse, 
    Button, 
    Typography, 
    Rating, 
    useTheme, 
    useMediaQuery,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    useThemeProps
} from "@mui/material"
import { useGetProductsQuery } from 'state/api'
import Header from "components/Header"
import FlexBetween from 'components/FlexBetween'


const Feedback = ({
    _id,
    name,
    productName,
    painPoint,
    time,
    rating,
    painPerception,
    difficulty,
    rom,
    thumbnail

}) =>{
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false)
    const romArray = rom ? rom.split('-') : [0,0]
    return(
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem"
            }}
        >
            <CardContent>
                <div className='flex justify-center items-center'>
                <img 
                    alt="profile"
                    src={thumbnail}
                    className="object-cover rounded-lg h-[250px] mb-2"
                />
                </div>
                <Typography 
                    sx={{fontSize: 14}}
                    color={theme.palette.secondary[700]}
                    gutterBottom
                    style={{textTransform: 'capitalize'}}
                >
                    Pain Point : <strong>{painPoint.toUpperCase()}</strong>
                </Typography>
                <Typography
                   
                    variant='h5'
                    component='div'
                >
                    {name}
                </Typography>
                <Typography
                   sx={{mt: '0.5rem'}}
                    variant='h6'
                    component='div'
                >
                    {productName}
                </Typography>
                <Typography
                   sx={{ mt: "0.5rem"}}
                    color={theme.palette.secondary[400]}
                >
                    Total time taken: {time} min
                </Typography>
                <Typography
                    sx={{ mt: "0.5rem"}}
                    color={theme.palette.secondary[400]}
                >
                    Pain Perception : { painPerception > 70 ? 'High' : painPerception < 70 && painPerception > 30 ? 'Moderate' : 'Low'  }
                </Typography>
                <Typography
                    sx={{ mt: "0.5rem"}}
                    color={theme.palette.secondary[400]}
                >
                    Difficulty : { difficulty > 70 ? 'High' : difficulty < 70 && difficulty > 30 ? 'Moderate' : 'Low'  }
                </Typography>
                <Rating sx={{ mt: "0.5rem"}} value={rating} readOnly precision={0.5} />
                <Typography
                 sx={{ mb: "1.5rem"}}
                 color={theme.palette.secondary[400]}>
                    Range of movement :<br/>Lower {romArray[0]}<br/>Upper {romArray[1]}<br/>
                 </Typography>
               
            </CardContent>
        </Card>
    )
}

export default Feedback;