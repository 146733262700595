import * as React from "react";
import { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import logofulldark from "assets/logofull.svg";
import "./login.css";
import { color, width } from "@mui/system";
import { auth } from "firebaseConfig";
import { useGetAccountQuery } from "state/api";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

export default function LoginPage() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(0);
  const [otpResult, setOtpResult] = useState();
  const [otp, setOtp] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [progress, setProgress] = useState();
  const [login, setLogin] = useState(false);
  const phoneNumberEncoded = phoneNumber ? phoneNumber.replace("+", "%2b") : "";
  const { data, loading } = useGetAccountQuery(
    phoneNumberEncoded,
    { skip: !login }
  );
  const [message, setMessage] = useState("");
  const [user, setUser] = useState();
  React.useEffect(() => {
    if (window) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "normal",
        },
        auth
      );
    }
  }, []);

  React.useEffect(() => {
    if (data) {
      setUser(data[0]);
    }
  }, [data]);

  const handleSendOtp = () => {
    setProgress(true);

    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((result) => {
        setOtpResult(result);
        setOtpSent(true);
        setResendCountdown(60);
        setProgress(false);
        setLogin(true);
        const intervalId = setInterval(() => {
          setResendCountdown((prevCount) => prevCount - 1);
        }, 1000);
        setTimeout(() => {
          clearInterval(intervalId);
          setResendCountdown(0);
        }, 60000);
      })
      .catch((error) => {
        setError(true);
        setProgress(false);
        setMessage(error.message);
      });
  };
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  const handleVerifyOtp = () => {
    setProgress(true);
    if (otp) {
      otpResult
        .confirm(otp)
        .then((result) => {
          if (user) {
            setProgress(false);
            setOtpVerified(true);
            setSuccess(true);
            localStorage.setItem("user", data[0]._id);
            localStorage.setItem("user_name", data[0].name);
            window.location.replace("/");
          } else {
            setError(true);
            alert("Access Denied!");
            setProgress(false);
            setOtpVerified(false);

            auth
              .signOut()
              .then(() => {
                window.location.replace("/");
              })
              .catch((error) => {
                console.log("error 1", error);
                setError(true);
                setMessage(error.message);
                setProgress(false);
              });
          }
        })
        .catch((error) => {
          console.log("error 2", error);
          setError(true);
          setMessage(error.message);
          setProgress(false);
        });
    } else {
      setError(true);
      setMessage("Something went wrong");
      setProgress(false);
    }
  };

  const handleResendOtp = () => {
    setOtpSent(false);
    setOtpVerified(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Container
        sx={{
          color: "black",
          boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          borderRadius: "16px",
          padding: "32px",
          bgcolor: "white",
          width: "500px",
          margin: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <img src={logofulldark} alt="App Logo" style={{ height: '64px' }} /> */}
          <Typography
            align="center"
            fontWeight={700}
            variant="h1"
            sx={{ mt: 4 }}
          >
            <span className=" text-gray-600">Casa</span>
            <span className=" text-[#02B9ED]">Doc</span>
          </Typography>
        </Box>

        <Typography align="center" fontWeight={700} variant="h5" sx={{ mt: 4 }}>
          Login to your account
        </Typography>

        {!otpSent && (
          <Box display="flex" flexDirection="column" sx={{ mt: 6 }}>
            <Typography variant="h6" fontWeight={600}>
              Phone Number
            </Typography>

            <PhoneInput
              style={{ padding: "18px", border: "solid", marginTop: "8px" }}
              className={"input-phone-number"}
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={setPhoneNumber}
              defaultCountry="IN"
              international
              countryCallingCodeEditable={false}
              inputProps={{
                required: true,
                autoFocus: true,
              }}
            />
          </Box>
        )}

        {otpSent && !otpVerified && (
          <Box display="flex" flexDirection="column" sx={{ mt: 3 }}>
            <TextField
              label="Enter OTP"
              variant="outlined"
              fullWidth
              placeholder="Enter OTP"
              focused={true}
              onChange={handleOtpChange}
              inputProps={{
                maxLength: 6,
                style: { color: "#006fa4" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              {resendCountdown === 0 ? (
                <Button variant="text" onClick={handleResendOtp}>
                  Resend OTP
                </Button>
              ) : (
                <Typography variant="body2">
                  Resend OTP in {resendCountdown}s
                </Typography>
              )}
              <Button
                variant="contained"
                onClick={handleVerifyOtp}
                sx={{ ml: 2 }}
              >
                Verify
              </Button>
            </Box>
          </Box>
        )}
        {success && (
          <Box
            display="flex"
            borderRadius={2}
            mt={2}
            padding="8px"
            bgcolor="#d7ffe8"
            justifyContent="center"
            alignItems="center"
          >
            <Typography align="center" variant="h6" color="#01b850">
              Registered successfully!
            </Typography>
          </Box>
        )}
        {error && (
          <Box
            display="flex"
            borderRadius={2}
            mt={2}
            padding="8px"
            bgcolor="#ff9999"
            justifyContent="center"
            alignItems="center"
          >
            <Typography align="center" variant="h6" color="#eb0a0a">
              {message}
            </Typography>
          </Box>
        )}
        {!otpSent && (
          <Box sx={{ mt: 3 }}>
            <Box sx={{ mt: 3 }}>
              <div style={{ margin: "8px" }} id="recaptcha-container"></div>
              {!progress && (
                <Button
                  id="signup-btn"
                  onClick={handleSendOtp}
                  variant="contained"
                  sx={{
                    width: "100%",
                    padding: "12px",
                    backgroundColor: "#006fa4",
                  }}
                >
                  Receive OTP
                </Button>
              )}
            </Box>
          </Box>
        )}

        <Box sx={{ mt: 3 }}>
          <Typography align="center" variant="h6">
            Don't have an account yet?{" "}
            <a href="/signup">
              <Typography
                color="#006fa4"
                variant="h5"
                style={{ cursor: "pointer" }}
                fontWeight={600}
              >
                Sign Up
              </Typography>
            </a>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
