import { ArrowBack } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { IconButton, Tab, Tabs, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import TestMultiWeek from "components/TestMultiWeek";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreatePlanMutation, useGetExercisesQuery } from "state/api";

const CreateCustomPlan = () => {
  const [name, setName] = useState("");
  const [weeks, setWeeks] = useState(1);
  const [department, setDepartment] = useState("");
  const [painPoints, setPainPoints] = useState([]);
  const [sports, setSports] = useState([]);
  const [multiWeekPlan, setMultiWeekPlan] = useState([]);
  const { data, loading } = useGetExercisesQuery();
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [numberOfSessions, setNumberOfSessions] = useState(1);
  const [createCustomPlan, response] = useCreatePlanMutation();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState();
  const query = useLocation().search;
  const user = new URLSearchParams(query).get("user");
  const handleExercises = (week, exercises) => {
    for (let index = 0; index < exercises.length; index++) {
      const element = exercises[index];
      if (element.exercise.length === 0) {
        setError(true);
        setMessage("Select exercise name");
        return;
      }
      if (element.sets === 0) {
        setError(true);
        setMessage("Sets should be more than 0");
        return;
      }
      if (element.reps === 0) {
        setError(true);
        setMessage("Reps should be more than 0");
        return;
      }
    }
    const tempWeek = multiWeekPlan.map((w) => {
      if (w.weekNumber === week.weekNumber) {
        w.exercises = exercises;
      }
      return w;
    });
    setMultiWeekPlan(tempWeek);
    setSuccess(true);
    setError(false);
    setMessage("Exercise updated");
    setTimeout(() => {
      setSuccess(false);
    }, 2000);

    
  };
  useState(() => {
    const mWP = [];
    for (let index = 0; index < weeks; index++) {
      mWP.push({
        weekNumber: index + 1,
        isDaily: false,
        exercises: [],
      });
    }
    setMultiWeekPlan(mWP);
 
  }, []);
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleWeeksChange = (e) => {
    setWeeks(e.target.value);
    const mWP = [];
    for (let index = 0; index < e.target.value; index++) {
      mWP.push({
        weekNumber: index + 1,
        isDaily: false,
        exercises: [],
      });
    }
    setMultiWeekPlan(mWP);
  };
 const handleExRepeat = (currentWeek, repeatWeek) => {
  var mWP = multiWeekPlan;
  
  let exercises = multiWeekPlan[currentWeek].exercises
  
  for (let index = 0; index < repeatWeek.length; index++) {
    const element = Number.parseInt(repeatWeek[index]);
    
    mWP[element-1] = {
      weekNumber: element,
      isDaily:false,
      exercises:exercises
    }
  }
  for (let index = 0; index < mWP.length; index++) {
    const element = mWP[index];
    if (!element) {
      mWP[index] = {
        weekNumber: index+1,
        isDaily:false,
        exercises:[]
      }
    }
  }
  setWeeks(Number.parseInt(repeatWeek[repeatWeek.length-1]));
  mWP[currentWeek].exercises = exercises
  setMultiWeekPlan(mWP)
  
 }
  const handleDepartmentChange = (e) => {
    if (e.target.value !== "sports_injury") {
      setSports([]);
    }
    setDepartment(e.target.value);
  };

  const handlePainPointsChange = (e) => {
    const selectedPainPoint = e.target.value;
    
    if (painPoints.includes(selectedPainPoint)) {
      setPainPoints(painPoints.filter((point) => point !== selectedPainPoint));
    } else {
      setPainPoints([...painPoints, selectedPainPoint]);
    }
  };
  const handleSportsChange = (e) => {
    const selectedSport = e.target.value;
  
    if (sports.includes(selectedSport)) {
      setSports(sports.filter((point) => point !== selectedSport));
    } else {
      setSports([...sports, selectedSport]);
    }
  };
  const navigate = useNavigate();
  const isSelectedPainPoint = (point) => {
    return painPoints.includes(point);
  };
  const isSelectedSport = (sport) => {
    return sports.includes(sport);
  };
  const sportsArray = [
    "badminton",
    "swimming",
    "football",
    "tennis",
    "basketball",
    "volleyball",
    "hand_ball",
    "cricket",
    "kabaddi",
    "weight_lifting",
    "wrestling",
  ];
  const theme = useTheme();
  const backgroundColor = theme.palette.primary.light;
  const isDarkMode = theme.palette.mode === "dark";
  const handleCreatePlan = () => {
    if (name.trim().length === 0) {
      setError(true);
      setMessage("Name is Required");
    } else if (department.trim().length === 0) {
      setError(true);
      setMessage("Select a department");
    } else if (painPoints.length === 0) {
      setError(true);
      setMessage("Select a pain point");
    } else if (department === "sports_injury" && sports.length === 0) {
      setError(true);
      setMessage("Select a sport");
    } else {
      for (let index = 0; index < multiWeekPlan.length; index++) {
        const element = multiWeekPlan[index];
        if (element.exercises.length === 0) {
          setError(true);
          setMessage("Add all exercises to each week");
          return;
        }
      }
      const plan = {
        name: name,
        painPoint: painPoints,
        department: [department],
        multiWeekPlan: multiWeekPlan,
        custom: true,
        createdBy: localStorage.getItem("user"),
        numberOfSessions: numberOfSessions,
      };

      createCustomPlan(plan)
        .unwrap()
        .then((data) => {
          setSuccess(true);
          setMessage("Custom Plan Created Successfully!");
          setTimeout(() => {
            setSuccess(false);
            setMessage();
            navigate(
              `/custom/details?partner=${localStorage.getItem(
                "user"
              )}&user=${user}&plan=${data._id}`
            );
          }, 2000);
        })
        .catch((error) => {
          setError(true);
          setMessage(error.data.message);
        });
    }
  };
  return (
    <div className="flex  flex-col md:flex-row">
      <div
        style={{ backgroundColor: theme.palette.background.alt }}
        className={`md:w-1/4 w-full p-4`}
      >
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBack />
        </IconButton>
        <div className="my-4">
          <label className="block font-bold mb-2" htmlFor="name">
            Plan Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="Enter Name (must be unique)"
            value={name}
            onChange={handleNameChange}
          />
        </div>
        <div className="my-4">
          <label className="block font-bold mb-2" htmlFor="weeks">
            Number of Weeks
          </label>
          <input
            min={1}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="weeks"
            type="number"
            placeholder="Enter Number of Weeks"
            value={weeks}
            onChange={handleWeeksChange}
          />
        </div>
        <div className="my-4">
          <label className="block font-bold mb-2" htmlFor="weeks">
            Number of Sessions
          </label>
          <input
            min={1}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="sessions"
            type="number"
            placeholder="Enter Number of Sessions in a day"
            value={numberOfSessions}
            onChange={(e) => {
              setNumberOfSessions(e.target.value);
            }}
          />
        </div>
        <div className="my-4">
          <label className="block font-bold mb-2" htmlFor="department">
            Department
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="department"
            value={department}
            onChange={handleDepartmentChange}
          >
            <option value="" selected disabled>
              Select Department
            </option>
            <option value="orthopedic">Orthopedic</option>
            <option value="sports_injury">Sports</option>
            <option value="gynaecology">Gynaecology</option>
            <option value="pcod">PCOD</option>
          </select>
        </div>
        <div className="my-4">
          <label className="block font-bold mb-2">Pain Point</label>
          <div className="flex flex-wrap">
            <div className="m-1">
              <input
                className="hidden"
                id="lowerBack"
                type="checkbox"
                value="lowerBack"
                onChange={handlePainPointsChange}
              />
              <label
                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${
                  isSelectedPainPoint("lowerBack") ? 100 : 40
                } rounded px-3 py-1 cursor-pointer`}
                htmlFor="lowerBack"
              >
                Lower Back
              </label>
            </div>
            <div className="m-1">
              <input
                className="hidden"
                id="upperBack"
                type="checkbox"
                value="upperBack"
                onChange={handlePainPointsChange}
              />
              <label
                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${
                  isSelectedPainPoint("upperBack") ? 100 : 40
                } rounded px-3 py-1 cursor-pointer`}
                htmlFor="upperBack"
              >
                Upper Back
              </label>
            </div>
            <div className="m-1">
              <input
                className="hidden"
                id="neck"
                type="checkbox"
                value="neck"
                onChange={handlePainPointsChange}
              />
              <label
                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${
                  isSelectedPainPoint("neck") ? 100 : 40
                } rounded px-3 py-1 cursor-pointer`}
                htmlFor="neck"
              >
                Neck
              </label>
            </div>
            <div className="m-1">
              <input
                className="hidden"
                id="shoulder"
                type="checkbox"
                value="shoulder"
                onChange={handlePainPointsChange}
              />
              <label
                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${
                  isSelectedPainPoint("shoulder") ? 100 : 40
                } rounded px-3 py-1 cursor-pointer`}
                htmlFor="shoulder"
              >
                Shoulder
              </label>
            </div>
            <div className="m-1">
              <input
                className="hidden"
                id="knee"
                type="checkbox"
                value="knee"
                onChange={handlePainPointsChange}
              />
              <label
                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${
                  isSelectedPainPoint("knee") ? 100 : 40
                } rounded px-3 py-1 cursor-pointer`}
                htmlFor="knee"
              >
                Knee
              </label>
            </div>
            <div className="m-1">
              <input
                className="hidden"
                id="hip"
                type="checkbox"
                value="hip"
                onChange={handlePainPointsChange}
              />
              <label
                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${
                  isSelectedPainPoint("hip") ? 100 : 40
                } rounded px-3 py-1 cursor-pointer`}
                htmlFor="hip"
              >
                Hip
              </label>
            </div>
            <div className="m-1">
              <input
                className="hidden"
                id="ankle"
                type="checkbox"
                value="ankle"
                onChange={handlePainPointsChange}
              />
              <label
                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${
                  isSelectedPainPoint("ankle") ? 100 : 40
                } rounded px-3 py-1 cursor-pointer`}
                htmlFor="ankle"
              >
                Ankle
              </label>
            </div>
            <div className="m-1">
              <input
                className="hidden"
                id="wristAndHand"
                type="checkbox"
                value="wristAndHand"
                onChange={handlePainPointsChange}
              />
              <label
                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${
                  isSelectedPainPoint("wristAndHand") ? 100 : 40
                } rounded px-3 py-1 cursor-pointer`}
                htmlFor="wristAndHand"
              >
                Wrist and Hand
              </label>
            </div>
            <div className="m-1">
              <input
                className="hidden"
                id="elbow"
                type="checkbox"
                value="elbow"
                onChange={handlePainPointsChange}
              />
              <label
                className={`inline-flex items-center bg-[#586cfa] bg-opacity-${
                  isSelectedPainPoint("elbow") ? 100 : 40
                } rounded px-3 py-1 cursor-pointer`}
                htmlFor="elbow"
              >
                Elbow
              </label>
            </div>
          </div>
        </div>
        {department === "sports_injury" && (
          <div className="my-4">
            <label className="block font-bold mb-2">Sports</label>
            <div className="flex flex-wrap">
              {sportsArray.map((s, i) => {
                return (
                  <div className="m-1" key={i}>
                    <input
                      className="hidden"
                      id={s}
                      type="checkbox"
                      value={s}
                      onChange={handleSportsChange}
                    />
                    <label
                      className={`inline-flex items-center bg-[#586cfa] bg-opacity-${
                        isSelectedSport(s) ? 100 : 40
                      } rounded px-3 py-1 cursor-pointer`}
                      htmlFor={s}
                    >
                      {s.split("_").join(" ")}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <button
          onClick={handleCreatePlan}
          className="rounded px-4 py-2 bg-[#5240eb] w-full mt-4"
        >
          Create Plan
        </button>
        {error && (
          <div className="w-full p-4 mt-2 rounded-lg bg-red-200 text-red-600 text-center">
            {message}
          </div>
        )}
        {success && (
          <div className="w-full p-4 mt-2 rounded-lg bg-green-200 text-green-600 text-center">
            {message}
          </div>
        )}
      </div>
      <div className="w-full md:w-3/4 py-4 px-8 text-white overflow-y-auto">
        <TabContext value={selectedWeek}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            borderRadius={2}
            padding={2}
          >
            <TabList
              variant="scrollable"
              onChange={(e, v) => {
                setSelectedWeek(Number.parseInt(v));
              }}
              aria-label="basic tabs example"
            >
              {Array.from({ length: weeks }, (_, index) => (
                <Tab
                  value={index}
                  key={index}
                  label={`Week ${index + 1}`}
                ></Tab>
              ))}
            </TabList>
          </Box>

          {multiWeekPlan.map((m, i) => {
            return (
              <TabPanel value={i} key={i}>
                {data && (
                  <TestMultiWeek
                    key={i}
                    week={m}
                    handleExRepeat={handleExRepeat}
                    handleExercises={handleExercises}
                    allExercises={data}
                  />
                )}
              </TabPanel>
            );
          })}
        </TabContext>
      </div>
    </div>
  );
};

export default CreateCustomPlan;
