import React, { useEffect, useMemo, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { antenatalData } from 'utils/data';
import { HeartIcon, ScaleIcon, FireIcon, AdjustmentsIcon } from '@heroicons/react/solid';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, FormControl, IconButton, InputLabel, MenuItem, Select, Tab, Tabs, Typography, makeStyles, useTheme } from '@mui/material';
import { Add, ArrowBack, ArrowForwardIos, Edit, ExpandMore, FilterList } from '@mui/icons-material';
import { useGetTrimestersQuery } from 'state/api';
import moment from 'moment';
import { ResponsiveLine } from '@nivo/line';
import BizarreCurveGraph from './chart';
import data from 'utils/geography';
import { useNavigate } from 'react-router-dom';

const GraphCard = ({selectedCase, title, trimesterComponent, unit, data, setTrimester1, setTrimester2, setTrimester3, trimesterData, selectedTab, }) => {
    const theme = useTheme()
    const [showDetailGraph, setShowDetailGraph] = useState(false)
    const handleShowFullViewChart = (filteredData) => {
        if (selectedTab === 0) {
            setTrimester1(filteredData)
        } else if (selectedTab === 1) {
            setTrimester2(filteredData)
        } else {
            setTrimester3(filteredData)
        }
        setShowDetailGraph(!showDetailGraph)
    }
    return (
        <div key={title} className="shadow-lg rounded-lg p-6" style={{ backgroundColor: theme.palette.background.alt }}>
            {showDetailGraph && <FullViewChart selectedCase={selectedCase} key={title} title={title} data={data} trimesterData={trimesterData} setTrimester1={setTrimester1} selectedTab={selectedTab} handleShowFullViewChart={handleShowFullViewChart} />}
            <div className='flex justify-between items-center'>
                <div className="flex gap-4 w-full flex-col items-start mb-2">

                    <div className='w-full flex items-center justify-between'>
                        <h3 className="">{title}</h3>
                        <IconButton onClick={() => {
                            console.log(trimesterData[selectedCase].trimesters.filter(trimester => trimester.trimester === 'Trimester 1'))
                            if (selectedTab === 0) {
                                handleShowFullViewChart(trimesterData[selectedCase].trimesters.filter(trimester => trimester.trimester === 'Trimester 1'))
                            } else if (selectedTab === 1) {
                                handleShowFullViewChart(trimesterData[selectedCase].trimesters.filter(trimester => trimester.trimester === 'Trimester 2'))
                            } else {
                                handleShowFullViewChart(trimesterData[selectedCase].trimesters.filter(trimester => trimester.trimester === 'Trimester 3'))
                            }

                        }} >
                            <ArrowForwardIos />
                        </IconButton>
                    </div>
                    {trimesterComponent}
                </div>
                {/* <IconButton onClick={onIconClick}>
         <Edit/> 
        </IconButton> */}
            </div>
            <Box height="25vh">
                <BizarreCurveGraph title={title} data={data} />
            </Box>
            {/* <ResponsiveLine
                data={[]}
                theme={{
                    axis: {
                        domain: {
                            line: {
                                stroke: theme.palette.secondary[200],
                            },
                        },
                        legend: {
                            text: {
                                fill: theme.palette.secondary[200],
                            },
                        },
                        ticks: {
                            line: {
                                stroke: theme.palette.secondary[200],
                                strokeWidth: 1,
                            },
                            text: {
                                fill: theme.palette.secondary[200],
                            },
                        },
                        class: 'text-sm lg:text-base whitespace-nowrap md:whitespace-normal md:flex-wrap truncated',

                    },
                    legends: {
                        text: {
                            fill: theme.palette.secondary[200],
                        },
                    },
                    tooltip: {
                        container: {
                            color: theme.palette.primary.main,
                        },
                    },
                }}

                // margin={margin}
                xScale={{ type: "point" }}
                yScale={{
                    type: "band",
                    min: "auto",
                    max: "auto",
                    stacked: false,
                    reverse: false,
                }}
                yFormat=" >-.2f"
                curve="catmullRom"
                enableArea={true}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    format: (v) => {
                        // abbreviate month names
                        const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                        return monthAbbreviations[v - 1];
                    },
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0, // rotate the labels by 45 degrees
                    legend: "Month",
                    legendOffset: 40,
                    legendPosition: "middle",
                    tickMargin: 5,


                    // display only 5 tick values
                }}
                // axisLeft={{
                //     orient: "left",
                //     tickValues: 5,
                //     tickSize: 3,
                //     tickPadding: 5,
                //     tickRotation: 0,
                //     legend: `${selectedPainScaled === muscleT ? "Muscle Tone" : selectedPainScaled === muscleST ? "Muscle Strength Testing" : "Assessed Pain Scale"}`,
                //     legendOffset: -60,
                //     legendPosition: "middle",
                // }}
                enableGridX={false}
                enableGridY={false}
                pointSize={10}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}

            /> */}
        </div>
    );
};

const FullViewChart = ({ selectedCase,data, title, trimesterData, setTrimester1, selectedTab, handleShowFullViewChart }) => {
    const theme = useTheme()
    const [timeRange, setTimeRange] = useState("weekly")
    
    return (
        <Box sx={{ marginLeft: { backgroundColor: theme.palette.background.default } }} className='flex flex-col z-50 fixed h-full inset-0 w-full'>
            <div className='flex  mx-72 justify-between items-start p-4 my-16' >
                <div className=' flex items-center gap-4'>
                    <IconButton onClick={() => { 
                          if (selectedTab === 0) {
                            handleShowFullViewChart(trimesterData[selectedCase].trimesters.filter(trimester => trimester.trimester === 'Trimester 1').splice(0,3))
                        } else if (selectedTab === 1) {
                            handleShowFullViewChart(trimesterData[selectedCase].trimesters.filter(trimester => trimester.trimester === 'Trimester 2').splice(0,3))
                        } else {
                            handleShowFullViewChart(trimesterData[selectedCase].trimesters.filter(trimester => trimester.trimester === 'Trimester 3').splice(0,3))
                        }
                     }} >
                        <ArrowBack fontSize="medium" />
                    </IconButton>
                    <h2>{title} - Trimester {selectedTab+1}</h2>

                </div>
                <FormControl className=''>
                    {/* <InputLabel>Time Range</InputLabel> */}
                    <Select
                        value={timeRange}
                        label=""
                        variant="standard"
                        onChange={(e) => setTimeRange(e.target.value)}
                    >
                        <MenuItem value={"weekly"}>Weekly</MenuItem>
                        <MenuItem value={"monthly"}>Monthly</MenuItem>

                    </Select>

                </FormControl>
            </div>
            <Box height="75vh" className='mx-72'>
                <BizarreCurveGraph title={title} data={data} />
            </Box>
        </Box>
    )
}

const AntenatalCare = ({userId, mobile}) => {
     
    const theme = useTheme()
    const [selectedTab, setSelectedTab] = useState(0)
    const [trimester1, setTrimester1] = useState([])
    const [trimester2, setTrimester2] = useState([])
    const [trimester3, setTrimester3] = useState([])
 
    const trimesterParams = [
        { name: "Blood Pressure", unit: "mmHg" },
        { name: "Pulse Rate", unit: "BPM" },
        { name: "Temperature", unit: "°C" },
        { name: "SpO2", unit: "" },
        { name: "Weight", unit: "kg" },
        { name: "Blood Sugar", unit: "mg/dL" },
        { name: "Haemoglobin", unit: "g/dL" },
        { name: "Foetal Heart Rate", unit: "BPM" },
        { name: "Urine Protein Albumin", unit: "mg/dL" },
        { name: "Blood Sugar Urine", unit: "mg/dL" },
        { name: "T3", unit: "ng/dL" },
        { name: "T4", unit: "µg/dL" },
        { name: "TSH", unit: "µIU/mL" },
        { name: "TORCH Panel IgM", unit: "IU/mL" }, // Example unit
        { name: "TORCH Panel IgG", unit: "IU/mL" }, // Example unit
        { name: "Cholesterol (Lipid Profile)", unit: "mg/dL" },
        { name: "Triglycerides (Lipid Profile)", unit: "mg/dL" },
        { name: "HDL Cholesterol (Lipid Profile)", unit: "mg/dL" },
        { name: "LDL Cholesterol (Lipid Profile)", unit: "mg/dL" },
        { name: "HbsAg", unit: "IU/mL" }, // Example unit
        { name: "OGTT", unit: "mg/dL" },
        { name: "Prolactin Test", unit: "ng/mL" },
        { name: "Estrogen Level Test", unit: "pg/mL" },
        { name: "Anti-Mullerian Hormone Test", unit: "ng/mL" }
    ];
    const navigate = useNavigate()
    const { data } = useGetTrimestersQuery({ user: userId })
    const [selectedCase, setSelectedCase] = useState(0)
    useEffect(() => {
        if (data) {
            if (data.length > 0) {
                setTrimester1(data[selectedCase].trimesters.filter(trimester => trimester.trimester === 'Trimester 1').splice(0, 3))
                setTrimester2(data[selectedCase].trimesters.filter(trimester => trimester.trimester === 'Trimester 2').splice(0, 3))
                setTrimester3(data[selectedCase].trimesters.filter(trimester => trimester.trimester === 'Trimester 3').splice(0, 3))
            } 
        }
    }, [data, selectedCase, selectedTab])
    const isDarkMode = theme.palette.mode === 'dark';
    const [bloodPressureData, pulseRateData, temperatureData, spo2GData, weightData, bloodSugarData, haemoglobinhData, foetalHeartRateData, urineProteinAlbuminData, bloodSugarUrineData, t3Data, t4Data, tshData, torchPanelIgmData, torchPanelIggData, cholesterolData, triglyceridesData, hdlCholesterolData, ldlCholesterolData, hbsAgData, ogttData, prolactinTestData, estrogenLevelTestData, antiMullerianHormoneTestData] = useMemo(() => {
        const bloodPressureGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const pulseRateGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const temperatureGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const spo2GraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const weightGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const bloodSugarGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const haemoglobinGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const foetalHeartRateGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const urineProteinAlbuminGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const bloodSugarUrineGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const t3GraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const t4GraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }


        const tshGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const torchPanelIgmeGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const torchPanelIggGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const cholesterolGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const triglyceridesGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const hdlCholesterolGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }


        const ldlCholesterolGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const hbsAgssGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const ogttGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const prolactinTestGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const estrogenLevelTestGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        const antiMullerianHormoneTestGraphData = {
            id: trimesterParams[0].name,
            color: theme.palette.secondary.main,
            data: [],
        }

        if (data) {
            if (selectedTab === 0 && trimester1 && trimester1.length > 0) {
                Object.values(trimester1).reduce((acc, { bloodPressure, pulseRate, temperature, spo2, weight, bloodSugar, haemoglobin, foetalHeartRate, urineProteinAlbumin, bloodSugarUrine, t3, t4, tsh, torchPanelIgm, torchPanelIgg, lipidProfile, hbsAg, ogtt, prolactinTest, estrogenLevelTest, antiMullerianHormoneTest, date }) => {
                    bloodPressureGraphData.data = [
                        ...bloodPressureGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: bloodPressure }
                    ];
                    pulseRateGraphData.data = [
                        ...pulseRateGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: pulseRate }
                    ];
                    temperatureGraphData.data = [
                        ...temperatureGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: temperature }
                    ];
                    spo2GraphData.data = [
                        ...spo2GraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: spo2 }
                    ];
                    weightGraphData.data = [
                        ...weightGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: weight }
                    ];
                    bloodSugarGraphData.data = [
                        ...bloodSugarGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: bloodSugar }
                    ];
                    haemoglobinGraphData.data = [
                        ...haemoglobinGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: haemoglobin }
                    ];
                    foetalHeartRateGraphData.data = [
                        ...foetalHeartRateGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: foetalHeartRate }
                    ];
                    urineProteinAlbuminGraphData.data = [
                        ...urineProteinAlbuminGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: urineProteinAlbumin }
                    ];
                    bloodSugarUrineGraphData.data = [
                        ...bloodSugarUrineGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: bloodSugarUrine }
                    ];
                    t3GraphData.data = [
                        ...t3GraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: t3 }
                    ];
                    t4GraphData.data = [
                        ...t4GraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: t4 }
                    ];
                    tshGraphData.data = [
                        ...tshGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: tsh }
                    ];
                    torchPanelIgmeGraphData.data = [
                        ...torchPanelIgmeGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: torchPanelIgm }
                    ];
                    torchPanelIggGraphData.data = [
                        ...torchPanelIggGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: torchPanelIgg }
                    ];
                    cholesterolGraphData.data = [
                        ...cholesterolGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: lipidProfile.cholesterol }
                    ];
                    triglyceridesGraphData.data = [
                        ...triglyceridesGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: lipidProfile.triglycerides }
                    ];
                    hdlCholesterolGraphData.data = [
                        ...hdlCholesterolGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: lipidProfile.hdlCholesterol }
                    ];
                    ldlCholesterolGraphData.data = [
                        ...ldlCholesterolGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: lipidProfile.ldlCholesterol }
                    ];
                    hbsAgssGraphData.data = [
                        ...hbsAgssGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: hbsAg }
                    ];
                    ogttGraphData.data = [
                        ...ogttGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: ogtt }
                    ];
                    prolactinTestGraphData.data = [
                        ...prolactinTestGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: prolactinTest }
                    ];
                    estrogenLevelTestGraphData.data = [
                        ...estrogenLevelTestGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: estrogenLevelTest }
                    ];
                    antiMullerianHormoneTestGraphData.data = [
                        ...antiMullerianHormoneTestGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: antiMullerianHormoneTest }
                    ];
                    return { date: moment(date).format("DD MMM YYYY") }
                }, { date: moment(new Date()).format("DD MMM YYYY") })
            }
            if (selectedTab === 1 && trimester2 && trimester2.length > 0) {
                Object.values(trimester2).reduce((acc, { bloodPressure, pulseRate, temperature, spo2, weight, bloodSugar, haemoglobin, foetalHeartRate, urineProteinAlbumin, bloodSugarUrine, t3, t4, tsh, torchPanelIgm, torchPanelIgg, lipidProfile, hbsAg, ogtt, prolactinTest, estrogenLevelTest, antiMullerianHormoneTest, date }) => {
                    bloodPressureGraphData.data = [
                        ...bloodPressureGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: bloodPressure }
                    ];
                    pulseRateGraphData.data = [
                        ...pulseRateGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: pulseRate }
                    ];
                    temperatureGraphData.data = [
                        ...temperatureGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: temperature }
                    ];
                    spo2GraphData.data = [
                        ...spo2GraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: spo2 }
                    ];
                    weightGraphData.data = [
                        ...weightGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: weight }
                    ];
                    bloodSugarGraphData.data = [
                        ...bloodSugarGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: bloodSugar }
                    ];
                    haemoglobinGraphData.data = [
                        ...haemoglobinGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: haemoglobin }
                    ];
                    foetalHeartRateGraphData.data = [
                        ...foetalHeartRateGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: foetalHeartRate }
                    ];
                    urineProteinAlbuminGraphData.data = [
                        ...urineProteinAlbuminGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: urineProteinAlbumin }
                    ];
                    bloodSugarUrineGraphData.data = [
                        ...bloodSugarUrineGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: bloodSugarUrine }
                    ];
                    t3GraphData.data = [
                        ...t3GraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: t3 }
                    ];
                    t4GraphData.data = [
                        ...t4GraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: t4 }
                    ];
                    tshGraphData.data = [
                        ...tshGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: tsh }
                    ];
                    torchPanelIgmeGraphData.data = [
                        ...torchPanelIgmeGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: torchPanelIgm }
                    ];
                    torchPanelIggGraphData.data = [
                        ...torchPanelIggGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: torchPanelIgg }
                    ];
                    cholesterolGraphData.data = [
                        ...cholesterolGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: lipidProfile.cholesterol }
                    ];
                    triglyceridesGraphData.data = [
                        ...triglyceridesGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: lipidProfile.triglycerides }
                    ];
                    hdlCholesterolGraphData.data = [
                        ...hdlCholesterolGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: lipidProfile.hdlCholesterol }
                    ];
                    ldlCholesterolGraphData.data = [
                        ...ldlCholesterolGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: lipidProfile.ldlCholesterol }
                    ];
                    hbsAgssGraphData.data = [
                        ...hbsAgssGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: hbsAg }
                    ];
                    ogttGraphData.data = [
                        ...ogttGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: ogtt }
                    ];
                    prolactinTestGraphData.data = [
                        ...prolactinTestGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: prolactinTest }
                    ];
                    estrogenLevelTestGraphData.data = [
                        ...estrogenLevelTestGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: estrogenLevelTest }
                    ];
                    antiMullerianHormoneTestGraphData.data = [
                        ...antiMullerianHormoneTestGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: antiMullerianHormoneTest }
                    ];
                    return { date: moment(date).format("DD MMM YYYY") }
                }, { date: moment(new Date()).format("DD MMM YYYY") })
            }
            if (selectedTab === 2 && trimester3 && trimester3.length > 0) {
                Object.values(trimester3).reduce((acc, { bloodPressure, pulseRate, temperature, spo2, weight, bloodSugar, haemoglobin, foetalHeartRate, urineProteinAlbumin, bloodSugarUrine, t3, t4, tsh, torchPanelIgm, torchPanelIgg, lipidProfile, hbsAg, ogtt, prolactinTest, estrogenLevelTest, antiMullerianHormoneTest, date }) => {
                    bloodPressureGraphData.data = [
                        ...bloodPressureGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: bloodPressure }
                    ];
                    pulseRateGraphData.data = [
                        ...pulseRateGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: pulseRate }
                    ];
                    temperatureGraphData.data = [
                        ...temperatureGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: temperature }
                    ];
                    spo2GraphData.data = [
                        ...spo2GraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: spo2 }
                    ];
                    weightGraphData.data = [
                        ...weightGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: weight }
                    ];
                    bloodSugarGraphData.data = [
                        ...bloodSugarGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: bloodSugar }
                    ];
                    haemoglobinGraphData.data = [
                        ...haemoglobinGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: haemoglobin }
                    ];
                    foetalHeartRateGraphData.data = [
                        ...foetalHeartRateGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: foetalHeartRate }
                    ];
                    urineProteinAlbuminGraphData.data = [
                        ...urineProteinAlbuminGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: urineProteinAlbumin }
                    ];
                    bloodSugarUrineGraphData.data = [
                        ...bloodSugarUrineGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: bloodSugarUrine }
                    ];
                    t3GraphData.data = [
                        ...t3GraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: t3 }
                    ];
                    t4GraphData.data = [
                        ...t4GraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: t4 }
                    ];
                    tshGraphData.data = [
                        ...tshGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: tsh }
                    ];
                    torchPanelIgmeGraphData.data = [
                        ...torchPanelIgmeGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: torchPanelIgm }
                    ];
                    torchPanelIggGraphData.data = [
                        ...torchPanelIggGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: torchPanelIgg }
                    ];
                    cholesterolGraphData.data = [
                        ...cholesterolGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: lipidProfile.cholesterol }
                    ];
                    triglyceridesGraphData.data = [
                        ...triglyceridesGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: lipidProfile.triglycerides }
                    ];
                    hdlCholesterolGraphData.data = [
                        ...hdlCholesterolGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: lipidProfile.hdlCholesterol }
                    ];
                    ldlCholesterolGraphData.data = [
                        ...ldlCholesterolGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: lipidProfile.ldlCholesterol }
                    ];
                    hbsAgssGraphData.data = [
                        ...hbsAgssGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: hbsAg }
                    ];
                    ogttGraphData.data = [
                        ...ogttGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: ogtt }
                    ];
                    prolactinTestGraphData.data = [
                        ...prolactinTestGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: prolactinTest }
                    ];
                    estrogenLevelTestGraphData.data = [
                        ...estrogenLevelTestGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: estrogenLevelTest }
                    ];
                    antiMullerianHormoneTestGraphData.data = [
                        ...antiMullerianHormoneTestGraphData.data,
                        { x: moment(date).format("DD MMM YYYY"), y: antiMullerianHormoneTest }
                    ];
                    return { date: moment(date).format("DD MMM YYYY") }
                }, { date: moment(new Date()).format("DD MMM YYYY") })
            }
        }
        return [[bloodPressureGraphData], [pulseRateGraphData], [temperatureGraphData], [spo2GraphData], [weightGraphData], [bloodSugarGraphData], [haemoglobinGraphData], [foetalHeartRateGraphData], [urineProteinAlbuminGraphData], [bloodSugarUrineGraphData], [t3GraphData], [t4GraphData], [tshGraphData], [torchPanelIgmeGraphData], [torchPanelIggGraphData], [cholesterolGraphData], [triglyceridesGraphData], [hdlCholesterolGraphData], [ldlCholesterolGraphData], [hbsAgssGraphData], [ogttGraphData], [prolactinTestGraphData], [estrogenLevelTestGraphData], [antiMullerianHormoneTestGraphData]]
    }, [data, trimester1, trimester2, trimester3, selectedTab])
  
    return (
        <div style={{ width: '100%' }}>
            <div className="w-full flex justify-between items-center py-4">
                <h2 className='text-lg font-bold'>Detailed Vitals</h2>
                <ButtonGroup >
                    <Select value={selectedCase} onChange={(e)=>{setSelectedCase(e.target.value)}}>
                        {
                         data && data.length > 0 && data.map((d, i)=>{
                            return <MenuItem value={i}>Case {i+1}</MenuItem>
                         })
                        }
                    </Select>
                    <IconButton onClick={()=>{navigate(`/patient/add-trimester?patient=${userId}&mobile=${mobile}`)}}>
                        <Add />
                    </IconButton>
                </ButtonGroup>
            </div>
            <div>
                <Tabs style={{ marginTop: 8 }} value={selectedTab} TabIndicatorProps={{ style: { background: isDarkMode ? theme.palette.primary.light : theme.palette.secondary.light } }} >
                    <Tab style={{ color: isDarkMode ? theme.palette.primary[100] : theme.palette.secondary.light }} value={0} onClick={() => { setSelectedTab(0) }} label="Trimester 1" />
                    <Tab style={{ color: isDarkMode ? theme.palette.primary[100] : theme.palette.secondary.light }} value={1} onClick={() => { setSelectedTab(1) }} label="Trimester 2" />
                    <Tab style={{ color: isDarkMode ? theme.palette.primary[100] : theme.palette.secondary.light }} value={2} onClick={() => { setSelectedTab(2) }} label="Trimester 3" />
                    <IconButton onClick={()=>{navigate(`/patient/edit-trimester?patient=${userId}&mobile=${mobile}`, {state: data[selectedCase]})}}>
                        <Edit/>
                    </IconButton>
                </Tabs>
            </div>
            {
                selectedTab === 0 && <div className="w-full">
                    {
                        trimester1.length > 0 && <div className="grid md:grid-cols-2 mt-4 gap-4 sm:grid-cols-1">
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={bloodPressureData}
                                title={trimesterParams[0].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.bloodPressure} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={pulseRateData}
                                title={trimesterParams[1].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.pulseRate} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={temperatureData}
                                title={trimesterParams[2].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.temperature} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={spo2GData}
                                title={trimesterParams[3].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.spo2} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={weightData}
                                title={trimesterParams[4].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.weight} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={bloodSugarData}
                                title={trimesterParams[5].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.bloodSugar} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={haemoglobinhData}
                                title={trimesterParams[6].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.haemoglobin} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />

                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={foetalHeartRateData}
                                title={trimesterParams[7].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.foetalHeartRate} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={urineProteinAlbuminData}
                                title={trimesterParams[8].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.urineProteinAlbumin} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={bloodSugarUrineData}
                                title={trimesterParams[9].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.bloodSugarUrine} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />

                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={t3Data}
                                title={trimesterParams[10].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.t3} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={t4Data}
                                title={trimesterParams[11].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.t4} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={tshData}
                                title={trimesterParams[12].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.tsh} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={torchPanelIgmData}
                                title={trimesterParams[13].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.torchPanelIgm} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={torchPanelIggData}
                                title={trimesterParams[14].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.torchPanelIgg} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={cholesterolData}
                                title={trimesterParams[15].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.lipidProfile.cholesterol} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={triglyceridesData}
                                title={trimesterParams[16].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.lipidProfile.triglycerides} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={hdlCholesterolData}
                                title={trimesterParams[17].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.lipidProfile.hdlCholesterol} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={ldlCholesterolData}
                                title={trimesterParams[18].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.lipidProfile.ldlCholesterol} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={hbsAgData}
                                title={trimesterParams[19].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.hbsAg} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={ogttData}
                                title={trimesterParams[20].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.ogtt} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={prolactinTestData}
                                title={trimesterParams[21].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.prolactinTest} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={estrogenLevelTestData}
                                title={trimesterParams[22].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.estrogenLevelTest} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={antiMullerianHormoneTestData}
                                title={trimesterParams[23].name}
                                trimesterComponent={<div>
                                    {trimester1.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.antiMullerianHormoneTest} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />

                        </div>
                    }
                </div>
            }
            {
                selectedTab === 1 && <div className="w-full">
                    {
                        trimester2.length > 0 && <div className="grid md:grid-cols-2 mt-4 gap-4 sm:grid-cols-1">
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={bloodPressureData}
                                title={trimesterParams[0].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.bloodPressure} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={pulseRateData}
                                title={trimesterParams[1].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.pulseRate} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={temperatureData}
                                title={trimesterParams[2].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.temperature} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={spo2GData}
                                title={trimesterParams[3].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.spo2} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={weightData}
                                title={trimesterParams[4].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.weight} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={bloodSugarData}
                                title={trimesterParams[5].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.bloodSugar} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={haemoglobinhData}
                                title={trimesterParams[6].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.haemoglobin} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />

                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={foetalHeartRateData}
                                title={trimesterParams[7].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.foetalHeartRate} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={urineProteinAlbuminData}
                                title={trimesterParams[8].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.urineProteinAlbumin} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={bloodSugarUrineData}
                                title={trimesterParams[9].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.bloodSugarUrine} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />

                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={t3Data}
                                title={trimesterParams[10].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.t3} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={t4Data}
                                title={trimesterParams[11].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.t4} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={tshData}
                                title={trimesterParams[12].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.tsh} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={torchPanelIgmData}
                                title={trimesterParams[13].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.torchPanelIgm} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={torchPanelIggData}
                                title={trimesterParams[14].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.torchPanelIgg} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={cholesterolData}
                                title={trimesterParams[15].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.lipidProfile.cholesterol} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={triglyceridesData}
                                title={trimesterParams[16].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.lipidProfile.triglycerides} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={hdlCholesterolData}
                                title={trimesterParams[17].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.lipidProfile.hdlCholesterol} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={ldlCholesterolData}
                                title={trimesterParams[18].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.lipidProfile.ldlCholesterol} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={hbsAgData}
                                title={trimesterParams[19].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.hbsAg} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={ogttData}
                                title={trimesterParams[20].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.ogtt} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={prolactinTestData}
                                title={trimesterParams[21].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.prolactinTest} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={estrogenLevelTestData}
                                title={trimesterParams[22].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.estrogenLevelTest} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={antiMullerianHormoneTestData}
                                title={trimesterParams[23].name}
                                trimesterComponent={<div>
                                    {trimester2.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.antiMullerianHormoneTest} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />

                        </div>
                    }
                </div>
            }
            {
                selectedTab === 2 && <div className="w-full">
                    {
                        trimester3.length > 0 && <div className="grid md:grid-cols-2 mt-4 gap-4 sm:grid-cols-1">
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={bloodPressureData}
                                title={trimesterParams[0].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.bloodPressure} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={pulseRateData}
                                title={trimesterParams[1].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.pulseRate} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={temperatureData}
                                title={trimesterParams[2].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.temperature} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={spo2GData}
                                title={trimesterParams[3].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.spo2} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={weightData}
                                title={trimesterParams[4].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.weight} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={bloodSugarData}
                                title={trimesterParams[5].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.bloodSugar} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={haemoglobinhData}
                                title={trimesterParams[6].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.haemoglobin} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />

                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={foetalHeartRateData}
                                title={trimesterParams[7].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.foetalHeartRate} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={urineProteinAlbuminData}
                                title={trimesterParams[8].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.urineProteinAlbumin} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={bloodSugarUrineData}
                                title={trimesterParams[9].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.bloodSugarUrine} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />

                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={t3Data}
                                title={trimesterParams[10].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.t3} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={t4Data}
                                title={trimesterParams[11].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.t4} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={tshData}
                                title={trimesterParams[12].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.tsh} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={torchPanelIgmData}
                                title={trimesterParams[13].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.torchPanelIgm} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={torchPanelIggData}
                                title={trimesterParams[14].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.torchPanelIgg} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={cholesterolData}
                                title={trimesterParams[15].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.lipidProfile.cholesterol} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={triglyceridesData}
                                title={trimesterParams[16].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.lipidProfile.triglycerides} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={hdlCholesterolData}
                                title={trimesterParams[17].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.lipidProfile.hdlCholesterol} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={ldlCholesterolData}
                                title={trimesterParams[18].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.lipidProfile.ldlCholesterol} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={hbsAgData}
                                title={trimesterParams[19].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.hbsAg} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={ogttData}
                                title={trimesterParams[20].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.ogtt} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={prolactinTestData}
                                title={trimesterParams[21].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.prolactinTest} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={estrogenLevelTestData}
                                title={trimesterParams[22].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.estrogenLevelTest} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />
                            <GraphCard
                                selectedTab={selectedTab}
                                selectedCase = {selectedCase}
                               
                                setTrimester1={setTrimester1}
                                setTrimester2={setTrimester2}
                                setTrimester3={setTrimester3}
                                trimesterData={data}
                                data={antiMullerianHormoneTestData}
                                title={trimesterParams[23].name}
                                trimesterComponent={<div>
                                    {trimester3.map((tm, idx) => {
                                        return (
                                            <div key={idx} className='flex gap-2 items-center'>
                                                <h2 className=''>&bull; {moment(tm.date).format("DD MMM yyyy")} - </h2>
                                                <h2 className=' font-bold'>{tm.antiMullerianHormoneTest} </h2>
                                            </div>
                                        )
                                    })}
                                </div>}
                            />

                        </div>
                    }
                </div>
            }
            { data && data.length === 0 && <div className='mt-8'>No Data Found</div> }

        </div>
    );
};

export default AntenatalCare;