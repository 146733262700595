import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Exercises from "./Exercises";
import PlayVideo from "./PlayVideo";

const MultiWeekDetail = ({ week, handleExercises, allExercises, success, error, message }) => {

    const [exercises, setExercises] = useState(week.exercises)
    const [selectedEx, setSelectedEx] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [showAllExercises, setShowAllExercises] = useState(false)

    const handleRemoveExercise = (exercise) => {
        setExercises(exercises.filter((i) => i !== exercise))
        handleExercises(week, exercises.filter((i) => i !== exercise))
    }
    // useEffect(()=>{
    //     let exer = []
    //     for (let index = 0; index < week.exercises.length; index++) {
    //         const element = week.exercises[index];
    //         exer.push({
    //             exercise: element.exercise._id,
    //             sets: element.sets,
    //             reps:element.reps,
    //             times:element.times
    //         })
    //     }
    //     setExercises(exer)
    // },[])
    const handleNumExercisesChange = (event) => {
        const numExercises = event.target.value;
        const newExercises = Array.from({ length: numExercises }, () => ({
            exercise: allExercises[0]._id,
            reps: 0,
            sets: 0,
            times: 1
        }));
        setExercises(newExercises);
    };

    const handleAddExercise = (exercise) => {
        // Check if the item already exists in the array
        let tempEx = exercises
        const itemExists = tempEx.some((element) => element.exercise === exercise.exercise);

        if (itemExists) {
            // If the item exists, update it
            const updatedArray = tempEx.map((element) =>
                element.exercise === exercise.exercise ? exercise : element
            );
            setExercises(updatedArray);
        } else {
            // If the item does not exist, add it to the array
            tempEx.push(exercise)
            setExercises(tempEx);
        }
        handleExercises(week, tempEx)
        
    }

    const handleExerciseChange = (index, field, value) => {

        setExercises((prevState) =>
            prevState.map((exercise, i) => {
                if (index === i) {
                    return { ...exercise, [field]: value };
                }
                return exercise;
            })
        );


    };
    const getExerciseName = (exercise) => {
        console.log(allExercises.filter((e) => e._id === exercise.exercise)[0].name)
        return allExercises.filter((e) => e._id === exercise.exercise).length > 0 ? allExercises.filter((e) => e._id === exercise.exercise)[0].name : ''
    }
    const theme = useTheme()
    const backgroundColor = theme.palette.secondary.light
    const isDarkMode = theme.palette.mode === 'dark';
    return (
        <div style={{color: isDarkMode ? "#ffe3a3" : theme.palette.secondary.dark}} className="bg-transparent w-full p-4  m-4" key={week.weekNumber} >
            {
                showAllExercises && <Exercises key={week.weekNumber} handleAddExercise={handleAddExercise} setShowModal={setShowAllExercises} showModal={showAllExercises} exercises={allExercises} />
            }
            {
                showModal && <PlayVideo key={selectedEx} setShowModal={setShowModal} showModal={showModal} videoUrl={allExercises.filter((i) => i._id === exercises[selectedEx].exercise)[0].videoUrl} />
            }
            <div>
                <div className="flex justify-between mb-4">
                    <h2 className="text-2xl font-bold">Week {week.weekNumber}</h2>
                    <button onClick={() => { setShowAllExercises(true) }} className="bg-gray-300 bg-opacity-40 rounded  p-2">Add Exercise</button>

                </div>
                {/* <div className="mb-4">
                    <label className="block font-bold mb-2" htmlFor="numExercises">
                        Enter Number of Exercises
                    </label>
                    {exercises.length > 0 && <input
                        min={1}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="numExercises"
                        type="number"
                        placeholder="Number of Exercises"
                        defaultValue={exercises.length}
                        onChange={handleNumExercisesChange}
                    />}
                </div> */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {exercises.map((exercise, exerciseIndex) => {
                        return <div style={{backgroundColor: theme.palette.background.alt, color: isDarkMode ? "#ffe3a3" : theme.palette.secondary.dark}} key={exerciseIndex} className="text-[#ffe3a3] p-4 rounded-md">
                            <div className="mb-4">
                            <label className="block font-bold  mb-2" htmlFor={`exerciseName${exerciseIndex}`}>
                                    Exercise {exerciseIndex+1}
                                </label>
                                <img onClick={() => {
                                    setSelectedEx(exerciseIndex)
                                    setShowModal(true)
                                }} src={allExercises.filter((i) => i._id === exercise.exercise)[0].thumbnailUrl} className="h-56  cursor-pointer" />
                            </div>
                            <div className="mb-4">
                              

                                <select
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id={`exerciseName${exerciseIndex}`}
                                    type="text"
                                    placeholder="Exercise Name"
                                    defaultValue={exercise.exercise}
                                    onChange={(e) => handleExerciseChange(exerciseIndex, 'exercise', e.target.value)}
                                >

                                    {
                                        allExercises.map((ex, i) => {
                                            return <option value={ex._id} key={i}>{ex.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="grid  grid-cols-2 gap-4">
                                <div className="mb-4">
                                    <label className="block font-bold mb-2" htmlFor={`exerciseReps${exerciseIndex}`}>
                                        Reps
                                    </label>
                                    <input
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id={`exerciseReps${exerciseIndex}`}
                                        type="number"
                                        placeholder="Reps"
                                        min={1}
                                        value={exercise.reps}
                                        onChange={(e) => handleExerciseChange(exerciseIndex, 'reps', Number.parseInt(e.target.value))}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block font-bold mb-2" htmlFor={`exerciseSets${exerciseIndex}`}>
                                        Sets
                                    </label>
                                    <input
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id={`exerciseSets${exerciseIndex}`}
                                        type="number"
                                        placeholder="Sets"
                                        min={1}
                                        value={exercise.sets}
                                        onChange={(e) => handleExerciseChange(exerciseIndex, 'sets', Number.parseInt(e.target.value))}
                                    />
                                </div>

                            </div>
                            <button onClick={() => { handleRemoveExercise(exercise) }} className="bg-red-300 bg-opacity-40 rounded w-full p-2">Remove Exercise</button>
                            <button onClick={() => { handleExercises(week, exercises) }} className="bg-gray-300 bg-opacity-40 mt-2 rounded w-full p-2">Update Exercise</button>

                        </div>
                    })}
                </div>

                <TabContext value={selectedEx}>

                    {/* 
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} borderRadius={2} padding={2}>
                        <TabList variant="scrollable" onChange={(e, v) => { setSelectedEx(Number.parseInt(v)) }}>
                            {
                                exercises.map((ex,i)=>{
                                    return  <Tab value={i} key={i} label={`Exercise ${i + 1}`}></Tab>
                                })
                            }
                        </TabList>
                    </Box> */}

                    {/* {
                        multiWeekPlan.map((m, i) => {
                            return <TabPanel value={i} key={i}>
                                {data && <MultiWeekDetail key={i} week={m} handleExercises={handleExercises} allExercises={data} />}

                            </TabPanel>
                        })

                    } */}
                </TabContext>



            </div>
        </div>
    );
};

export default MultiWeekDetail