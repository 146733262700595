import { useState } from 'react';

function PlayVideo({ videoUrl,showModal, setShowModal }) {
   

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };
 console.log(videoUrl)
  return (
    <div>
     
      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto" key={videoUrl}>
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
            <div className="bg-white rounded-lg p-8 max-w-md w-full mx-auto">
              <div className="relative pb-9/16">
              <video src={videoUrl} width="750" height="500" autoPlay controls>
              </video>
                {/* <iframe
                  className="absolute inset-0 w-full h-full"
                  src={videoUrl}
                  title="Video Player"
                  allowFullScreen
                ></iframe> */}
              </div>
              <button
                className="absolute top-14 right-4 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PlayVideo;
