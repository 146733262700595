import React from 'react'
import {
  Box,
  useMediaQuery,
  useTheme
} from "@mui/material"
import { useGetCustomersQuery, useGetProductsQuery, useGetUserFeedbacksQuery } from 'state/api'
import Header from 'components/Header'
import { DataGrid } from '@mui/x-data-grid'
import rows from "utils/customers"
import { useLocation, useNavigate } from 'react-router-dom'
import { Feed } from '@mui/icons-material'
import Feedback from 'components/Feedback'

const CustomerDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const query = useLocation().search;
  const id=new URLSearchParams(query).get("patient");
  const { data, isLoading } = useGetUserFeedbacksQuery(id);
  // console.log("dataCustomers", data)
  const rows = [];
  const isNonMobile = useMediaQuery("(min-width: 1000px)")

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={`Session Feedbacks`}></Header>
      {data || !isLoading ? (

        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 3" }
          }}
        >
          {data && data.map(
            ({
              plan,
              user,
              rom, 
              painPerception,
              difficulty,
              rating,
              time,
              _id
            }) => (
              <Feedback
                 key={_id}
                _id={_id}
                name={user.name}
                productName={plan.name}
                painPoint={plan.painPoint[0]}
                painPerception={painPerception}
                difficulty={difficulty}
                rating={rating}
                time={time}
                rom={rom}
                thumbnail={plan.thumbnail}
              />
            )
          )}
          { !data && <>No Feedback Found</>}
        </Box>
      ) : (
        <>Loading...</>
      )
      }
    </Box>
  )
}

export default CustomerDetails