import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    useTheme
} from "@mui/material"
import { useGetActiveSubscriptionQuery, useGetCustomersQuery, useGetUserTransferedCasesQuery } from 'state/api'
import Header from 'components/Header'
import { DataGrid } from '@mui/x-data-grid'
import rows from "utils/customers"
import { useNavigate, useOutletContext } from 'react-router-dom'
import PatientTransferedTable from "components/PatientTransferedTable";

export default function PatientTransfered(){
    const theme = useTheme();
    const navigate = useNavigate();
    const [userData] = useOutletContext()
    const [showAddonDialog, setShowAddonDialog] = useState(false)
    const { data, isLoading } = useGetUserTransferedCasesQuery(userData.userData[0]._id);
    
    return (
        <Box m="1.5rem 2.5rem">
            <Header title="PATIENTS TRANSFERED" subtitle="Find all patient cases you transefered!" />
            <Box
            mt="20px"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            {
              data && <PatientTransferedTable patients={data}/>
            }
          </Box>
    
    
        </Box>
      )
}