import React from 'react';
import './AdminTeam.css'; // Import CSS file for component styling
import { useGetTeamMembersQuery } from 'state/api';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { AccountCircle } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { tokensDark } from 'theme';
import { Fab } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

  

const AdminTeam = () => {
 
 
  const navigate = useNavigate()
  const [userData] = useOutletContext();
  const {data, loading} = useGetTeamMembersQuery(userData.userData[0]._id)
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const backgroundColor = isDarkMode ? '#3668a2' : '#917e4e';

  const handleAddMember = () => {
     navigate('/add-member')
  };
  // useEffect(()=>{
  //   if(!userData.team) {
  //      if(userData.userData[0].role !== "Doctor"){
  //       alert("Access Denied")
  //       navigate('/', {replace: true})
  //      }
  //   } else {
  //     if(userData.team.role !== "admin"){
  //       alert("Access Denied")
  //       navigate('/', {replace: true})
  //      }
  //   }
  // },[])
  return (
    <div className="admin-team-container">
    
      <div className="add-member-button-container">
           {
              !userData.team && userData.userData[0].role !== "Client" &&  <button className="add-member-button" style={{backgroundColor: backgroundColor}} onClick={handleAddMember}>
              Add Team Member
            </button>
            }
            {
              userData.team && userData.team.role === "admin" &&  <button className="add-member-button" onClick={handleAddMember}>
              Add Team Member
            </button>
            }
       
      </div>
      <h2 className="team-heading">
        Team
      </h2>
      <div className="team-cards-container">
        {data && data.map((member, index) => (
          <div
            key={index}
            style={{backgroundColor: backgroundColor}}
            className="team-card"
          >
           {member.photo &&  <img className="team-member-image" src={member.photo} alt={member.name} />}
           {!member.photo && <AccountCircle style={{height:"100px", width:"100px", color:"white"}}/>}
            <div className="team-member-details">
              <h3 className="team-member-name" style={{ color: "white"}}>
                {member.name}
              </h3>
              <p className="team-member-email" style={{ color: "white" }}>
                {member.email}
              </p>
              <p className="team-member-phone" style={{ color: "white" }}>
                {member.mobile}
              </p>
              <p className="team-member-phone" style={{ color: "white" }}>
                {member.role}
              </p>
              <p className="team-member-phone" style={{ color: "white" }}>
                {member.specialisation}
              </p>
            </div>
          </div>
        ))}
        {
          data && data.length === 0 && <div className='mt-4 text-[#4255d1]'>
            No Member Added!
          </div>
        }
      </div>
    </div>
  );
};

export default AdminTeam;