import React, { useEffect, useRef, useState } from 'react';
import AgoraUIKit from 'agora-react-uikit';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as posenet from "@tensorflow-models/posenet";
const AgoraVideoComponent = ({ app, channel, token, uid }) => {
  const [videoCall, setVideoCall] = useState(true);
  const webRef = useRef()
  const navigate = useNavigate()
  const rtcProps = {
    appId: app,
    channel: channel, // your agora channel
    token: token,// use null or skip if using app in testing mode
    uid: uid
  };
   
  const callbacks = {
    EndCall: () => {
     
      setVideoCall(false)
      navigate('/appointments', { replace: true })
      window.location.reload()
    },
    // "l": ({stream}) =>{
    //   console.log("stream", stream)
    //   const videoElement = webRef.current;
    //   stream.play(videoElement);
    // }
  };
  // const trackBody = async () => {
  //   const imageScaleFactor = 0.50;
  //   const flipHorizontal = false;
  //   const outputStride = 16;
  //   // get up to 5 poses
  //   const maxPoseDetections = 5;
  //   // minimum confidence of the root part of a pose
  //   const scoreThreshold = 0.5;
  //   // minimum distance in pixels between the root parts of poses
  //   const nmsRadius = 20;
  //   const imageElement = document.getElementById('cat');
  //   // load posenet
  //   const net = await posenet.load();
  //   const poses = await net.estimateMultiplePoses(
  //     imageElement, imageScaleFactor, flipHorizontal, outputStride,
  //     maxPoseDetections, scoreThreshold, nmsRadius);
  //   console.log(poses)
  // }
  // useEffect(()=>{
  //   trackBody()
  // },[])
  return videoCall ? (
    <Box  style={{display: 'flex',backgroundColor:"#146db9", width:'100%',height: '100vh'}} >
      <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} />
    </Box>
  ) : (

    <div>

    </div>
  );
};

export default AgoraVideoComponent;