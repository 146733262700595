import * as React from 'react';
import { useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import logofulldark from "assets/logofull.svg"
import './login.css'
import { color, width } from '@mui/system';
import { auth, signin } from 'firebaseConfig';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCreateAccountMutation } from 'state/api';


export default function SignupPage() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [resendCountdown, setResendCountdown] = useState(0);
    const [otpResult, setOtpResult] = useState();
    const [otp, setOtp] = useState();
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [progress, setProgress] = useState();
    const [message, setMessage] = useState('');
    const [createAccount, response] = useCreateAccountMutation();
    const [role, setRole] = useState("Doctor")
    const navigate = useNavigate();
    React.useEffect(() => {
        if (window) {
            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': 'normal',

            }, auth);
        }
    }, [])

    const handleSendOtp = () => {
        setProgress(true)
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
        if (name.trim().length === 0) {
            setError(true)
            setProgress(false)
            setMessage('Name is required')
            return
        }  else if (email.trim().length > 0 && !email.match(validRegex)) {
            setError(true)
            setProgress(false)
            setMessage('Invalid email address')
            return
        } else if (phoneNumber.trim().length === 0) {
            setError(true)
            setProgress(false)

            setMessage('Invalid phone number')
            return
        }
        setError(false)
        const appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((result) => {
                setOtpResult(result)
                setOtpSent(true);
                setResendCountdown(60);
                setProgress(false)
                const intervalId = setInterval(() => {
                    setResendCountdown(prevCount => prevCount - 1);
                }, 1000);
                setTimeout(() => {
                    clearInterval(intervalId);
                    setResendCountdown(0);
                }, 60000);
            }).catch((error) => {
                setError(true)
                setProgress(false)
                setMessage(error.message)
            })

    };

    const handleOtpChange = (e) => {
        setOtp(e.target.value)
    }
    const handleVerifyOtp = () => {
        setProgress(true)
        if (otp) {
            otpResult.confirm(otp)
                .then((result) => {

                    const account = { name: name, mobile: auth.currentUser?.phoneNumber, email: email, role: role }
                    createAccount(account).unwrap().then((data) => {
                        
                        setProgress(false)
                        setOtpVerified(true)
                        setSuccess(true)
                        localStorage.setItem('user', data._id)
                        localStorage.setItem('user_name', data.name)
                        // navigate(`/profile?partner=${data.data.name}`,{replace:true})
                        window.location.replace(`/`)
                    }).catch((err) => {
                        alert("Something went wrong!")
                        auth.signOut().then(() => {
                            setError(true)
                            setMessage(err.data.message)
                            setProgress(false)
                            setTimeout(() => {
                                window.location.reload()
                            }, 2000)
                        }).catch((error) => {
                           
                            setError(true)
                            setMessage(error.message)
                            setProgress(false)
                        })
                    })
                }).catch((error) => {
                    
                    setError(true)
                    setMessage(error.message)
                    setProgress(false)
                })
        } else {
            setError(true)
            setMessage("Something went wrong")
            setProgress(false)
        }
    };

    const handleResendOtp = () => {
        setOtpSent(false);
        setOtpVerified(false);
    };

    const handleNameChange = (e) => {
        setName(e.target.value)
        setError(false)
        setProgress(false)
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        setError(false)
        setProgress(false)
    }

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',

        }}>
            <Container sx={{
                color: 'black',
                boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                borderRadius: '16px',
                padding: '32px',
                bgcolor: 'white',
                width: '500px',
                margin: '12px'
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                   <Typography align='center' fontWeight={700} variant="h1" sx={{ mt: 4 }}>
                    <span className=' text-gray-600'>Casa</span><span className=' text-[#02B9ED]'>Doc</span>
                </Typography>

                </Box>

                <Typography align='center' fontWeight={700} variant="h5" sx={{ mt: 4 }}>
                    Create Account
                </Typography>
                {
                success && <Box display="flex" borderRadius={2} mt={2} padding="8px" bgcolor="#d7ffe8" justifyContent="center" alignItems="center"><Typography align='center' variant='h6' color="#01b850">Registered successfully!</Typography></Box>
            }
                {
                    !otpSent && <Box display='flex' flexDirection='column' sx={{ mt: 6 }}>
                        <Typography variant="h6" fontWeight={600}>
                            Name
                        </Typography>
                        <TextField
                            onChange={handleNameChange}
                            variant="standard"
                            fullWidth
                            placeholder='Enter Name'
                            focused={false}
                            required
                            inputProps={{

                                style: { color: 'black', fontSize: '16px', border: 'solid black', padding: '12px' }
                            }}
                        />

                        <Typography mt={2} variant="h6" fontWeight={600}>
                            Email
                        </Typography>
                        <TextField
                            variant="standard"
                            placeholder='Enter Email'
                            fullWidth
                            onChange={handleEmailChange}
                            focused={false}
                            type="email"
                            inputProps={{

                                style: { color: 'black', fontSize: '16px', border: 'solid black', padding: '12px' }
                            }}
                        />

<Typography mt={2} variant="h6" fontWeight={600}>
                            Are You A?
                        </Typography>
                        <Select
                                value={role}
                                label="Role"
                                 
                                 variant="standard"
                                 sx={{color:"black", '.MuiSvgIcon-root ': {
                                    fill: "black !important",
                                  }}}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <MenuItem  value={'Doctor'}>Doctor</MenuItem>
                                {/* <MenuItem value={'viewer'}>Viewer</MenuItem> */}
                                <MenuItem value={'Physio'}>Physio</MenuItem>
                            </Select> 
                        <Typography mt={2} variant="h6" fontWeight={600}>
                            Phone Number
                        </Typography>

                        <PhoneInput

                            style={{ padding: '12px', border: 'solid', marginTop: '8px' }}
                            className={"input-phone-number"}
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            defaultCountry="IN"
                            international
                            countryCallingCodeEditable={false}
                            inputProps={{

                                required: true,
                                autoFocus: true,


                            }}
                        />

                    </Box>

                }
                {otpSent && !otpVerified && (
                    <Box display="flex" flexDirection="column" sx={{ mt: 3, }}>


                        <TextField

                            label="Enter OTP"
                            variant="outlined"
                            fullWidth
                            placeholder='Enter OTP'
                            focused={true}
                            onChange={handleOtpChange}
                            inputProps={{
                                maxLength: 6,
                                style: { color: '#006fa4' }
                            }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                            {resendCountdown === 0 ? (
                                <Button variant="text" onClick={handleResendOtp}>
                                    Resend OTP
                                </Button>
                            ) : (
                                <Typography variant="body2">
                                    Resend OTP in {resendCountdown}s
                                </Typography>
                            )}
                            <Button variant="contained" onClick={handleVerifyOtp} sx={{ ml: 2 }}>
                                Verify
                            </Button>
                        </Box>

                    </Box>
                )}

                {
                    error && <Box display="flex" borderRadius={2} mt={2} padding="8px" bgcolor="#ff9999" justifyContent="center" alignItems="center"><Typography align='center' variant='h6' color="#eb0a0a">{message}</Typography></Box>
                }
                {!otpSent && (

                    <Box sx={{ mt: 3 }}>
                        <div style={{ margin: '8px' }} id='recaptcha-container'></div>
                        {!progress && <Button id="signup-btn" onClick={handleSendOtp} variant="contained" sx={{ width: '100%', padding: '12px', backgroundColor: "#006fa4" }}>
                            Receive OTP
                        </Button>}

                    </Box>
                )}

                {
                    progress && <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress sx={{ width: '100%', alignSelf: 'center' }} /></Box>
                }

                <Box sx={{ mt: 3 }}>
                    <Typography align='center' variant="h6">
                        Already have an account? <a href='/signin'><Typography color="#006fa4" variant='h5' style={{ cursor: 'pointer' }} fontWeight={600}>Sign In</Typography></a>
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}