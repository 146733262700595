import { CircularProgress, CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "theme";
import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Dashboard from "scenes/dashboard";
import Layout from "scenes/layout";
import Products from "scenes/products";
import Customers from "scenes/customers";
import Transactions from "scenes/transactions";
import Geography from "scenes/geography";
import Overview from "scenes/overview";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";
import Appointments from "scenes/appointments";
import VideoCall from "scenes/videoCall";
import CustomerDetails from "scenes/feedbacks";
import Loginnew from "scenes/signin";
import LoginPage from "scenes/signin";
import SignupPage from "scenes/signup";
import { auth, requestForToken } from "firebaseConfig";
import { useAuthState } from 'react-firebase-hooks/auth';
import { Box } from "@mui/system";
import Patient from "scenes/patient";
import Profile from "scenes/profile";
import AssignPlan from "scenes/patient/assign-plan";
import Tiers from "scenes/tiers";
import CreateCustomPlan from "scenes/custom/create";
import ViewCustomPlan from "scenes/custom/details";
import Subscriptions from "scenes/subscriptions";
import AddPatient from "scenes/patient/add";
import PatientProfile from "scenes/patient/profile";
import MedicalRecord from "scenes/patient/medical-record";
import AddRecord from "scenes/patient/add-record";
import UpdateStat from "scenes/patient/update-stats";
import PatientPage from "components/PatientPage";
import AppointmentPage from "components/AppointmentPage";
import TransactionPage from "components/TransactionPage";
import SubscriptionPage from "components/SubscriptionPage";
import MergePlan from "scenes/custom/merge";

// Import starts for the Admin UI

import Appointments_admin from 'Admin/scenes/adminappointments';
import Products_admin from 'Admin/scenes/adminproducts';
import Dashboard_admin from "Admin/scenes/admindashboard";
import Customers_admin from 'Admin/scenes/admincustomers';
import Transactions_admin from 'Admin/scenes/admintransactions';
import Geography_admin from 'Admin/scenes/admingeography';
import Overview_admin from 'Admin/scenes/adminoverview';
import Daily_admin from 'Admin/scenes/admindaily';
import Monthly_admin from 'Admin/scenes/adminmonthly';
import Breakdown_admin from 'Admin/scenes/adminbreakdown';
import VideoCall_admin from 'Admin/scenes/adminvideoCall';
import CustomerDetails_admin from 'Admin/scenes/adminfeedbacks';
import Subscriptions_admin from 'Admin/scenes/adminsubscriptions';
import AssignPlan_admin from 'Admin/scenes/adminpatient/admin_assign-plan';
import Patient_admin from 'Admin/scenes/adminpatient';
import AddPatient_admin from 'Admin/scenes/adminpatient/admin_add';
import MedicalRecord_admin from 'Admin/scenes/adminpatient/adminmedical-record';
import UpdateStat_admin from 'Admin/scenes/adminpatient/admin_update-stats';
import AddRecord_admin from 'Admin/scenes/adminpatient/admin_add-record';
import CreateCustomPlan_admin from 'Admin/scenes/admincustom/create';
import ViewCustomPlan_admin from 'Admin/scenes/admincustom/details';
import MergePlan_admin from 'Admin/scenes/admincustom/merge';
import Tiers_admin from 'Admin/scenes/admintiers';
import Profile_admin from 'Admin/scenes/adminprofile';
import LoginPage_admin from "Admin/scenes/adminsignin";
import SignupPage_admin from "Admin/scenes/adminsignup";
import PatientProfile_admin from "Admin/scenes/adminpatient/adminprofile";
import Layout_admin from "Admin/scenes/adminlayout";
import { MedicalPage } from "components/MedicalPage";
import AdminTeam from "Admin/scenes/adminteams";
import AddMember_admin from "Admin/scenes/adminpatient/admin_add_member";
import MyAccount from "scenes/my-account";
import Billing from "scenes/billing";
import PatientTransfered from "scenes/patients-transfered";
import PatientsRetained from "scenes/patients-retained";
import YourComponent from "scenes/patient/body-track";
import BodyVisualization from "scenes/patient/body-track";
import Prescription from "scenes/prescription";
import PrescriptionFormComponent from "scenes/prescription/PrescriptionForm";
import Report from "scenes/patient/report";
import PrescriptionDetail from "scenes/prescription/details";
import ViewExercise from "scenes/exercises";
import PatientOverview from "scenes/customers/details";
import AddTrimester from "scenes/customers/add-trimester";
import EditTrimester from "scenes/customers/edit-trimester";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const [user, loading] = useAuthState(auth);
  // useEffect(()=>{
  //  if (window) {

  //  }
  // },[])
  if (loading) {
    return <div className="app_loader"><div className="loader"></div></div>
  }
  if (user) {
    // window.location.replace('/')

  }


  return user ? (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route element={<Layout />}>

              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/appointments" element={<Appointments />} />
              {/* <Route path="/products" element={<Products />} /> */}
              <Route path="/patients" element={<Customers />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/daily" element={<Daily />} />
              <Route path="/monthly" element={<Monthly />} />
              <Route path="/breakdown" element={<Breakdown />} />
              <Route path="/video-call" element={<VideoCall />} />
              <Route path="/feedbacks" element={<CustomerDetails />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route path="/patient/assign-plan" element={<AssignPlan />} />
              <Route path="/patient" element={<Patient />} />
              <Route path="/patients-tranfered" element={<PatientTransfered />} />
              <Route path="/patients-received" element={<PatientsRetained />} />
              <Route path="/patient/add" element={<AddPatient />} />
              <Route path="/patient/medical-record" element={<MedicalRecord />} />
              <Route path="/patient/profile" element={<PatientProfile />} />
              <Route path="/patient/add-record" element={<AddRecord />} />
              <Route path="/patient/update-stats" element={<UpdateStat />} />
              <Route path="/appointment/medicalrecord" element={<MedicalPage />} />
              <Route path="/add-member" element={<AddMember_admin/>} />
              <Route path="/team" element={<AdminTeam/>} />
              <Route path="/account" element={<MyAccount/>}/>
              <Route path="/billing" element={<Billing/>}/>
              <Route path="/trackbody" element={<BodyVisualization/>}/>
              <Route path="/prescription" element={<Prescription/>}/>
              <Route path="/prescription/form" element={<PrescriptionFormComponent/>}/>
              <Route path="/prescription/detail" element={<PrescriptionDetail/>}/>
              <Route path="/report" element={<Report/>}/>
              <Route path="/exercises" element={<ViewExercise/>} />
              <Route path="/patient/add-trimester" element={<AddTrimester/>} />
              <Route path="/patient/edit-trimester" element={<EditTrimester/>} />
              {/* <Route path="/patient/overview" element={<PatientOverview/>} /> */}
              <Route path="*" element={<Navigate to="/" />} />
              {/* <Route path="/profile" element={<Profile/>}/> */}
             
    </Route>

              {/* Routing for the admin panel */}
            {/* <Route element={<Layout_admin />}>
              <Route path="/admin" element={<Navigate to="/admin/dashboard" replace />} />
              <Route path="/admin/dashboard" element={<Dashboard_admin />} />
              <Route path="/admin/appointments" element={<Appointments_admin />} />
              <Route path="/admin/products" element={<Products_admin/>} />
              <Route path="/admin/patients" element={<Customers_admin />} />
              <Route path="/admin/transactions" element={<Transactions_admin />} />
              <Route path="/admin/geography" element={<Geography_admin />} />
              <Route path="/admin/overview" element={<Overview_admin />} />
              <Route path="/admin/daily" element={<Daily_admin />} />
              <Route path="/admin/monthly" element={<Monthly_admin />} />
              <Route path="/admin/breakdown" element={<Breakdown_admin />} />
              <Route path="/admin/video-call" element={<VideoCall_admin />} />
              <Route path="/admin/feedbacks" element={<CustomerDetails_admin />} />
              <Route path="/admin/subscriptions" element={<Subscriptions_admin />} />
              <Route path="/admin/patient/assign-plan" element={<AssignPlan_admin />} />
              <Route path="/admin/patient" element={<Patient_admin />} />
              <Route path="/admin/patient/add" element={<AddPatient_admin />} />
              <Route path="/admin/patient/medical-record" element={<MedicalRecord_admin />} />
              <Route path="/admin/patient/profile" element={<PatientProfile_admin />} />
              <Route path="/admin/patient/add-record" element={<AddRecord_admin />} />
              <Route path="/admin/patient/update-stats" element={<UpdateStat_admin />} />
              <Route path="/admin/custom/create" element={<CreateCustomPlan_admin />} />
              <Route path="/admin/custom/details" element={<ViewCustomPlan_admin />} />
              <Route path="/admin/custom/merge" element={<MergePlan_admin />} />
              <Route path="/admin/tier-purchase" element={<Tiers_admin />} />
              <Route path="/admin/profile" element={<Profile_admin />} />
              <Route path="/admin/team" element={<AdminTeam/>}/>
           
              </Route>
              
*/}
           
            <Route path="/custom/create" element={<CreateCustomPlan />} />
            <Route path="/custom/details" element={<ViewCustomPlan />} />
            <Route path='/custom/merge' element={<MergePlan />} />
            <Route path="/tier-purchase" element={<Tiers />} />
            <Route path="/profile" element={<Profile />} /> 
          


          </Routes>

        </ThemeProvider>
      </BrowserRouter>
    </div>
  ) : <div className="app">
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Navigate to="/signin" replace />} />
          <Route path="/signin" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="*" element={<Navigate to="/signin" />} />
        </Routes>

      </ThemeProvider>
    </BrowserRouter>
  </div>;
}

export default App;
